@charset "UTF-8";
/*********************************************
scss/global/variables/_typography.scss
フォントの指定
**********************************************/
/* 日本語のフォントの指定 */
/* 英語フォントの指定 */
/* WEBフォントのインポート */
@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@500;700&display=swap");
/*********************************************
reset and normalize
**********************************************/
/***
    The new CSS reset - version 1.7.3 (last updated 7.8.2022)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/
/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */


*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}


header,
footer {

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

/* For images to not be able to exceed their container */
img {
  width: 100%;
  height: auto;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  -moz-appearance: revert;
       appearance: revert;
}

/* reset default text opacity of input placeholder */
::-moz-placeholder {
  color: unset;
}
::placeholder {
  color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable=false])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable=true]) {
  -webkit-user-drag: element;
}

}

/*********************************************
basics
**********************************************/
/*********************************************
global/variables/
**********************************************/
/*********************************************
variable
**********************************************/
/*********************************************
foundation/setting/_color.scss
**********************************************/
/* 以下、上記で指定した変数内で指定する */
/*---------------設定ここまで---------------*/
/*********************************************
ブレイクポイントの設定
foundation/setting/_responsive.scss
**********************************************/
/*---------------設定ここまで---------------*/
/* japanese */
/* english */
/*********************************************
コンテナサイズの設定
foundation/setting/_container.scss
**********************************************/
/*********************************************
global/function/
**********************************************/
/*********************************************
function
**********************************************/
/*********************************************
計算用に単位を削除する
**********************************************/
/*********************************************
function
**********************************************/
/*********************************************
pxをremに変換する
ex.font-size:rem(14px)
**********************************************/
/*********************************************
function
**********************************************/
/*********************************************
widthやheightをvwに変換しレスポンシブ対応する。
**********************************************/
/*********************************************
global/mixin
**********************************************/
/*********************************************
mixin
**********************************************/
/*********************************************
global/variables/
**********************************************/
/*********************************************
Break Pointの設定に基づいたMedia Queryの生成
scss/global/mixin/_responsive.scss
**********************************************/
/*********************************************
hoverメディアクエリのショートコード(SPでhoverを無効にする為)
@include hover(){color:$c-primary;}]
参考：https://zenn.dev/tak_dcxi/articles/2cc1828e9c1fe2#hover%E3%81%AE%E6%8C%87%E5%AE%9A
**********************************************/
html {
  scroll-behavior: smooth;
  font-size: 16px;
}
@media (max-width: 1331px) {
    html {
    font-size: 1.201vw;
    .l-main {
        font-size: 16px;
    }
  }
}
@media (max-width: 959px) {
html {
    font-size: 1.201vw;
    .l-main {
        font-size: 16px;
    }
  }
}
@media (max-width: 519px) {
    html {
    font-size: 4.21vw;
    .l-main {
        font-size: 16px;
    }
  }
}

body {
  font-weight: 500;
  background: #fff;
  color: #000;
  font-family: "Zen Kaku Gothic New", -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN", sans-serif;
  -webkit-font-smoothing: antialiased;
  position: relative;
  line-height: 1;
}




/*********************************************
layout/
**********************************************/
/*********************************************
layout/_container.scss

<div class="l-container -full">
  <div class="l-container__inner">
  </div>
</div>
**********************************************/
.l-container {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 520px) {
    .l-container.lg\:-full {
      width: 82.5825825826vw;
    }
  }
  @media (min-width: 1332px) {
    .l-container.lg\:-full {
      width: 68.75rem;
    }
  }
  @media (min-width: 520px) {
    .l-container.lg\:-md {
      width: 66.6666666667vw;
    }
  }
  @media (min-width: 1332px) {
    .l-container.lg\:-md {
      width: 55.5rem;
    }
  }
  @media (min-width: 520px) {
    .l-container.lg\:-sm {
      width: 49.0990990991vw;
    }
  }
  @media (min-width: 1332px) {
    .l-container.lg\:-sm {
      width: 40.875rem;
    }
  }
  @media (max-width: 519px) {
    .l-container.sm\:-screen {
      margin-left: -0.625rem;
      margin-right: -0.625rem;
    }
    .l-container.sm\:-full {
      width: calc(100% - 20px);
    }
    .l-container.sm\:-md {
      width: 86%;
    }
  }


header,
footer {

@media (min-width: 520px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

a:focus {
  outline: 1px solid #ea5404;
}

}

/*********************************************
layout/_header.scss
・header
・global nav
・drawer menu
**********************************************/
.l-header {
  padding-top: 2rem;
  color: #1e1e1e;
}
@media (max-width: 959px) {
  .l-header {
    padding-top: 0;
    width: 100% !important;
  }
}
@media (max-width: 519px) {
  .l-header.l-container.sm\:-screen {
    margin: 0;
  }
}
.l-header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.875rem;
}
@media (max-width: 959px) {
  .l-header__inner {
    height: 4.25rem;
    padding-right: 4.25rem;
    margin-bottom: 0;
  }
  .is-nomenu .l-header__inner {
    padding-right: 0;
  }
}
.l-header__logo {
  width: 8.8125rem;
  height: auto;
}
.l-header__logo a {
  display: block;
}
@media (max-width: 959px) {
  .l-header__logo {
    width: 6.5rem;
    margin-left: 0.625rem;
  }
}

.l-header-sub__list {
  display: flex;
  align-items: center;
  font-weight: 700;
}
.l-header-sub__item {
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-left: 1px solid #9d9d9d;
  padding: 0 1.5rem;
}
@media (max-width: 959px) {
  .l-header-sub__item {
    display: none;
  }
}
.l-header-sub__item a:focus {
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .l-header-sub__item a:hover {
    text-decoration: underline;
  }
}
.l-header-sub__btn + .l-header-sub__btn {
  margin-left: 0.75rem;
}
@media (max-width: 959px) {
  .l-header-sub__btn + .l-header-sub__btn {
    margin-left: 0;
  }
}
.l-header-sub__btn a {
  display: flex;
  width: 10.5rem;
  height: 2.5rem;
  padding-left: 2.375rem;
  border-radius: 0.3125rem;
  border: 0.125rem solid;
  background: left center/contain no-repeat;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  transition: background-color .32s,color .32s;
}
@media (max-width: 959px) {
  .l-header-sub__btn a {
    width: 5rem;
    height: 3rem;
    padding-left: 0;
    background-position: center 0.375rem;
    background-size: 1.25rem;
    align-items: flex-end;
    border: none;
    border-left: 1px solid;
    font-size: 0.75rem;
    letter-spacing: -0.05em;
    border-radius: 0;
  }
}
.l-header-sub__btn.-login a {
  border-color: #404ac7;
  background-image: url(/common_2024/img/header_icon_login_pc.webp);
}
.no-webp-support .l-header-sub__btn.-login a {
  background-image: url(/common_2024/img/header_icon_login_pc.png);
}
@media (max-width: 959px) {
  .l-header-sub__btn.-login a {
    background-image: url(/common_2024/img/header_icon_login_sp.webp);
    border-color: #d1d1d1;
  }
  .no-webp-support .l-header-sub__btn.-login a {
    background-image: url(/common_2024/img/header_icon_login_sp.png);
  }
}
.l-header-sub__btn.-user a {
  border-color: #9d9d9d;
  background-image: url(/common_2024/img/header_icon_user_pc.webp);
}
.no-webp-support .l-header-sub__btn.-user a {
  background-image: url(/common_2024/img/header_icon_user_pc.png);
}
@media (max-width: 959px) {
  .l-header-sub__btn.-user a {
    background-image: url(/common_2024/img/header_icon_user_sp.webp);
    border-color: #d1d1d1;
  }
  .no-webp-support .l-header-sub__btn.-user a {
    background-image: url(/common_2024/img/header_icon_user_sp.png);
  }
}

.l-header-nav {
  font-weight: 700;
}
@media (max-width: 959px) {
  .l-header-nav {
    overflow-x: auto;
    position: relative;
  }
}
.l-header-nav__list {
  display: grid;
  min-height: 2.875rem;
}
.l-header-nav__list.-col-5 {
  grid-template-columns: repeat(5, 1fr);
}
.l-header-nav__list.-col-6 {
  grid-template-columns: repeat(6, 1fr);
}
.l-header-nav__list.-col-7 {
  grid-template-columns: repeat(7, 1fr);
}
.l-header-nav__list.-col-8 {
  grid-template-columns: repeat(8, 1fr);
}
.l-header-nav__list.-col-9 {
  grid-template-columns: repeat(9, 1fr);
}
.l-header-nav__list.-col-10 {
  grid-template-columns: repeat(10, 1fr);
}
@media (max-width: 519px) {
  .l-header-nav__list {
    display: flex;
    justify-content: flex-start;
    min-width: 100%;
    background: #474747;
    min-height: 3rem;
  }
}
.l-header-nav__item {
  position: relative;
  display: flex;
  align-items: center;
  background: #474747;
}
.l-header-nav__item a {
  display: flex;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.25;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}
@media (max-width: 519px) {
  .l-header-nav__item a {
    letter-spacing: 0.05em;
    padding: 0 0.5rem;
    white-space: nowrap;
  }
}
.l-header-nav__item.-small a {
  font-size: 0.625rem;
  line-height: 1.2;
}
@media (max-width: 519px) {
  .l-header-nav__item.-small a {
    font-size: 0.75rem;
    line-height: 1.25;
  }
  .l-header-nav__item.-small a br:last-child {
    display: none;
  }
}
.l-header-nav__item:not(:first-child)::before {
  content: "";
  width: 0.0625rem;
  height: 1.25rem;
  background: #fff;
}
.l-header-nav__item.-current a,
.l-header-nav__item a:hover,
.l-header-nav__item a:focus {
  background: #ea5404;
}
.l-header-nav__scroll {
  display: none;
  width: 1.25rem;
  height: 1.25rem;
  transition: opacity 0.4s ease-in-out;
}
@media (max-width: 519px) {
  .l-header-nav__scroll {
    position: absolute;
    right: 0.625rem;
    top: 5.125rem;
    display: block;
  }
}
.l-header-nav__scroll.is-scrolled {
  opacity: 0;
}

/*** ハンバーガーボタン ***/
.l-header-menu {
  position: fixed;
  background: #ea5404;
  height: 100%;
  right: 0;
  top: 0;
  width: 6.625rem;
  z-index: 10000;
  padding-top: 0.75rem;
}
@media (max-width: 959px) {
  .l-header-menu {
    padding: 0;
    width: 4.25rem;
    height: 4.25rem;
    position: absolute;
  }
}
.l-header-menu__btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  width: 5.375rem;
  height: 5.375rem;
  background: #fff;
  border-radius: 0.3125rem;
  z-index: 10001;
  padding-top: 0.25rem;
  margin: 0 auto;
  cursor: pointer;
}
@media (max-width: 959px) {
  .l-header-menu__btn {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding-top: 0.25rem;
    background: transparent;
  }
}
.l-header-menu__inner {
  width: 2.5rem;
  height: 1.375rem;
  position: relative;
}
@media (max-width: 959px) {
  .l-header-menu__inner {
    width: 2rem;
  }
}
.l-header-menu__bar {
  position: absolute;
  left: 0;
  z-index: 10100;
  width: 100%;
  height: 0.125rem;
  background-color: #ea5404;
  border-radius: 1px;
  transition: transform 0.4s, opacity 0.4s;
}
@media (max-width: 959px) {
  .l-header-menu__bar {
    background-color: #fff;
  }
}
.l-header-menu__bar:nth-of-type(1) {
  top: 0;
  transform: translateY(0) rotate(0deg);
}
.l-header-menu__bar:nth-of-type(2) {
  top: 0.625rem;
}
.l-header-menu__bar:nth-of-type(3) {
  bottom: 0;
  transform: translateY(0) rotate(0);
}
.is-drawer-open .l-header-menu__bar:nth-of-type(1) {
  transform: translateY(0.625rem) rotate(-45deg);
}
.is-drawer-open .l-header-menu__bar:nth-of-type(2) {
  opacity: 0;
}
.is-drawer-open .l-header-menu__bar:nth-of-type(3) {
  transform: translateY(-0.625rem) rotate(45deg);
}
.l-header-menu__text {
  font-weight: 700;
  margin-top: 0.5rem;
  text-align: center;
  font-size: 0.9375rem;
  color: #ea5404;
  line-height: 1.2;
}
@media (max-width: 959px) {
  .l-header-menu__text {
    font-size: 0.8125rem;
    margin-top: 0.375rem;
    color: #fff;
    letter-spacing: -0.1em;
  }
}

/*********************************************
layout/_header.scss
・header
・global nav
・drawer menu
**********************************************/
.l-drawer {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 62.5rem;
  height: 100vh;
  height: 100dvh;
  overflow-y: auto;
  z-index: 9999;
  padding: 2.5rem 1.25rem 2.5rem 2.25rem;
  top: 0;
  right: 6.625rem;
  transform: translateX(80rem);
  transition: transform 0.4s, opacity 0.2s;
  will-change: transform;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 959px) {
  .l-drawer {
    transform: none;
    right: 0;
    top: 4.25rem;
    width: 100%;
    padding: 2.5rem calc((100% - 59rem) / 2) 6rem;
  }
}
@media (max-width: 519px) {
  .l-drawer {
    padding: 2rem 0.625rem 6rem;
  }
}
.is-drawer-open .l-drawer {
  transform: none;
  opacity: 1;
  visibility: visible;
}
.l-drawer__search {
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1.75rem;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 519px) {
  .l-drawer__search {
    display: block;
    margin-bottom: 1.5rem;
  }
}
.l-drawer__search form {
  position: relative;
  width: 22.5rem;
  height: 100%;
}
@media (max-width: 519px) {
  .l-drawer__search form {
    width: 100%;
  }
}
.l-drawer__search-input {
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  padding: 0 3rem 0 1.125rem;
  font-size: 0.875rem;
  background: #fff;
}
.l-drawer__search-input::-moz-placeholder {
  color: #d1d1d1;
}
.l-drawer__search-input::placeholder {
  color: #d1d1d1;
}
.l-drawer__search-submit {
  position: absolute;
  width: 3rem;
  height: 2.5rem;
  background: url(/common_2024/img/drawer_search_icon.png) center center/1.25rem no-repeat;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.l-drawer__wrapper {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 519px) {
  .l-drawer__wrapper {
    display: block;
  }
}
.l-drawer__main {
  width: 38.25rem;
}
@media (max-width: 519px) {
  .l-drawer__main {
    width: 100%;
  }
}
.l-drawer__sub {
  width: 18.75rem;
}
@media (max-width: 519px) {
  .l-drawer__sub {
    width: 100%;
    margin-top: 2.5rem;
  }
}
.l-drawer__heading {
  border-bottom: 0.125rem #fff solid;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
  letter-spacing: 0.03em;
  color: #fff;
  padding-bottom: 1rem;
}
.l-drawer__heading::before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 1.875rem;
  background: #ea5404;
  margin-right: 0.625rem;
  vertical-align: -0.4em;
}
.l-drawer__sub .l-drawer__heading:not(:first-child) {
  margin-top: 2rem;
}
.l-drawer__text {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.03em;
  margin-top: 0.375rem;
}
.l-drawer__list {
  display: grid;
  gap: 0.5rem;
  margin-top: 0.75rem;
}
.l-drawer__item {
  background: url(/common_2024/img/drawer_icon_arrow_white.svg) left 0.5rem no-repeat;
  background-size: 0.375rem;
}
.l-drawer__item a {
  color: #fff;
  padding-left: 0.875rem;
  font-size: 0.75rem;
  line-height: 2;
}
.l-drawer__item a:focus {
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .l-drawer__item a:hover {
    text-decoration: underline;
  }
}
.l-drawer__grid-1 {
  display: grid;
  gap: 1.25rem 0.75rem;
  margin-top: 1.25rem;
  grid-template-columns: 1fr 1fr;
}
.l-drawer__grid-1 > li:nth-child(1), .l-drawer__grid-1 > li:nth-child(2) {
  grid-column: 1/3;
}
@media (max-width: 519px) {
  .l-drawer__grid-1 {
    grid-template-columns: 1fr;
  }
  .l-drawer__grid-1 > li:nth-child(1), .l-drawer__grid-1 > li:nth-child(2) {
    grid-column: 1/2;
  }
  .l-drawer__grid-1 > li:nth-child(1) {
    order: 1;
  }
  .l-drawer__grid-1 > li:nth-child(2) {
    order: 2;
  }
  .l-drawer__grid-1 > li:nth-child(3) {
    order: 3;
  }
  .l-drawer__grid-1 > li:nth-child(5) {
    order: 4;
  }
  .l-drawer__grid-1 > li:nth-child(4) {
    order: 5;
  }
  .l-drawer__grid-1 > li:nth-child(6) {
    order: 6;
  }
  .l-drawer__grid-1 > li:nth-child(7) {
    order: 7;
  }
}
.l-drawer__grid-2 {
  margin-top: 0.875rem;
  display: grid;
  gap: 1.25rem;
}
@media (max-width: 519px) {
  .l-drawer__grid-2 {
    display: grid;
    gap: 0.625rem;
    grid-template-columns: 1fr 1fr;
  }
  .l-drawer__grid-2 > li:first-child {
    order: 2;
  }
  .l-drawer__grid-2 > li:last-child {
    order: 1;
  }
}
.l-drawer__grid-3 {
  margin-top: 2rem;
}
@media (max-width: 519px) {
  .l-drawer__grid-3 {
    margin-top: 2.5rem;
    display: grid;
    gap: 0.625rem;
    grid-template-columns: 1fr 1fr;
  }
}
.l-drawer__cover {
  display: none;
  width: 100vw;
  height: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9998;
}
.is-drawer-open .l-drawer__cover {
  display: block;
}

.l-drawer-toggle {
  background: #fff;
  border-radius: 0.625rem;
  border: 1px solid #d1d1d1;
  padding: 0 0.75rem;
}
.l-drawer-toggle__heading {
  display: flex;
  height: 3.625rem;
  color: #ea5404;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.05em;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
.l-drawer-toggle__heading:focus {
  outline: #ea5404 1px solid;
}
@media (max-width: 519px) {
  .l-drawer-toggle__heading {
    height: 3rem;
    font-size: 1rem;
  }
}
.l-drawer-toggle__heading.-icon-bicycle, .l-drawer-toggle__heading.-icon-pet {
  padding-left: 2.5rem;
  background: left center/2rem no-repeat;
}
@media (max-width: 519px) {
  .l-drawer-toggle__heading.-icon-bicycle, .l-drawer-toggle__heading.-icon-pet {
    background-size: 1.5rem;
    padding-left: 2rem;
  }
}
.l-drawer-toggle__heading.-icon-bicycle {
  background-image: url(/common_2024/img/drawer_icon_bicycle.svg);
}
.l-drawer-toggle__heading.-icon-pet {
  background-image: url(/common_2024/img/drawer_icon_pet.svg);
}
.l-drawer-toggle__heading::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  background: url(/common_2024/img/icon/icon_toggle_plus.svg) center center/cover no-repeat;
  position: absolute;
  right: 0;
  top: 1.125rem;
}
@media (max-width: 519px) {
  .l-drawer-toggle__heading::after {
    top: 0.8125rem;
  }
}
.l-drawer-toggle__heading.is-active::after {
  background-image: url(/common_2024/img/icon/icon_toggle_minus.svg);
}
.l-drawer-toggle__heading-sub {
  font-weight: 500;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1;
  margin-bottom: 0.25rem;
}
.l-drawer-toggle__row {
  border-top: 0.125rem #ea5404 dashed;
  margin-top: 0.25rem;
  padding: 1rem 0.75rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem 0.75rem;
}
@media (max-width: 519px) {
  .l-drawer-toggle__row {
    grid-template-columns: 1fr;
    gap: 1.25rem;
    padding-top: 1.5rem;
  }
}
.l-drawer-toggle__list {
  display: grid;
  gap: 0.75rem;
}
.l-drawer-toggle__list .l-drawer-toggle__list {
  margin-top: 0.75rem;
  gap: 0.5rem;
}
.l-drawer-toggle__list + .l-drawer-toggle__list {
  margin-top: 1.5rem;
}
@media (max-width: 519px) {
  .l-drawer-toggle__list + .l-drawer-toggle__list {
    margin-top: 1.25rem;
  }
}
.l-drawer-toggle__item {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.08em;
  font-weight: 700;
  background: url(/common_2024/img/drawer_icon_arrow_primary_bold.svg) left 0.59375rem no-repeat;
  background-size: 0.375rem;
}
.l-drawer-toggle__item a {
  color: #ea5404;
  padding-left: 0.875rem;
}
.l-drawer-toggle__item a:focus {
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .l-drawer-toggle__item a:hover {
    text-decoration: underline;
  }
}
.l-drawer-toggle__item .l-drawer-toggle__item {
  font-size: 0.75rem;
  background-position: left 0.375rem;
}
.l-drawer-toggle__item .l-drawer-toggle__item a {
  color: #000;
}
.l-drawer-toggle__sub {
  font-size: 0.625rem;
  letter-spacing: 0.1em;
}

.l-drawer-btn {
  background-color: #fff;
  border-radius: 0.625rem;
  border: 1px solid #d1d1d1;
  box-shadow: 0 0.25rem 0 #d1d1d1;
  display: flex;
  height: 3.625rem;
  color: #ea5404;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.03em;
  justify-content: center;
  flex-direction: column;
  padding-left: 1.25rem;
  position: relative;
}
@media (max-width: 519px) {
  .l-drawer-btn {
    font-size: 1rem;
    height: 3rem;
    padding-left: 0.625rem;
  }
}
.l-drawer-btn__sub {
  font-weight: 500;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1;
  margin-bottom: 0.25rem;
}
.l-drawer-btn.-icon-earth, .l-drawer-btn.-icon-first-aid-kit, .l-drawer-btn.-icon-japan, .l-drawer-btn.-icon-hurt, .l-drawer-btn.-icon-golf {
  padding-left: 3.25rem;
  background: 0.75rem center/2rem no-repeat #fff;
}
@media (max-width: 519px) {
  .l-drawer-btn.-icon-earth, .l-drawer-btn.-icon-first-aid-kit, .l-drawer-btn.-icon-japan, .l-drawer-btn.-icon-hurt, .l-drawer-btn.-icon-golf {
    background-size: 1.5rem;
    padding-left: 2.75rem;
  }
}
.l-drawer-btn.-icon-earth {
  background-image: url(/common_2024/img/drawer_icon_earth.svg);
}
.l-drawer-btn.-icon-first-aid-kit {
  background-image: url(/common_2024/img/drawer_icon_first_aid_kit.svg);
}
.l-drawer-btn.-icon-japan {
  background-image: url(/common_2024/img/drawer_icon_japan.svg);
}
.l-drawer-btn.-icon-hurt {
  background-image: url(/common_2024/img/drawer_icon_hurt.svg);
}
.l-drawer-btn.-icon-golf {
  background-image: url(/common_2024/img/drawer_icon_golf.svg);
}
.l-drawer-btn::after {
  content: "";
  width: 0.375rem;
  height: 0.6875rem;
  background: url(/common_2024/img/drawer_icon_arrow_primary.svg) center center/contain no-repeat;
  position: absolute;
  right: 0.5rem;
  top: 1.3125rem;
}
@media (max-width: 519px) {
  .l-drawer-btn::after {
    top: 1.0625rem;
    right: 1.125rem;
  }
}

.js-drawer-toggle-content {
  display: none;
}

/*********************************************
layout/_main.scss
**********************************************/
.l-main {
  margin-top: 1rem;
  margin-bottom: 5rem;
//   font-size: 0.875rem;
}

/*********************************************
layout/_side.scss
**********************************************/
/*********************************************
layout/_footer.scss
**********************************************/
.l-footer {
  position: sticky;
  top: 100vh;
}
.l-footer__top {
  background: #ea5404;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.875rem;
  padding-right: 0.5rem;
  padding-left: 2rem;
}
@media (max-width: 959px) {
  .l-footer__top {
    padding-right: 0;
    margin-bottom: 0;
  }
}
.l-footer__top-logo {
  width: 4.125rem;
  height: 0.875rem;
}
.l-footer__top-pagetop {
  height: 100%;
}
@media (max-width: 519px) {
  .l-footer__top-pagetop {
    border-left: 1px solid #fff;
  }
}
.l-footer__top-pagetop a {
  display: flex;
  height: 100%;
  align-items: center;
}
@media (max-width: 519px) {
  .l-footer__top-pagetop a {
    padding: 0 0.875rem;
  }
}
.l-footer__top-pagetop svg {
  width: 1.5625rem;
  height: 1.5625rem;
}
.l-footer__number {
  font-size: 0.5rem;
  color: #777;
  font-weight: 700;
  text-align: right;
  letter-spacing: 0.1em;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}
@media (max-width: 959px) {
  .l-footer__number {
    padding-right: 0.625rem;
  }
}
.l-footer__nav {
  background: #f3f3f3;
  color: #5c5c5c;
}
.l-footer__row {
  padding: 1.25rem 0 1.25rem 2rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 959px) {
  .l-footer__row {
    padding-right: 0.625rem;
  }
}
@media (max-width: 519px) {
  .l-footer__row {
    display: block;
    padding: 0.5rem 1.25rem 1.5rem;
  }
}
.l-footer__heading {
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-bottom: 0.75rem;
  line-height: 1.5;
}
@media (max-width: 519px) {
  .l-footer__heading {
    margin-bottom: 0;
    line-height: 2.5rem;
    border-bottom: 0.0625rem #d1d1d1 solid;
    padding-left: 0.25rem;
    font-size: 0.875rem;
    position: relative;
  }
  .l-footer__heading::after {
    content: "";
    width: 1.25rem;
    height: 1.25rem;
    background: url(/common_2024/img/icon/icon_toggle_plus.svg) center center/cover no-repeat;
    position: absolute;
    right: 0;
    top: 0.625rem;
  }
  .l-footer__heading.is-active::after {
    background-image: url(/common_2024/img/icon/icon_toggle_minus.svg);
  }
}
.l-footer__content {
  display: none;
}
@media (min-width: 520px) {
  .l-footer__content {
    display: block !important;
  }
}
@media (max-width: 519px) {
  .l-footer__content {
    padding: 0 0 0 1.25rem;
  }
}
@media (max-width: 959px) {
  .l-footer__content.-block {
    display: block;
  }
}
@media (max-width: 519px) {
  .l-footer__content > .l-footer__list:first-child {
    padding-top: 1.5rem;
  }
  .l-footer__content > .l-footer__list:last-child {
    padding-bottom: 1.5rem;
  }
}
.l-footer__list {
  display: grid;
  gap: 0.25rem;
}
.l-footer__list .l-footer__list {
  margin-top: 0.25rem;
  margin-left: 0.5rem;
}
.l-footer__list + .l-footer__list {
  margin-top: 1rem;
}
@media (max-width: 519px) {
  .l-footer__list.-sm-col2 {
    grid-template-columns: 1fr 1fr;
    gap: 0.25rem 1.25rem;
  }
}
.l-footer__content + .l-footer__heading {
  margin-top: 1.25rem;
}
@media (max-width: 519px) {
  .l-footer__content + .l-footer__heading {
    margin-top: 0;
  }
}
.l-footer__item {
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  line-height: 2;
  background: url(/common_2024/img/footer_nav_arrow.svg) left 0.5625rem no-repeat;
  background-size: 0.3125rem;
  padding-left: 0.75rem;
}
.l-footer__item a:focus {
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .l-footer__item a:hover {
    text-decoration: underline;
  }
}
.l-footer__item.-external a::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 9px;
  margin-left: 0.5rem;
  background: url(/common_2024/img/footer_nav_external.svg) center center/contain no-repeat;
}
.l-footer__sub {
  font-size: 0.5rem;
  letter-spacing: 0.1em;
}
.l-footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
}
@media (max-width: 959px) {
  .l-footer__bottom {
    padding: 0.75rem 0 0.625rem;
  }
}
@media (max-width: 519px) {
  .l-footer__bottom {
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    height: auto;
    padding: 0.625rem 0;
  }
}
.l-footer__bottom-logo {
  width: 12.375rem;
}
.l-footer__bottom-copy {
  font-size: 0.75rem;
  color: #777;
  font-weight: 700;
  letter-spacing: 0.1em;
}
@media (max-width: 519px) {
  .l-footer__bottom-copy {
    margin-top: 0.25rem;
    font-size: 0.625rem;
  }
}

.c-breadcrumbs {
  background: #f3f3f3;
  overflow: hidden;
}
.c-breadcrumbs__list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.625rem;
  height: 2.5rem;
  overflow-x: auto;
  padding-left: 1px;
}
@media (max-width: 959px) and (min-width: 520px) {
  .c-breadcrumbs__list {
    width: 100% !important;
    padding: 0.625rem;
  }
}
.c-breadcrumbs__item {
  white-space: nowrap;
}
.c-breadcrumbs__item:not(:first-child)::before {
  content: ">";
  display: inline-block;
  margin: 0 1em 0.1em;
  transform: translateY(-0.0625rem);
}
.c-breadcrumbs__item a:focus {
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .c-breadcrumbs__item a:hover {
    text-decoration: underline;
  }
}

header,
footer {


/*********************************************
object/component
**********************************************/
// @keyframes splide-loading {
//   0% {
//     transform: rotate(0);
//   }
//   to {
//     transform: rotate(1turn);
//   }
// }
// .splide__track--draggable {
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//        user-select: none;
// }

// .splide__track--fade > .splide__list > .splide__slide {
//   margin: 0 !important;
//   opacity: 0;
//   z-index: 0;
// }

// .splide__track--fade > .splide__list > .splide__slide.is-active {
//   opacity: 1;
//   z-index: 1;
// }

// .splide--rtl {
//   direction: rtl;
// }

// .splide__track--ttb > .splide__list {
//   display: block;
// }

// .splide__container {
//   box-sizing: border-box;
//   position: relative;
// }

// .splide__list {
//   backface-visibility: hidden;
//   display: flex;
//   height: 100%;
//   margin: 0 !important;
//   padding: 0 !important;
// }

// .splide.is-initialized:not(.is-active) .splide__list {
//   display: block;
// }

// .splide__pagination {
//   align-items: center;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   margin: 0;
//   pointer-events: none;
// }

// .splide__pagination li {
//   display: inline-block;
//   line-height: 1;
//   list-style-type: none;
//   margin: 0;
//   pointer-events: auto;
// }

// .splide:not(.is-overflow) .splide__pagination {
//   display: none;
// }

// .splide__progress__bar {
//   width: 0;
// }

// .splide {
//   position: relative;
//   visibility: hidden;
// }

// .splide.is-initialized, .splide.is-rendered {
//   visibility: visible;
// }

// .splide__slide {
//   backface-visibility: hidden;
//   box-sizing: border-box;
//   flex-shrink: 0;
//   list-style-type: none !important;
//   margin: 0;
//   position: relative;
// }

// .splide__slide img {
//   vertical-align: bottom;
// }

// .splide__spinner {
//   animation: splide-loading 1s linear infinite;
//   border: 2px solid #999;
//   border-left-color: transparent;
//   border-radius: 50%;
//   bottom: 0;
//   contain: strict;
//   display: inline-block;
//   height: 20px;
//   left: 0;
//   margin: auto;
//   position: absolute;
//   right: 0;
//   top: 0;
//   width: 20px;
// }

// .splide__sr {
//   clip: rect(0 0 0 0);
//   border: 0;
//   height: 1px;
//   margin: -1px;
//   overflow: hidden;
//   padding: 0;
//   position: absolute;
//   width: 1px;
// }

// .splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
//   display: none;
// }

// .splide__toggle.is-active .splide__toggle__pause {
//   display: inline;
// }

// .splide__track {
//   overflow: hidden;
//   position: relative;
//   z-index: 0;
// }

// .splide__pagination {
//   width: 100%;
// }
// .splide__pagination__page {
//   width: 1.875rem;
//   height: 0.125rem;
//   background: #d1d1d1;
//   margin: 0 0.25rem;
// }
// .splide__pagination__page.is-active {
//   background: #ea5404;
// }

// .c-accordion {
//   border-radius: 0.5rem;
//   border: 1px solid #d1d1d1;
// }
// .c-accordion__heading {
//   font-weight: 700;
//   letter-spacing: 0.1em;
//   padding: 1.25rem 6.625rem;
//   line-height: 1.5;
//   position: relative;
//   font-size: 1.25rem;
//   line-height: 1.5;
//   cursor: pointer;
// }
// @media (max-width: 519px) {
//   .c-accordion__heading {
//     font-size: 1.125rem;
//     padding: 1.25rem 2rem 1.25rem 0.625rem;
//   }
// }
// .c-accordion__heading::after {
//   content: "";
//   width: 1.25rem;
//   height: 1.25rem;
//   background: url(/common_2024/img/icon/icon_toggle_plus.svg) center center/cover no-repeat;
//   position: absolute;
//   right: 1.25rem;
//   top: 1.5625rem;
// }
// @media (max-width: 519px) {
//   .c-accordion__heading::after {
//     right: 0.625rem;
//   }
// }
// .c-accordion__heading.is-active::after {
//   background-image: url(/common_2024/img/icon/icon_toggle_minus.svg);
// }
// .c-accordion__wrapper {
//   display: none;
// }
// .c-accordion__content {
//   border-top: 2px #afafaf dotted;
//   padding: 1.5rem 0 2rem;
// }
// @media (max-width: 519px) {
//   .c-accordion__content {
//     padding: 1.5rem 0.625rem 2rem;
//   }
// }
// .c-accordion__content dt {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.5;
// }
// .c-accordion__content dd {
//   font-size: 0.875rem;
//   line-height: 1.5;
//   padding-bottom: 1.5rem;
// }
// .c-accordion__content dd span {
//   font-weight: 700;
// }
// .c-accordion + .c-accordion {
//   margin-top: 2rem;
// }
// @media (max-width: 519px) {
//   .c-accordion + .c-accordion {
//     margin-top: 0.625rem;
//   }
// }

// .c-accordion-4 {
//   border-radius: 0.25rem;
//   border: 1px solid #d1d1d1;
//   max-width: 50rem;
//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
//   background: #fff;
//   width: 21.25rem;
// }
// @media (max-width: 519px) {
//   .c-accordion-4 {
//     width: 100%;
//   }
// }
// .c-accordion-4__heading {
//   text-align: center;
//   font-size: 1rem;
//   font-weight: 700;
//   line-height: 1.25;
//   padding: 0.875rem 1rem;
//   border-bottom: #d1d1d1 1px solid;
//   border-radius: 0.25rem;
//   box-shadow: 0px 0.25rem 0px #d1d1d1;
//   cursor: pointer;
//   position: relative;
// }
// .c-accordion-4__heading::after {
//   content: "";
//   width: 1.25rem;
//   height: 1.25rem;
//   background: url(/common_2024/img/icon/icon_toggle_plus.svg) center center/cover no-repeat;
//   position: absolute;
//   right: 1.25rem;
//   top: 0.875rem;
// }
// @media (max-width: 519px) {
//   .c-accordion-4__heading::after {
//     right: 0.625rem;
//   }
// }
// .c-accordion-4__heading.is-active::after {
//   background-image: url(/common_2024/img/icon/icon_toggle_minus.svg);
// }
// .c-accordion-4__heading.-angle::after {
//   content: "";
//   width: 0.875rem;
//   height: 0.875rem;
//   background: url(/common_2024/img/icon/icon_tryangle_orange.png) center center/contain no-repeat;
//   position: absolute;
//   right: 0.875rem;
//   top: 0.875rem;
// }
// @media (max-width: 519px) {
//   .c-accordion-4__heading.-angle::after {
//     right: 0.625rem;
//   }
// }
// .c-accordion-4__wrapper {
//   display: none;
// }
// @media (max-width: 519px) {
//   .c-accordion-4__wrapper {
//     padding: 0 0.625rem;
//   }
// }
// .c-accordion-4__content {
//   padding: 1rem 1.5rem;
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.3;
// }
// .c-accordion-4__content p a {
//   display: block;
//   margin-top: 0.875rem;
// }

// .c-alertbox {
//   color: #f20000;
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.6;
//   letter-spacing: 0.03em;
//   padding: 1rem 0.75rem 1rem 3.5rem;
//   background: url(/common_2024/img/icon/icon_alert.svg) 0.75rem center no-repeat;
//   background-size: 2rem;
//   border: 1px solid #f20000;
// }
// @media (max-width: 519px) {
//   .c-alertbox {
//     font-size: 0.75rem;
//     padding: 0.5rem 0.75rem 0.5rem 3.5rem;
//   }
// }
// .c-alertbox.-icon-top {
//   background-position: 0.75rem 0.75rem;
// }

// .c-ankerlink {
//   margin-top: 1rem;
//   margin-bottom: 1rem;
// }
// .c-ankerlink a {
//   color: #ea5404;
//   font-size: 1rem;
//   font-weight: 700;
//   line-height: 1.5;
//   letter-spacing: 0.1em;
//   background: url(/common_2024/img/icon/icon_anker-link.svg) left center/0.875rem no-repeat;
//   padding-left: 1.875rem;
//   transition: opacity 0.2s;
// }
// .c-ankerlink a:hover {
//   opacity: 0.6;
// }

// .c-badge {
//   color: #fff;
//   text-align: center;
//   font-size: 0.9375rem;
//   font-weight: 700;
//   line-height: 1.5;
//   letter-spacing: 0.1em;
//   padding-bottom: 0.25em;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 9.625rem;
//   height: 9.375rem;
//   margin-left: auto;
//   margin-right: auto;
//   background: url(/common_2024/img/bg_badge.svg) center center/cover no-repeat;
// }

// .c-btn-important-1 {
//   background: url(/common_2024/img/arrow/arrow_bold_primary.svg) right 1rem center no-repeat;
//   background-size: 1.125rem;
//   border: 1px solid #d1d1d1;
//   border-radius: 1.25rem;
//   width: 17.5rem;
//   height: 6.875rem;
//   box-shadow: 0 0.25rem 0 #d1d1d1;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   padding: 0 2.25rem 0 0.75rem;
//   transition: all 0.2s ease;
// }
// .c-btn-important-1.-external {
//   background-image: url(/common_2024/img/icon/icon_external_primary.svg);
// }
// .c-btn-important-1__icon {
//   width: 3.5rem;
//   height: auto;
//   margin-right: 0.25rem;
// }
// .c-btn-important-1__icon.-app-au {
//   width: 1.6875rem;
//   margin-right: 0.90625rem;
//   margin-left: 0.90625rem;
// }
// .c-btn-important-1__text {
//   font-size: 1rem;
//   font-weight: 700;
//   line-height: 1.25;
//   letter-spacing: 0.05em;
// }
// .c-btn-important-1:focus {
//   border-color: #ea5404;
//   box-shadow: none;
//   transform: translateY(0.25rem);
// }
// @media (hover: hover) and (pointer: fine) {
//   .c-btn-important-1:hover {
//     border-color: #ea5404;
//     box-shadow: none;
//     transform: translateY(0.25rem);
//   }
// }
// @media (max-width: 519px) {
//   .c-btn-important-1 {
//     flex-direction: column;
//     padding: 0.5rem 0 2.5rem;
//     background-position: center bottom 0.75rem;
//     min-height: 10rem;
//     width: 9.25rem;
//   }
//   .c-btn-important-1__icon {
//     width: 4rem;
//     margin: 0;
//   }
//   .c-btn-important-1__text {
//     text-align: center;
//     font-size: 0.875rem;
//     line-height: 1.25;
//     height: 3.3125rem;
//     display: flex;
//     align-items: center;
//   }
// }
// @media (max-width: 519px) {
//   .c-btn-important-1--single {
//     width: 21.25rem;
//     padding: 0.875rem 1.375rem 0.8125rem 1.0625rem;
//     flex-direction: row;
//     gap: 0 1.35rem;
//     background-position: center right 0.75rem;
//     min-height: initial;
//     height: 6.0625rem;
//   }
// }
// @media (max-width: 519px) {
//   .c-btn-important-1--single .c-btn-important-1__text {
//     font-size: 1rem;
//   }
// }

// .c-btn-login {
//   width: 100%;
//   max-width: 19.25rem;
//   background: #fff url(/common_2024/img/arrow/arrow_bold_login.svg) right 1.375rem center no-repeat;
//   background-size: 1.125rem;
//   box-shadow: 0 0.25rem 0 #d1d1d1;
//   height: 3.625rem;
//   text-align: center;
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1;
//   display: inline-flex;
//   align-items: center;
//   border-radius: 0.625rem;
//   overflow: hidden;
//   padding-right: 1.5rem;
//   transition: transform 0.2s ease, box-shadow 0.2s ease;
// }
// .c-btn-login__icon {
//   width: 3.625rem;
//   height: 100%;
//   background: url(/common_2024/img/icon/icon_user.svg) center center/1.25rem #404ac7 no-repeat;
// }
// .c-btn-login__text {
//   flex: 1;
//   text-align: center;
// }
// .c-btn-login:focus {
//   box-shadow: 0 0.0625rem 0 #d1d1d1;
//   transform: translateY(0.1875rem);
// }
// @media (hover: hover) and (pointer: fine) {
//   .c-btn-login:hover {
//     box-shadow: 0 0.0625rem 0 #d1d1d1;
//     transform: translateY(0.1875rem);
//   }
// }

// .c-btn {
//   font-weight: 700;
//   border-radius: 0.5rem;
//   border: 1px solid #d1d1d1;
//   background: #fff no-repeat;
//   box-shadow: 0px 0.25rem 0px 0px #d1d1d1;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   transition: transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease 0.1s;
// }
// .c-btn:focus {
//   box-shadow: none;
//   transform: translateY(0.25rem);
//   border-color: #ea5404;
// }
// @media (hover: hover) and (pointer: fine) {
//   .c-btn:hover {
//     box-shadow: none;
//     transform: translateY(0.25rem);
//     border-color: #ea5404;
//   }
// }
// @media (max-width: 519px) {
//   .c-btn {
//     width: 100%;
//   }
// }
// .c-btn.-bg-yellow {
//   background-color: #fff0c9;
// }
// .c-btn.-lg {
//   font-size: 1.25rem;
//   letter-spacing: 0;
//   min-width: 18.75rem;
//   height: 3.75rem;
//   background-image: url(/common_2024/img/arrow/arrow_lg_primary.svg);
//   background-size: 0.4375rem;
//   background-position: right 0.8125rem center;
// }
// .c-btn.-lg.-s {
//   font-size: 0.875rem;
// }
// .c-btn.-md {
//   font-size: 1rem;
//   width: 21.25rem;
//   height: 2.5rem;
//   background-image: url(/common_2024/img/arrow/arrow_md_primary.svg);
//   background-position: right 0.5rem center;
//   background-size: 0.375rem;
// }
// .c-btn.-bottom {
//   font-size: 1rem;
//   width: 21.25rem;
//   height: 2.5rem;
//   background-image: url(/common_2024/img/arrow/arrow_bold_dawn.svg);
//   background-position: right 0.875rem center;
//   background-size: 0.75rem;
// }
// .c-btn.-up {
//   font-size: 1rem;
//   width: 21.25rem;
//   height: 2.5rem;
//   background-image: url(/common_2024/img/arrow/arrow_bold_up.svg);
//   background-position: right 0.875rem center;
//   background-size: 0.75rem;
// }
// .c-btn.-external {
//   background-image: url(/common_2024/img/icon/icon_external_primary.svg);
// }
// .c-btn.-external.-md {
//   background-size: 0.875rem;
// }
// .c-btn.-external.-lg {
//   background-size: 0.875rem;
//   background-position: right 0.6875rem center;
// }
// .c-btn.prev {
//   background-image: url(/common_2024/img/arrow/arrow_prev_primary.svg);
//   background-position: left 1.1875rem center;
// }

// .c-download-app .c-heading3 {
//   margin-bottom: 0;
// }
// .c-download-app__text {
//   text-align: center;
//   margin-top: 0.75rem;
// }
// .c-download-app__list {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin: 1.5rem auto 0;
//   width: 22.375rem;
// }
// @media (max-width: 519px) {
//   .c-download-app__list {
//     width: 21.25rem;
//   }
// }
// .c-download-app__item img {
//   width: auto;
//   height: 3.3125rem;
// }
// @media (max-width: 519px) {
//   .c-download-app__item img {
//     height: 3.125rem;
//   }
// }
// .c-download-app__btn {
//   margin-top: 1.5rem;
//   text-align: center;
// }

// .c-download-pdf {
//   font-size: 0.75rem;
//   line-height: 1.5;
//   font-weight: 700;
//   background: url(/common_2024/img/icon/icon_pdf.webp) left 0.3em no-repeat;
//   background-size: 2rem;
//   min-height: 2rem;
//   padding: 0.5rem 0 0.5rem 2.75rem;
//   display: inline-flex;
//   align-items: center;
//   margin-top: 1rem;
//   margin-bottom: 1rem;
// }
// .no-webp-support .c-download-pdf {
//   background-image: url(/common_2024/img/icon/icon_pdf.png);
// }
// .c-download-pdf:focus {
//   text-decoration: underline;
// }
// @media (hover: hover) and (pointer: fine) {
//   .c-download-pdf:hover {
//     text-decoration: underline;
//   }
// }
// @media (max-width: 519px) {
//   .c-download-pdf {
//     font-size: 0.75rem;
//     padding: 0.5rem 0 0.5rem 2.25rem;
//     background-size: 1.5rem;
//     background: url(/common_2024/img/icon/icon_pdf.webp) left 1.2em no-repeat;
//   }
// }
// @media (max-width: 519px) {
//   .c-download-pdf.-one-line {
//     font-size: 0.75rem;
//     padding: 0.5rem 0 0.5rem 2.25rem;
//     background-size: 1.5rem;
//     background: url(/common_2024/img/icon/icon_pdf.webp) left 0.5em no-repeat;
//   }
// }
// .c-download-pdf.-external::after {
//   content: "";
//   background: url(/common_2024/img/icon/icon_external.svg) center center/contain no-repeat;
//   width: 0.9375rem;
//   height: 0.75rem;
//   display: inline-block;
//   margin-left: 0.75em;
// }
// @media (max-width: 519px) {
//   .c-download-pdf.-external::after {
//     width: 0.9375rem;
//     height: 0.75rem;
//   }
// }

// .c-contact-box {
//   width: 25.875rem;
//   border-radius: 0.625rem;
//   border: 0.125rem solid #ea5404;
//   padding-top: 1.5rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .c-contact-box {
//     width: 100%;
//     padding-top: 1.25rem;
//   }
// }
// .c-contact-box__heading {
//   color: #ea5404;
//   text-align: center;
//   font-size: 1.5rem;
//   font-weight: 700;
//   line-height: 1;
//   padding-left: 2.5rem;
//   background-size: 1.875rem;
//   background-position: left center;
//   width: -moz-fit-content;
//   width: fit-content;
//   position: relative;
// }
// @media (max-width: 519px) {
//   .c-contact-box__heading {
//     font-size: 1.375rem;
//     background-size: 1.75rem;
//     padding-left: 2.25rem;
//   }
// }
// .c-contact-box__heading::before {
//   content: "";
//   display: block;
//   position: absolute;
//   width: 1.875rem;
//   height: 2.5rem;
//   background: center center no-repeat;
//   background-size: 100%;
//   top: -0.5rem;
//   left: 0;
// }
// @media (max-width: 519px) {
//   .c-contact-box__heading::before {
//     width: 1.75rem;
//   }
// }
// .c-contact-box__heading.-icon-smartphone::before {
//   background-image: url(/common_2024/img/icon/icon_smartphone.svg);
// }
// .c-contact-box__heading.-icon-tel {
//   padding-right: 3.25rem;
// }
// .c-contact-box__heading.-icon-tel::before {
//   background-image: url(/common_2024/img/icon/icon_tel.webp);
// }
// .no-webp-support .c-contact-box__heading.-icon-tel::before {
//   background-image: url(/common_2024/img/icon/icon_tel.png);
// }
// .c-contact-box__heading.-none::before {
//   display: none;
// }
// .c-contact-box__heading-sub {
//   display: block;
//   font-size: 0.625rem;
//   margin-top: 0.25rem;
// }
// .c-contact-box__call-free {
//   width: 2.75rem;
//   height: auto;
//   position: absolute;
//   right: 0;
//   top: -0.625rem;
//   line-height: 1em;
// }
// .c-contact-box__btn {
//   flex: 1;
//   display: flex;
//   align-items: center;
//   padding: 3rem 0;
// }
// @media (max-width: 519px) {
//   .c-contact-box__btn {
//     padding: 1.25rem 0 1.5rem;
//   }
// }
// .c-contact-box__btn .c-btn {
//   height: 4.5rem;
// }
// .c-contact-box__content {
//   width: 18.75rem;
//   padding: 1rem 0;
// }
// .c-contact-box__time {
//   font-size: 0.875rem;
//   font-weight: 700;
//   border-bottom: dotted #000 0.125rem;
//   padding-bottom: 0.375rem;
//   width: -moz-fit-content;
//   width: fit-content;
// }
// .c-contact-box__time span {
//   font-size: 1.5rem;
//   margin: 0 0.125rem;
// }
// .c-contact-box__tel {
//   margin-top: 0.75rem;
//   font-size: 2.75rem;
//   padding-left: 3rem;
//   line-height: 1;
//   font-weight: 700;
//   background: url(/common_2024/img/contact_freecall.webp) left 0.28em no-repeat;
//   background-size: 2.3125rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box__tel {
//     margin-top: 0.5rem;
//   }
// }
// .no-webp-support .c-contact-box__tel {
//   background-image: url(/common_2024/img/contact_freecall.png);
// }
// .c-contact-box__tel a {
//   display: flex;
//   align-items: center;
// }
// .c-contact-box__tel span {
//   font-size: 1.875rem;
// }
// .c-contact-box__note {
//   font-size: 0.625rem;
//   line-height: 1.3;
//   font-feature-settings: "halt" on;
//   text-indent: -1em;
//   padding-left: 1em;
//   margin-top: 0.75rem;
// }
// .c-contact-box__note + .c-contact-box__note {
//   margin-top: 0.5em;
// }

// .c-contact-box-2 {
//   border-radius: 0.5rem;
//   border: 0.125rem solid #ea5404;
//   padding: 2rem 3rem;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .c-contact-box-2 {
//     padding: 1rem 1rem 1.5rem;
//     flex-direction: column;
//   }
// }
// .c-contact-box-2__heading {
//   color: #ea5404;
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1.3em;
//   letter-spacing: 0.1em;
// }
// @media (max-width: 519px) {
//   .c-contact-box-2__heading {
//     text-align: center;
//   }
// }
// .c-contact-box-2__text {
//   font-size: 0.75rem;
//   line-height: 1.3;
//   margin-top: 0.5rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box-2__text {
//     line-height: 1.5;
//   }
// }
// .c-contact-box-2__btn {
//   margin-left: 2rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box-2__btn {
//     margin-left: 0;
//     margin-top: 1rem;
//   }
// }

// .c-contact-box-3 {
//   border-radius: 0.5rem;
//   border: 0.125rem solid #ea5404;
// }
// .c-contact-box-3__header {
//   background: #ea5404;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 1.5rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box-3__header {
//     flex-direction: column;
//     padding: 1.25rem 1em 1.5rem;
//   }
// }
// .c-contact-box-3__header-content {
//   color: #fff;
// }
// .c-contact-box-3__heading {
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1.3;
// }
// @media (max-width: 519px) {
//   .c-contact-box-3__heading {
//     text-align: center;
//   }
// }
// .c-contact-box-3__header-text {
//   margin-top: 0.5rem;
//   font-size: 0.75rem;
//   line-height: 1.3;
//   text-align: center;
// }
// .c-contact-box-3__header-btn {
//   margin-left: 2rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box-3__header-btn {
//     margin-left: 0;
//     margin-top: 1.25rem;
//   }
// }
// .c-contact-box-3__text {
//   font-weight: 700;
//   line-height: 1.4;
//   margin-top: 1.25rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// @media (max-width: 519px) {
//   .c-contact-box-3__text {
//     margin: 1.25rem 0.625rem 0;
//   }
//   .c-contact-box-3__text span {
//     flex: 1;
//     text-align: left;
//   }
// }
// .c-contact-box-3__text::before {
//   content: "";
//   width: 1.625rem;
//   height: 1.75rem;
//   margin-right: 0.75rem;
//   background: url(/common_2024/img/icon/icon_tel.webp) center center/cover no-repeat;
// }
// .c-contact-box-3__list {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 0.5rem 1.5rem;
//   padding: 1.25rem 1.5rem 1.5rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box-3__list {
//     padding: 1.25rem 0.625rem 1.5rem;
//     grid-template-columns: 1fr;
//     gap: 1.5rem;
//   }
// }
// .c-contact-box-3__item {
//   border: 1px solid #d1d1d1;
//   padding: 0.5rem 0.75rem;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .c-contact-box-3__item {
//     flex-direction: column;
//     text-align: center;
//     position: relative;
//     padding: 0.5rem 0.75rem 1.75rem;
//   }
// }
// .c-contact-box-3__item-content {
//   width: 13.625rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box-3__item-content {
//     width: 100%;
//   }
// }
// .c-contact-box-3__title {
//   color: #ea5404;
//   font-weight: 700;
//   line-height: 1.5;
// }
// .c-contact-box-3__note {
//   font-size: 0.5625rem;
//   line-height: 1.5;
// }
// @media (max-width: 519px) {
//   .c-contact-box-3__note {
//     position: absolute;
//     width: 100%;
//     font-size: 0.5rem;
//     text-align: center;
//     bottom: 0.5rem;
//     left: 0;
//   }
// }
// .c-contact-box-3__tel {
//   width: 9.5rem;
//   font-size: 1.5rem;
//   font-weight: 700;
//   line-height: 1;
// }
// @media (max-width: 519px) {
//   .c-contact-box-3__tel {
//     width: 100%;
//     font-size: 1.875rem;
//   }
// }

// .c-contact-box-4 {
//   border-radius: 0.5rem;
//   border: 0.125rem solid #ea5404;
// }
// .c-contact-box-4__heading {
//   color: #fff;
//   font-size: 1.5rem;
//   font-weight: 700;
//   line-height: 4.5rem;
//   background: #ea5404;
//   text-align: center;
//   display: flex;
//   justify-content: center;
// }
// @media (max-width: 519px) {
//   .c-contact-box-4__heading {
//     font-size: 1.375rem;
//     line-height: 3.375rem;
//   }
// }
// @media (min-width: 520px) {
//   .c-contact-box-4 a[href^="tel:"] {
//     pointer-events: none;
//   }
// }
// .c-contact-box-4__content {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 1rem 2rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box-4__content {
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     padding: 1rem 2rem 1.5rem;
//   }
// }
// .c-contact-box-4__title-wrapper {
//   background: url(/common_2024/img/icon/icon_tel.webp) left center/1.5625rem no-repeat;
//   padding-left: 2.5rem;
// }
// .c-contact-box-4__title {
//   color: #ea5404;
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1;
// }
// @media (max-width: 519px) {
//   .c-contact-box-4__title {
//     font-size: 1.375rem;
//     line-height: 1.875rem;
//   }
// }
// .c-contact-box-4__text {
//   font-size: 0.5625rem;
//   line-height: 1.2;
//   margin-top: 0.25rem;
//   max-width: 11.875rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box-4__text {
//     max-width: none;
//   }
// }
// .c-contact-box-4__tel {
//   font-size: 2.25rem;
//   font-weight: 700;
// }
// .c-contact-box-4__tel a {
//   display: flex;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .c-contact-box-4__tel {
//     font-size: 2.75rem;
//     line-height: 1;
//     margin-top: 0.25rem;
//   }
//   .c-contact-box-4__tel span {
//     font-size: 1.875rem;
//   }
// }
// .c-contact-box-4__separator {
//   height: 5.25rem;
//   width: 1px;
//   display: block;
//   background: #ea5404;
// }
// @media (max-width: 519px) {
//   .c-contact-box-4__separator {
//     height: 1px;
//     width: 18.75rem;
//     margin: 1.5rem 0 1.25rem;
//   }
// }
// .c-contact-box-4__btn {
//   margin-left: 0.5rem;
// }
// .c-contact-box-4__btn .c-btn {
//   width: 16.5rem;
// }
// .c-contact-box-4__btn .c-btn.-lg {
//   font-size: 1.25rem;
//   letter-spacing: 0.1;
//   width: 18.75rem;
//   height: 3.75rem;
//   background-image: url(/common_2024/img/arrow/arrow_lg_primary.svg);
//   background-size: 0.4375rem;
//   background-position: right 0.8125rem center;
// }

// .c-contact-box-5 {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 1.5rem 3rem;
//   border-radius: 0.5rem;
//   border: 2px solid #ea5404;
//   width: -moz-fit-content;
//   width: fit-content;
//   margin-left: auto;
//   margin-right: auto;
//   font-weight: 700;
// }
// @media (max-width: 519px) {
//   .c-contact-box-5 {
//     padding: 1rem 0;
//     width: 100%;
//   }
// }
// .c-contact-box-5__heading {
//   color: #ea5404;
//   font-size: 1.25rem;
//   line-height: 1.4;
//   text-align: center;
//   margin-bottom: 0.5rem;
// }
// @media (max-width: 519px) {
//   .c-contact-box-5__heading {
//     line-height: 1.2;
//   }
//   .c-contact-box-5__heading span {
//     font-size: 1rem;
//   }
// }
// .c-contact-box-5__tel {
//   font-size: 2.75rem;
//   letter-spacing: 0.05em;
//   line-height: 1;
// }
// @media (max-width: 519px) {
//   .c-contact-box-5__tel {
//     font-size: 2.5rem;
//   }
// }
// .c-contact-box-5__tel a {
//   display: flex;
//   align-items: center;
// }
// @media (min-width: 520px) {
//   .c-contact-box-5__tel a[href^="tel:"] {
//     pointer-events: none;
//   }
// }
// .c-contact-box-5__tel span {
//   font-size: 1.875rem;
// }

// .c-contact-box-6 {
//   width: 26.75rem;
//   border-radius: 0.625rem;
//   border: 0.125rem solid #ea5404;
//   padding: 1.5rem 2rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
// }
// @media (max-width: 519px) {
//   .c-contact-box-6 {
//     width: 100%;
//     padding: 1.5rem 1rem;
//   }
// }
// .c-contact-box-6__heading {
//   color: #ea5404;
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1;
// }
// @media (max-width: 519px) {
//   .c-contact-box-6__heading {
//     font-size: 1.25rem;
//   }
// }
// .c-contact-box-6__heading::before {
//   content: "";
//   display: block;
//   position: absolute;
//   width: 1.875rem;
//   height: 2.5rem;
//   background: center center no-repeat;
//   background-size: 100%;
//   top: -0.5rem;
//   left: 0;
// }
// @media (max-width: 519px) {
//   .c-contact-box-6__heading::before {
//     width: 1.75rem;
//   }
// }
// .c-contact-box-6__heading.-none::before {
//   display: none;
// }
// .c-contact-box-6__content {
//   width: 18.75rem;
//   padding: 1rem 0;
// }
// .c-contact-box-6__text {
//   font-size: 0.625rem;
// }
// .c-contact-box-6__note {
//   font-size: 0.625rem;
//   line-height: 1.3;
//   font-feature-settings: "halt" on;
//   margin-top: 0.75rem;
// }
// .c-contact-box-6__note + .c-contact-box-6__note {
//   margin-top: 0.5em;
// }
// .c-contact-box-6__note span {
//   color: #ea5404;
// }

// .c-faq {
//   display: grid;
//   gap: 0.75rem;
// }
// .c-faq__item {
//   padding: 0 1.25rem;
//   border-radius: 0.25rem;
//   background: #f3f3f3;
// }
// .c-faq__title {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.5;
//   position: relative;
//   cursor: pointer;
//   padding: 1.5rem 2rem 1.5rem 2.25rem;
// }
// .c-faq__title::before {
//   content: "Q";
//   color: #ea5404;
//   font-size: 1.625rem;
//   font-weight: 700;
//   line-height: 1;
//   left: 0;
//   top: 1.125rem;
//   position: absolute;
// }
// .c-faq__title::after {
//   content: "";
//   width: 1.25rem;
//   height: 1.25rem;
//   background: url(/common_2024/img/icon/icon_toggle_plus.svg) center center/cover no-repeat;
//   position: absolute;
//   right: 0rem;
//   top: 1.5rem;
// }
// @media (max-width: 519px) {
//   .c-faq__title::after {
//     right: 0rem;
//   }
// }
// .c-faq__title.is-active::after {
//   background-image: url(/common_2024/img/icon/icon_toggle_minus.svg);
// }
// .c-faq__wrapper {
//   display: none;
// }
// .c-faq__content {
//   border-top: 1px #d1d1d1 solid;
//   padding-top: 1.5rem;
//   padding-left: 2.25rem;
//   padding-bottom: 1.5rem;
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.3;
//   letter-spacing: 0.03em;
//   position: relative;
// }
// .c-faq__content::before {
//   content: "A";
//   color: #ea5404;
//   font-size: 1.625rem;
//   font-weight: 700;
//   line-height: 1;
//   left: 0;
//   top: 1.125rem;
//   position: absolute;
// }

// .c-feature-card {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 2rem;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .c-feature-card {
//     grid-template-columns: 1fr;
//     gap: 1.5rem;
//   }
// }
// .c-feature-card.-pic-small {
//   grid-template-columns: repeat(4, 1fr);
//   gap: 1.5rem;
// }
// @media (max-width: 519px) {
//   .c-feature-card.-pic-small {
//     grid-template-columns: 1fr;
//     gap: 1.5rem;
//   }
// }
// .c-feature-card__pic {
//   order: 2;
// }
// .-reverse .c-feature-card__pic {
//   order: 1;
// }
// @media (max-width: 519px) {
//   .c-feature-card__pic {
//     order: 1;
//   }
// }
// .c-feature-card__pic img {
//   display: block;
// }
// .c-feature-card__content {
//   line-height: 1.5;
//   order: 1;
// }
// @media (max-width: 519px) {
//   .c-feature-card__content {
//     order: 2;
//     padding: 0 1.5rem;
//   }
// }
// .-pic-small .c-feature-card__content {
//   grid-column: span 3/span 3;
// }
// .-reverse .c-feature-card__content {
//   order: 2;
// }
// @media (max-width: 519px) {
//   .-reverse .c-feature-card__content {
//     order: 2;
//   }
// }
// .c-feature-card .c-text-accent {
//   margin-bottom: 1rem;
// }

// .c-heading1-bg-primary {
//   background: #ea5404;
//   font-size: 1.625rem;
//   line-height: 1.5;
//   padding: 0.875rem 1em;
//   color: #fff;
//   text-align: center;
//   font-weight: 700;
//   margin-bottom: 2rem;
// }
// @media (max-width: 519px) {
//   .c-heading1-bg-primary {
//     margin-left: -0.625rem;
//     margin-right: -0.625rem;
//   }
// }

// .c-heading2-has-line {
//   font-size: 1.5rem;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 1.45;
//   letter-spacing: 0.1em;
//   text-align: center;
//   position: relative;
//   margin-top: 7rem;
//   margin-bottom: 1.25rem;
// }
// .c-heading2-has-line::after {
//   content: "";
//   width: 2.5rem;
//   height: 0.1875rem;
//   background: #ea5404;
//   display: block;
//   margin: 0.5rem auto 0;
// }
// .c-heading2-has-line.-relax {
//   margin-bottom: 2rem;
// }
// .c-heading2-has-line.-relax::after {
//   margin-top: 1.25rem;
// }

// .c-heading2-bg-primary {
//   border-radius: 0.75rem 0.75rem 0px 0px;
//   background: #ea5404;
//   color: #fff;
//   line-height: 1.5;
//   font-size: 1.25rem;
//   padding: 0.5625rem 1em;
//   text-align: center;
//   font-weight: 700;
//   letter-spacing: 0.1em;
// }
// @media (max-width: 519px) {
//   .c-heading2-bg-primary {
//     padding: 0.625rem 1em;
//   }
// }

// .c-heading2-has-border {
//   border-left: 0.25rem solid #ea5404;
//   font-size: 1.5rem;
//   line-height: 1.8;
//   letter-spacing: 0.045rem;
//   padding: 0.15625rem 1.375rem;
//   font-weight: 700;
// }
// @media (max-width: 519px) {
//   .c-heading2-has-border {
//     font-size: 1.25rem;
//     padding: 0.15625rem 0.6875rem;
//     line-height: 1.6;
//   }
// }

// .c-heading2-has-tag,
// .c-heading3-has-tag {
//   font-weight: 700;
//   letter-spacing: 0.1em;
//   line-height: 1.5;
//   display: flex;
//   align-items: center;
// }
// .c-heading2-has-tag__tag,
// .c-heading3-has-tag__tag {
//   color: #fff;
//   font-size: 1rem;
//   line-height: 2.5rem;
//   border-radius: 0.75rem;
//   background: #ea5404;
//   margin-right: 1.25rem;
//   padding: 0 1.125rem;
//   font-weight: 700;
//   letter-spacing: 0;
//   display: inline-block;
// }

// .c-heading2-has-tag {
//   font-size: 1.5rem;
//   margin-top: 7rem;
//   margin-bottom: 3.5rem;
// }

// .c-heading3-has-tag {
//   font-size: 1.25rem;
//   margin-top: 3.5rem;
//   margin-bottom: 2rem;
// }

// .c-heading3 {
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1.5;
//   letter-spacing: 2px;
//   margin-top: 3.5rem;
//   margin-bottom: 2rem;
//   text-align: center;
// }

// /* 先頭のh2はmargin-top:56px */
// .l-main h1 + h2 {
//   margin-top: 3.5rem;
// }

/*********************************************
ul/olタグの共通スタイル
**********************************************/
// .c-list-dot,
// .c-list-number,
// .c-list-check,
// .c-list-number-bracketed,
// .c-list-asterisk {
//   display: grid;
//   font-size: 0.875rem;
//   line-height: 1.5;
//   gap: 0.5em;
//   font-weight: 700;
// }

// .c-list-dot li,
// .c-list-number li {
//   margin-left: 1.2em;
// }

// .c-list-dot,
// .c-list-dot ul {
//   list-style: disc;
// }
// .c-list-dot ul,
// .c-list-dot ul ul {
//   list-style: circle;
//   margin-top: 0.5em;
// }
// .c-list-dot.-dot-red,
// .c-list-dot ul.-dot-red {
//   color: #f20000;
// }
// .c-list-dot.-dot-red span,
// .c-list-dot ul.-dot-red span {
//   color: #000;
// }

// .c-list-number {
//   list-style: decimal;
// }

// .c-list-number-bracketed {
//   counter-reset: item;
// }
// .c-list-number-bracketed li {
//   counter-increment: item;
// }
// .c-list-number-bracketed li::before {
//   content: "(" counter(item) ") ";
// }

// .c-list-asterisk {
//   counter-reset: item;
// }
// .c-list-asterisk li {
//   counter-increment: item;
// }
// .c-list-asterisk li::before {
//   content: "*" counter(item);
//   margin-right: 0.5em;
// }

// .c-list-check li {
//   background: url(/common_2024/img/icon/icon-check.webp) left 0.3125rem/0.75rem no-repeat;
//   padding-left: 1.2em;
// }
// .no-webp-support .c-list-check li {
//   background-image: url(/common_2024/img/icon/icon-check.png);
// }

// .c-list-flow__item {
//   background-image: none !important;
//   position: relative;
//   padding-left: 2rem;
//   padding-bottom: 2rem;
//   /*&:not(:has(+ &))::after,
//   &.last::after,*/
// }
// .c-list-flow__item h4 + p:not(:first-child) {
//   margin-top: 0.75rem;
// }
// .c-list-flow__item::before {
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0.75rem;
//   left: 0;
//   border-radius: 100%;
//   width: 9px;
//   height: 9px;
//   background-color: #000;
// }
// .c-list-flow__item::after {
//   display: block;
//   content: "";
//   position: absolute;
//   top: 1.25rem;
//   left: 4px;
//   height: 100%;
//   border-left: 1px dashed #000;
// }
// .c-list-flow__item:last-child::after {
//   content: none;
// }

// .c-point-box__title {
//   text-align: center;
// }
// .c-point-box__items {
//   margin-top: 2.0625rem;
//   display: flex;
//   flex-wrap: wrap;
//   -moz-column-gap: 2rem;
//        column-gap: 2rem;
//   row-gap: 1.5rem;
//   justify-content: space-between;
// }
// @media (max-width: 519px) {
//   .c-point-box__items {
//     display: flex;
//     flex-direction: column;
//   }
// }
// .c-point-box__item {
//   text-align: center;
//   padding: 0.5rem;
//   border-radius: 5px;
//   border: 1px solid #bdbdbd;
//   background: #fff;
//   width: calc(50% - 1rem);
// }
// @media (max-width: 519px) {
//   .c-point-box__item {
//     width: 100%;
//   }
// }
// .c-point-box__item--balloon {
//   position: relative;
// }
// .c-point-box__item--1column {
//   width: 100%;
// }
// .c-point-box__head {
//   background: #f3f3f3;
//   position: relative;
// }
// .c-point-box__number {
//   color: #ea5404;
//   font-size: 1.875rem;
//   font-weight: 700;
//   line-height: 150%;
//   text-transform: capitalize;
//   padding: 1.25rem 0.625rem;
// }
// @media (max-width: 519px) {
//   .c-point-box__number {
//     text-align: left;
//     padding: 1rem 3rem;
//     font-size: 1.125rem;
//   }
// }
// .c-point-box__number span {
//   font-size: 3rem;
// }
// @media (max-width: 519px) {
//   .c-point-box__number span {
//     font-size: 2.1875rem;
//   }
// }
// .c-point-box__img {
//   max-width: 5.125rem;
//   height: auto;
//   overflow: hidden;
//   position: absolute;
//   top: 50%;
//   right: -6%;
//   transform: translate(-50%, -50%);
//   -webkit-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
// }
// @media (max-width: 519px) {
//   .c-point-box__img {
//     max-width: 3.75rem;
//     top: 47%;
//     right: -1%;
//   }
// }
// .c-point-box__img img {
//   width: 100%;
//   height: 100%;
//   -o-object-fit: cover;
//      object-fit: cover;
// }
// .c-point-box__item--1column .c-point-box__img {
//   right: 0;
// }
// .c-point-box__body {
//   padding: 2.25rem 4.375rem 1.5rem 3rem;
// }
// @media (max-width: 519px) {
//   .c-point-box__body {
//     padding: 1.25rem 0.3125rem;
//   }
// }
// .c-point-box__main {
//   color: #ea5404;
//   font-size: 1.5rem;
//   font-weight: 700;
//   line-height: 100%;
//   display: inline-block;
// }
// .c-point-box__main p {
//   margin-top: 0.625rem;
// }
// .c-point-box__dashed {
//   background-image: linear-gradient(to right, #ea5404, #ea5404 3px, transparent 2px, transparent 2px);
//   background-position: left bottom;
//   background-repeat: repeat-x;
//   background-size: 5px 5px;
//   width: 100%;
//   display: block;
//   height: 2px;
//   text-align: center;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 0.375rem;
// }
// .c-point-box__text {
//   text-align: center;
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 160%;
//   margin-top: 1.125rem;
// }
// @media (max-width: 519px) {
//   .c-point-box__text {
//     text-align: left;
//   }
// }
// .c-point-box__sub {
//   color: #5c5c5c;
//   font-size: 0.625rem;
//   font-weight: 700;
//   line-height: 110%;
//   text-align: left;
//   margin-top: 0.625rem;
// }
// @media (max-width: 519px) {
//   .c-point-box__sub {
//     margin-top: 1.25rem;
//   }
// }
// .c-point-box__photo {
//   aspect-ratio: 320/58;
//   overflow: hidden;
//   max-width: 20rem;
//   margin-top: 1.5rem;
//   margin-right: auto;
//   margin-left: auto;
// }
// .c-point-box__photo img {
//   width: 100%;
//   height: 100%;
//   -o-object-fit: cover;
//      object-fit: cover;
// }
// .c-point-box__balloon {
//   position: absolute;
//   display: inline-block;
//   margin: 1.5em 0;
//   padding: 0 0.625rem;
//   min-width: 120px;
//   max-width: 100%;
//   font-size: 16px;
//   border-radius: 5px;
//   background: #fff682;
//   top: -10%;
//   left: 0.5rem;
// }
// @media (max-width: 959px) {
//   .c-point-box__balloon {
//     top: -13%;
//   }
// }
// @media (max-width: 519px) {
//   .c-point-box__balloon {
//     top: -11%;
//     left: 1rem;
//   }
// }
// .c-point-box__balloon::before {
//   content: "";
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   margin-left: -15px;
//   border: 10px solid transparent;
//   border-top: 10px solid #fff682;
// }
// .c-point-box__balloon p {
//   color: #000;
//   font-size: 0.9375rem;
//   font-weight: 700;
//   line-height: 33.161px; /* 213.333% */
// }
// @media (max-width: 959px) {
//   .c-point-box__balloon p {
//     line-height: 300%;
//   }
// }
// @media (max-width: 519px) {
//   .c-point-box__balloon p {
//     line-height: 200%;
//   }
// }
// .c-point-box__btn {
//   text-align: center;
//   margin-top: 2.875rem;
// }
// @media (max-width: 519px) {
//   .c-point-box__btn {
//     margin-top: 2rem;
//   }
// }

// .c-post-card {
//   background: #fff;
//   padding: 0.5rem 1rem;
//   max-width: 18.75rem;
// }
// .c-post-card__pic {
//   margin-bottom: 0.75rem;
// }
// .c-post-card__title {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.6;
//   letter-spacing: 0.03em;
// }
// .c-post-card__readmore {
//   margin-top: 1rem;
//   text-align: right;
// }
// .c-post-card__readmore a {
//   color: #ea5404;
//   font-size: 0.875rem;
//   font-weight: 700;
//   width: -moz-fit-content;
//   width: fit-content;
//   background: url(/common_2024/img/arrow/arrow_lg_primary.svg) right center/0.4375rem no-repeat;
//   padding-right: 1rem;
//   transition: opacity 0.2s;
// }
// .c-post-card__readmore a:focus {
//   opacity: 0.6;
// }
// @media (hover: hover) and (pointer: fine) {
//   .c-post-card__readmore a:hover {
//     opacity: 0.6;
//   }
// }
// .c-post-card .c-post-meta {
//   color: #ea5404;
//   margin-top: 0.75rem;
// }
// .c-post-card .c-post-meta__category {
//   background: #f3f3f3;
// }

// @media (min-width: 520px) {
//   .c-post-card-slider .splide__list {
//     transform: none !important;
//   }
// }

// .c-post-heading {
//   background: #ea5404;
//   padding: 2rem;
//   color: #fff;
//   font-weight: 700;
//   margin-bottom: 2rem;
// }
// @media (max-width: 519px) {
//   .c-post-heading {
//     margin-left: -0.625rem;
//     margin-right: -0.625rem;
//     padding: 2rem 0.625rem;
//   }
// }
// .c-post-heading__title {
//   font-size: 1.5rem;
//   line-height: 1.5;
// }
// .c-post-heading .c-post-meta {
//   margin-top: 0.25rem;
// }

// .c-post-meta {
//   display: flex;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .c-post-meta {
//     flex-direction: column;
//     align-items: flex-start;
//   }
// }
// .c-post-meta a {
//   transition: opacity 0.2s;
// }
// .c-post-meta a:focus {
//   opacity: 0.6;
// }
// @media (hover: hover) and (pointer: fine) {
//   .c-post-meta a:hover {
//     opacity: 0.6;
//   }
// }
// .c-post-meta__date {
//   font-size: 0.625rem;
//   letter-spacing: 0.1em;
// }
// .c-post-meta__category {
//   background: #fff;
//   color: #ea5404;
//   font-size: 0.625rem;
//   letter-spacing: 0.1em;
//   line-height: 1.8;
//   padding: 0 0.25rem;
//   border-radius: 0.125rem;
// }
// .c-post-meta__date + .c-post-meta__category, meta + .c-post-meta__category {
//   margin-left: 0.75rem;
// }
// @media (max-width: 519px) {
//   .c-post-meta__date + .c-post-meta__category, meta + .c-post-meta__category {
//     margin-left: 0;
//     margin-top: 0.75rem;
//   }
// }
// .c-post-meta__date + .c-post-meta__tag-list, meta + .c-post-meta__tag-list, .c-post-meta__category + .c-post-meta__tag-list {
//   margin-left: 0.75rem;
// }
// @media (max-width: 519px) {
//   .c-post-meta__date + .c-post-meta__tag-list, meta + .c-post-meta__tag-list, .c-post-meta__category + .c-post-meta__tag-list {
//     margin-left: 0;
//     margin-top: 0.5rem;
//   }
// }
// .c-post-meta__tag-list {
//   font-size: 0.625rem;
//   line-height: 1.5;
//   font-weight: 700;
//   display: flex;
// }
// .c-post-meta__tag:not(:first-child) {
//   margin-left: 0.5rem;
// }

// .c-post-pickup {
//   position: relative;
//   background: #f3f3f3;
// }
// .c-post-pickup__tag {
//   background: #fff682;
//   text-align: center;
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 2.375rem;
//   padding: 0 2rem;
//   position: absolute;
//   left: 0;
//   top: -1.1875rem;
//   z-index: 10;
// }
// @media (max-width: 519px) {
//   .c-post-pickup__tag {
//     left: -0.625rem;
//   }
// }
// .c-post-pickup__article {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0.75rem 6.625rem;
// }
// @media (max-width: 519px) {
//   .c-post-pickup__article {
//     flex-direction: column;
//     padding: 0;
//   }
// }
// .c-post-pickup__content {
//   width: 28.5rem;
// }
// @media (max-width: 519px) {
//   .c-post-pickup__content {
//     width: 100%;
//     order: 2;
//     padding: 1rem 2rem 1.5rem;
//   }
// }
// .c-post-pickup__title {
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1.5;
//   margin-bottom: 1.25rem;
// }
// .c-post-pickup__text {
//   font-size: 0.875rem;
//   font-weight: 500;
//   line-height: 1.5;
// }
// .c-post-pickup__readmore {
//   margin-top: 1.5rem;
// }
// @media (max-width: 519px) {
//   .c-post-pickup__readmore {
//     text-align: right;
//   }
// }
// .c-post-pickup__readmore a {
//   color: #ea5404;
//   font-size: 1rem;
//   font-weight: 700;
//   width: -moz-fit-content;
//   width: fit-content;
//   background: url(/common_2024/img/arrow/arrow_lg_primary.svg) right center/0.4375rem no-repeat;
//   padding-right: 1rem;
//   transition: opacity 0.2s;
// }
// .c-post-pickup__readmore a:focus {
//   opacity: 0.6;
// }
// @media (hover: hover) and (pointer: fine) {
//   .c-post-pickup__readmore a:hover {
//     opacity: 0.6;
//   }
// }
// .c-post-pickup__pic {
//   width: 24.5rem;
// }
// @media (max-width: 519px) {
//   .c-post-pickup__pic {
//     width: 100%;
//     order: 1;
//   }
// }
// .c-post-pickup .c-post-meta {
//   color: #ea5404;
//   margin-top: 0.75rem;
// }
// .c-post-pickup .splide__pagination {
//   position: absolute;
//   bottom: -1.5rem;
// }

// .c-step-list {
//   counter-reset: step-list-num;
//   list-style: none;
//   display: grid;
//   gap: 3.5rem;
// }
// .c-step-list__item {
//   position: relative;
// }
// @media (min-width: 520px) {
//   .c-step-list__item {
//     display: flex;
//     align-items: center;
//     border-radius: 0.25rem;
//     border: 2px solid #ea5404;
//   }
//   .c-step-list__item::before {
//     counter-increment: step-list-num; /* counter-resetと同じ文字列 */
//     content: counter(step-list-num); /* カウントした数に応じて番号を表示 */
//     width: 3.1875rem;
//     min-height: 3.1875rem;
//     height: 100%;
//     background: #ea5404;
//     color: #fff;
//     font-size: 1.625rem;
//     font-weight: 700;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
// }
// .c-step-list__item:not(:first-child)::after {
//   content: "";
//   width: 100%;
//   height: 1.25rem;
//   position: absolute;
//   display: block;
//   top: -2.375rem;
//   background: url(/common_2024/img/arrow/arrow_round_primary.svg) center center/contain no-repeat;
// }
// .c-step-list__title {
//   width: 23.75rem;
//   font-size: 1rem;
//   font-weight: 700;
//   letter-spacing: 0;
//   padding: 0.75rem 1.5rem 0.75rem 4.75rem;
// }
// @media (max-width: 519px) {
//   .c-step-list__title {
//     width: 100%;
//     border-radius: 0.25rem;
//     border: 2px solid #ea5404;
//     position: relative;
//     padding: 0.5rem 0.5rem 0.5rem 3.75rem;
//   }
//   .c-step-list__title::before {
//     counter-increment: step-list-num; /* counter-resetと同じ文字列 */
//     content: counter(step-list-num); /* カウントした数に応じて番号を表示 */
//     width: 3.1875rem;
//     min-height: 3.1875rem;
//     height: 100%;
//     background: #ea5404;
//     color: #fff;
//     font-size: 1.625rem;
//     font-weight: 700;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
//   .c-step-list__title .c-text-accent {
//     font-size: 1.375rem;
//   }
// }
// .c-step-list__text {
//   font-size: 0.875rem;
//   line-height: 1.6;
//   font-weight: 700;
// }
// @media (min-width: 520px) {
//   .c-step-list__text {
//     flex: 1;
//     position: relative;
//     padding: 0.5rem 1.5rem;
//   }
//   .c-step-list__text::before {
//     position: absolute;
//     content: "";
//     background: #d1d1d1;
//     width: 1px;
//     height: 100%;
//     left: 0;
//     top: 0rem;
//   }
// }
// @media (max-width: 519px) {
//   .c-step-list__text {
//     margin-top: 0.5rem;
//   }
// }

// @media (max-width: 519px) {
//   .c-table {
//     overflow-x: auto;
//     margin-right: -0.625rem;
//     padding-right: 0.625rem;
//   }
// }
// .c-table__table {
//   width: 100%;
//   border-bottom: 1px solid #afafaf;
//   border-right: 1px solid #afafaf;
// }
// .c-table th,
// .c-table td {
//   font-size: 0.875rem;
//   line-height: 1.5;
//   padding: 1.25rem 2.5rem;
//   vertical-align: middle;
//   border-left: 1px solid #afafaf;
//   border-top: 1px solid #afafaf;
// }
// @media (max-width: 519px) {
//   .c-table th,
//   .c-table td {
//     padding: 1.25rem 0.875rem;
//   }
// }
// .c-table__heading {
//   font-weight: 700;
//   color: #fff;
//   background-color: #ea5404;
//   white-space: nowrap;
//   text-align: center;
// }
// thead .c-table__heading {
//   border-top: none;
// }
// .c-table__heading:first-child {
//   border-left-color: #ea5404;
// }
// .c-table__heading + .c-table__heading {
//   border-left-color: #fff;
// }
// .c-table__title {
//   background-color: #f3f3f3;
//   text-align: center;
// }
// .c-table a {
//   text-decoration: underline;
// }
// @media (min-width: 520px) {
//   .c-table a[href^="tel:"] {
//     pointer-events: none;
//     text-decoration: none;
//   }
// }

// .c-tel-lg {
//   border-radius: 0.5rem;
//   border: 0.125rem solid #ea5404;
//   padding: 1.125rem 2.25rem 1.125rem 1.5rem;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   font-weight: 700;
// }
// @media (max-width: 519px) {
//   .c-tel-lg {
//     padding: 1rem;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//   }
// }
// .c-tel-lg__title {
//   color: #ea5404;
//   font-size: 1.5rem;
//   line-height: 1.3;
//   background: url(/common_2024/img/icon/icon_tel.webp) left center/2.625rem no-repeat;
//   padding-left: 3.5rem;
//   position: relative;
// }
// @media (max-width: 519px) {
//   .c-tel-lg__title {
//     width: -moz-fit-content;
//     width: fit-content;
//     font-size: 1.375rem;
//     background-size: 1.625rem;
//     padding-left: 2rem;
//     line-height: 1.2;
//     margin-bottom: 0.25rem;
//   }
// }
// .c-tel-lg__tel {
//   font-size: 2.75rem;
//   letter-spacing: 0.04em;
//   line-height: 1;
//   position: relative;
// }
// .c-tel-lg__tel a {
//   display: flex;
//   align-items: center;
// }
// @media (min-width: 520px) {
//   .c-tel-lg__tel a[href^="tel:"] {
//     pointer-events: none;
//   }
// }
// .c-tel-lg__tel span {
//   font-size: 1.875rem;
// }
// .c-tel-lg__text {
//   font-size: 0.75rem;
//   margin-top: 0.25rem;
//   line-height: 1;
//   text-align: center;
// }
// @media (max-width: 519px) {
//   .c-tel-lg__text {
//     font-size: 0.5rem;
//     margin-top: 0.75rem;
//     text-align: center;
//   }
// }

// .c-text-accent {
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1.5;
//   letter-spacing: 0.1em;
// }
// .c-text-accent.-underline {
//   color: #ea5404;
//   border-bottom: 0.125rem dotted #ea5404;
//   width: -moz-fit-content;
//   width: fit-content;
// }
// .c-text-accent.-underline.u-text-center {
//   margin-left: auto;
//   margin-right: auto;
// }

// .c-text-box {
//   padding: 1.5rem;
//   border: 1px solid #d1d1d1;
//   position: relative;
// }
// .c-text-box.-rounded {
//   border-radius: 1rem;
// }
// .c-text-box.-bg-full {
//   border: none;
// }
// .c-text-box.-bg-full::after {
//   content: "";
//   margin: 0 calc(50% - 50vw);
//   width: 100vw;
//   height: 100%;
//   position: absolute;
//   display: block;
//   left: 0;
//   top: 0;
//   background-color: #f3f3f3;
//   z-index: -1;
// }
// .c-text-box__heading {
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1.5;
//   letter-spacing: 0.1em;
//   text-align: center;
// }
// .c-text-box__text {
//   font-size: 0.75rem;
//   line-height: 1.5;
//   letter-spacing: 0.03em;
// }
// .c-text-box__heading + .c-text-box__text {
//   margin-top: 1rem;
// }
// .c-textbox-has-link {
//   font-size: 0.75rem;
//   font-weight: 700;
//   line-height: 1.5;
//   border: 1px solid #ea5404;
//   padding: 0.75rem 1rem;
// }
// .c-textbox-has-link__link {
//   text-align: right;
// }
// .c-textbox-has-link__link a {
//   color: #ea5404;
//   background: url(/common_2024/img/arrow/arrow_md_primary.svg) right center/0.375rem no-repeat;
//   padding-right: 0.75rem;
// }
// .c-textbox-has-link__link a:focus {
//   text-decoration: underline;
// }
// @media (hover: hover) and (pointer: fine) {
//   .c-textbox-has-link__link a:hover {
//     text-decoration: underline;
//   }
// }

// .c-banner-label a, .c-banner-label a:after, .c-banner-label a:before, .c-banner-label a span {
//   content: "";
//   width: 9.613125rem;
//   height: 9.3653125rem;
//   background-color: #ea5404;
//   position: absolute;
//   top: 0px;
// }
// @media (max-width: 519px) {
//   .c-banner-label a, .c-banner-label a:after, .c-banner-label a:before, .c-banner-label a span {
//     width: 8.8125rem;
//     height: 4.0625rem;
//   }
// }

// .c-banner-label, .c-banner-label a, .c-banner-label a:after, .c-banner-label a:before {
//   transform: rotate(22.5deg);
// }

// .c-banner-label a span {
//   transform: rotate(-45deg);
//   text-align: center;
//   z-index: 999;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #fff;
//   font-size: 0.9613125rem;
//   letter-spacing: 0.096125rem;
//   line-height: 1.5;
//   font-weight: 700;
// }
// @media (max-width: 519px) {
//   .c-banner-label a span {
//     width: 8.8125rem;
//     height: 4.0625rem;
//     font-size: 0.625rem;
//     letter-spacing: 0.0625rem;
//   }
// }

// .c-banner-label {
//   position: relative;
//   width: 9.613125rem;
//   height: 9.3653125rem;
//   background-color: #ea5404;
// }
// @media (max-width: 519px) {
//   .c-banner-label {
//     width: 8.8125rem;
//     height: 4.0625rem;
//   }
// }

/*********************************************
object/project
**********************************************/
// .p-anipos-feature__inner {
//   max-width: 57.125rem;
// }
// @media (max-width: 519px) {
//   .p-anipos-feature__inner {
//     max-width: initial;
//   }
// }

// .p-anipos-feature__contents {
//   align-items: center;
//   border: 0.125rem solid #ea5404;
//   border-radius: 0.5rem;
// }

// .p-anipos-feature__image {
//   max-width: 16.3125rem;
//   height: auto;
//   overflow: hidden;
// }
// @media (max-width: 519px) {
//   .p-anipos-feature__image {
//     max-width: 11.5625rem;
//   }
// }

// .p-anipos-feature__image img {
//   width: 100%;
//   height: auto;
//   -o-object-fit: cover;
//      object-fit: cover;
// }

// .p-anipos-feature__text-block {
//   flex: 1;
// }

// @media (max-width: 519px) {
//   .p-cards {
//     width: 95%;
//     margin: 0 auto;
//   }
// }

// .p-cards__list {
//   display: grid;
//   gap: 1.875rem;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 2.25rem;
// }
// @media (max-width: 519px) {
//   .p-cards__list {
//     grid-template-columns: repeat(1, 1fr);
//     gap: 1.5rem;
//   }
// }

// .p-cards__item {
//   border-radius: 0.625rem;
//   overflow: hidden;
//   border: 1px solid #CFCFCF;
//   display: flex;
//   flex-direction: column;
// }

// .p-cards__item-head {
//   background-color: #F3F3F3;
//   display: flex;
//   padding: 1rem;
//   gap: 0.9375rem;
//   justify-content: center;
//   align-items: center;
// }

// .p-cards-item-img01 {
//   width: 36.5%;
// }

// .p-cards-item-img02 {
//   width: 18%;
// }

// .p-cards-item-img03 {
//   width: 13.3%;
// }

// .p-cards__item-title {
//   font-size: 1.125rem;
//   font-weight: bold;
//   line-height: 1.4;
// }

// .p-cards__item-body {
//   padding: 0.6875rem 1.3125rem 1rem;
//   margin-top: auto;
//   margin-bottom: auto;
// }

// .p-cards__item-text {
//   font-size: 0.875rem;
//   font-weight: bold;
//   line-height: 1.5;
// }

// .p-cards__item-link {
//   font-size: 0.75rem;
//   font-weight: medium;
//   line-height: auto;
//   margin-top: 1.0625rem;
//   display: block;
// }

// .p-column__article {
//   margin-bottom: 5rem;
// }
// .p-column__article, .p-column__article .c-list-dot, .p-column__article .c-list-dot ul, .p-column__article .c-list-number, .p-column__article .c-download-pdf {
//   font-size: 1rem;
//   line-height: 1.8;
// }
// @media (max-width: 519px) {
//   .p-column__article, .p-column__article .c-list-dot, .p-column__article .c-list-dot ul, .p-column__article .c-list-number, .p-column__article .c-download-pdf {
//     font-size: 0.875rem;
//     line-height: 1.5;
//   }
// }
// .p-column__eyecatch {
//   margin-top: 3.5rem;
//   margin-bottom: 3rem;
//   border-radius: 0.5rem;
//   overflow: hidden;
// }
// .p-column__eyecatch img {
//   display: block;
// }
// .p-column__heading {
//   font-size: 1.5rem;
//   font-weight: 700;
//   letter-spacing: 0.03em;
//   background: #f3f3f3;
//   border-left: 0.25rem solid #ea5404;
//   line-height: 1.4;
//   padding: 0.125rem 0 0.125rem 0.75rem;
//   margin: 3rem 0 1.5rem;
// }
// @media (max-width: 519px) {
//   .p-column__heading {
//     font-size: 1.25rem;
//   }
// }

// .p-column-cta {
//   position: fixed;
//   right: 1rem;
//   top: 50%;
//   transform: translateY(-50%);
//   z-index: 10001;
//   transition: opacity 0.2s, visibility 0.2s;
// }
// @media (max-width: 519px) {
//   .p-column-cta {
//     position: sticky;
//     bottom: 0.625rem;
//     left: 0;
//     transform: none;
//     width: 22.5rem;
//     border-radius: 0.75rem;
//     border: 0.1875rem solid #ea5404;
//     background: #fff;
//     box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.25);
//     padding: 1rem 0.5rem 1.25rem;
//   }
// }
// .is-drawer-open .p-column-cta {
//   opacity: 0;
//   visibility: hidden;
// }
// .p-column-cta__heading {
//   display: none;
// }
// @media (max-width: 519px) {
//   .p-column-cta__heading {
//     color: #ea5404;
//     text-align: center;
//     font-size: 0.875rem;
//     font-weight: 700;
//     letter-spacing: 0.1em;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 0.75rem;
//   }
//   .p-column-cta__heading::after {
//     width: 1rem;
//     height: 1.125rem;
//     background: url(/common_2024/img/icon/icon_footprints.svg) center center/contain no-repeat;
//     content: "";
//   }
// }
// .p-column-cta__list {
//   display: grid;
//   gap: 1rem;
// }
// @media (max-width: 519px) {
//   .p-column-cta__list {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     gap: 0;
//   }
// }
// .p-column-cta__item {
//   text-align: center;
//   border-radius: 0.5rem;
//   background: #fff;
//   border: 1px solid #d1d1d1;
//   width: 8.25rem;
//   box-shadow: 0px 0.375rem 0px 0px #d1d1d1;
// }
// .p-column-cta__item.-primary {
//   color: #ea5404;
// }
// @media (max-width: 519px) {
//   .p-column-cta__item.-primary {
//     border: none;
//     color: #fff;
//     background-color: #ea5404;
//     background-image: url(/common_2024/img/arrow/arrow_bold_white.svg);
//     box-shadow: 0px 0.25rem 0px 0px #ab3b00;
//   }
// }
// @media (max-width: 519px) {
//   .p-column-cta__item.-yellow {
//     background-color: #fff682;
//     background-image: url(/common_2024/img/arrow/arrow_bold_black.svg);
//     border: 0.1875rem solid #ffef28;
//     box-shadow: 0px 0.25rem 0px 0px #a69c1b;
//   }
// }
// .p-column-cta__item.-green {
//   color: #0ab606;
// }
// @media (max-width: 519px) {
//   .p-column-cta__item.-green {
//     border: none;
//     color: #fff;
//     background-color: #0ab606;
//     background-image: url(/common_2024/img/arrow/arrow_bold_white.svg);
//     box-shadow: 0px 0.25rem 0px 0px #037100;
//   }
// }
// @media (max-width: 519px) {
//   .p-column-cta__item {
//     width: 10.375rem;
//     background-position: right 0.375rem center;
//     background-size: 1.4375rem;
//     background-repeat: no-repeat;
//   }
// }
// .p-column-cta__title {
//   font-size: 0.6875rem;
//   font-weight: 700;
//   border: 2px solid #fff;
//   line-height: 2rem;
//   border-radius: 0.5rem 0.5rem 0 0;
// }
// @media (max-width: 519px) {
//   .p-column-cta__title {
//     display: none;
//   }
// }
// .p-column-cta__item.-primary .p-column-cta__title {
//   color: #fff;
//   background: #ea5404;
// }
// .p-column-cta__item.-yellow .p-column-cta__title {
//   background: #fff682;
// }
// .p-column-cta__item.-green .p-column-cta__title {
//   color: #fff;
//   background: #0ab606;
// }
// .p-column-cta__text {
//   text-align: center;
//   font-size: 0.875rem;
//   font-weight: 700;
//   padding: 0.375rem 0 0.5rem;
//   line-height: 1.5;
// }
// @media (max-width: 519px) {
//   .p-column-cta__text {
//     text-align: left;
//     font-size: 0.75rem;
//     line-height: 1.2;
//     padding-left: 12px;
//   }
//   .p-column-cta__text span {
//     font-size: 1.25rem;
//   }
// }

// .p-column-toc__heading {
//   font-size: 1.125rem;
//   font-weight: 700;
//   letter-spacing: 0.1em;
//   line-height: 1;
//   margin-bottom: 1rem;
// }
// .p-column-toc__list {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.6;
//   letter-spacing: 0.1em;
//   display: grid;
//   gap: 0.25rem;
//   padding-left: 0.625rem;
// }
// .p-column-toc__item {
//   padding-left: 1.75rem;
//   background: url(/pc/keiyakusya/assets/img/toc_arrow.svg) left 0.5625rem/0.75rem no-repeat;
// }
// .p-column-toc__item a {
//   transition: color 0.2s;
// }
// .p-column-toc__item a:focus {
//   color: #ea5404;
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-column-toc__item a:hover {
//     color: #ea5404;
//   }
// }

// .p-column-supervisor {
//   display: flex;
//   width: 29.1875rem;
//   justify-content: space-between;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .p-column-supervisor {
//     display: block;
//     width: 86.8%;
//   }
// }
// .p-column-supervisor__pic {
//   width: 7.25rem;
//   order: 1;
// }
// @media (max-width: 519px) {
//   .p-column-supervisor__pic {
//     margin-right: 1rem;
//   }
// }
// .p-column-supervisor__content {
//   order: 2;
//   width: 20rem;
// }
// @media (max-width: 519px) {
//   .p-column-supervisor__content {
//     width: 100%;
//   }
// }
// @media (max-width: 519px) {
//   .p-column-supervisor__header {
//     display: flex;
//     align-items: center;
//     margin-bottom: 1.25rem;
//   }
// }
// .p-column-supervisor__name {
//   color: #ea5404;
//   font-size: 1rem;
//   line-height: 1.3;
//   letter-spacing: 0.03em;
//   margin-bottom: 0.5rem;
//   font-weight: 700;
// }
// @media (max-width: 519px) {
//   .p-column-supervisor__name {
//     font-size: 1.125rem;
//     margin-bottom: 0;
//     order: 2;
//   }
// }
// .p-column-supervisor__text {
//   font-size: 0.875rem;
//   line-height: 1.3;
//   letter-spacing: 0.03em;
//   font-weight: 700;
// }
// .p-column-supervisor__text + .p-column-supervisor__text {
//   margin-top: 0.5rem;
// }
// .p-column-supervisor + .p-column-share {
//   margin-top: 2.5rem;
// }

// .p-column-share__heading {
//   font-size: 1rem;
//   line-height: 1.5;
//   font-weight: 700;
//   text-align: center;
// }
// .p-column-share__list {
//   display: grid;
//   gap: 1.25rem;
//   grid-template-columns: repeat(3, 2.5rem);
//   margin-top: 1rem;
//   justify-content: center;
// }
// .p-column-share__item {
//   width: 2.5rem;
// }
// .p-column-share__item a {
//   transition: opacity 0.2s;
// }
// .p-column-share__item a:focus {
//   opacity: 0.6;
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-column-share__item a:hover {
//     opacity: 0.6;
//   }
// }
// .p-column-share__item a img {
//   height: auto;
// }

// .p-feature-card-grid {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 1.5rem;
// }
// .p-feature-card-grid .c-feature-card {
//   gap: 1rem;
// }
// .p-feature-card-grid .c-text-accent {
//   margin-bottom: 0.75rem;
// }
// @media (max-width: 519px) {
//   .p-feature-card-grid {
//     grid-template-columns: 1fr;
//     gap: 2rem;
//   }
// }

// .p-keiyakusha-login-list {
//   width: -moz-fit-content;
//   width: fit-content;
//   margin: 1.25rem auto;
//   background: #fff;
//   padding: 1.5rem 2rem;
// }
// .p-keiyakusha-login-list .c-list-dot {
//   -moz-column-gap: 1.5rem;
//        column-gap: 1.5rem;
// }
// @media (max-width: 519px) {
//   .p-keiyakusha-login-list {
//     padding: 1.5rem 1.5rem;
//   }
// }

// @media (min-width: 520px) {
//   .p-keiyakusya-menu {
//     display: flex;
//     flex-flow: row wrap;
//     justify-content: center;
//     gap: 1.5rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-keiyakusya-menu {
//     display: grid;
//     gap: 1rem 0.75rem;
//     grid-template-columns: 1fr 1fr;
//   }
// }

// .p-keiyakusya-accordion {
//   max-width: 51.0625rem;
// }

// .p-keiyakusya-accordion__wrapper {
//   padding-bottom: 2.25rem;
// }

// .p-keiyakusya-accordion__heading {
//   background-color: #dcdcdc;
//   border-radius: 0.375rem;
// }
// .p-keiyakusya-accordion__heading::after {
//   top: 50%;
//   transform: translateY(-50%);
// }

// .p-keiyakusya-accordion__content {
//   display: flex;
//   justify-content: center;
//   gap: 0 6.015rem;
//   margin-top: 2.5rem;
//   position: relative;
// }
// .p-keiyakusya-accordion__content::before {
//   content: "";
//   background-color: #fff;
//   width: 0.125rem;
//   height: 16.5625rem;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-accordion__content::before {
//     display: none;
//   }
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-accordion__content {
//     flex-wrap: wrap;
//     padding-bottom: 2.1875rem;
//   }
// }

// .p-keiyakusya-accordion__item {
//   max-width: 19.1875rem;
//   width: 100%;
// }

// .p-keiyakusya-accordion__wrap {
//   display: flex;
//   gap: 0 0.8125rem;
//   flex-wrap: wrap;
// }
// .p-keiyakusya-accordion__wrap .u-text-center {
//   width: 100%;
// }

// .p-keiyakusya-accordion__btn {
//   position: relative;
//   max-width: 9.1875rem;
//   width: 100%;
//   font-weight: 700;
//   border-radius: 0.5rem;
//   border: 1px solid #d1d1d1;
//   background: #fff no-repeat;
//   box-shadow: 0px 0.25rem 0px 0px #d1d1d1;
//   display: inline-flex;
//   justify-content: center;
//   transition: transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease 0.1s;
//   min-height: 9.75rem;
// }
// .p-keiyakusya-accordion__btn:focus {
//   box-shadow: none;
//   transform: translateY(0.25rem);
//   border-color: #ea5404;
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-keiyakusya-accordion__btn:hover {
//     box-shadow: none;
//     transform: translateY(0.25rem);
//     border-color: #ea5404;
//   }
// }

// .p-keiyakusya-accordion__icon.-sm {
//   max-width: 2.8125rem;
//   width: 100%;
//   margin-top: 0.4375rem;
// }
// .p-keiyakusya-accordion__icon.-md {
//   max-width: 3.5rem;
//   width: 100%;
// }

// .p-keiyakusya-accordion__text {
//   font-size: 0.875rem;
//   line-height: 1.4285714286;
//   font-weight: 700;
//   text-align: center;
// }

// .p-keiyakusya-accordion__arrow {
//   max-width: 1.0965rem;
//   width: 100%;
//   position: absolute;
//   bottom: 0.6875rem;
//   left: 50%;
//   transform: translateX(-50%);
// }

// .p-keiyakusya-accordion-btn {
//   font-weight: 700;
//   border-radius: 0.5rem;
//   border: 1px solid #d1d1d1;
//   background: #fff no-repeat;
//   box-shadow: 0px 0.25rem 0px 0px #d1d1d1;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   transition: transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease 0.1s;
//   height: 3.3125rem;
//   width: 100%;
//   background-image: url(/common_2024/img/arrow/arrow_md_primary.svg);
//   background-position: right 0.5rem center;
//   background-size: 0.375rem;
// }
// .p-keiyakusya-accordion-btn:focus {
//   box-shadow: none;
//   transform: translateY(0.25rem);
//   border-color: #ea5404;
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-keiyakusya-accordion-btn:hover {
//     box-shadow: none;
//     transform: translateY(0.25rem);
//     border-color: #ea5404;
//   }
// }

// .p-keiyakusya-accordion-inner {
//   border-radius: 0.5rem;
//   border: 0.0625rem solid #c9c9c9;
//   max-width: 44.75rem;
// }

// .p-keiyakusya-accordion-inner__heading {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 4.8125rem;
//   border-radius: 0.625rem 0.625rem 0px 0px;
// }

// .p-keiyakusya-accordion-inner__icon {
//   max-width: 4rem;
// }

// @media (max-width: 519px) {
//   .p-keiyakusya-accordion-inner__sub-heading {
//     position: relative;
//     cursor: pointer;
//   }
// }
// .p-keiyakusya-accordion-inner__sub-heading::after {
//   content: "";
//   width: 1.25rem;
//   height: 1.25rem;
//   background: url(/common_2024/img/icon/icon_toggle_plus.svg) center center/cover no-repeat;
//   position: absolute;
//   right: 1.25rem;
//   top: 0rem;
// }
// @media (max-width: 519px) {
//   .p-keiyakusya-accordion-inner__sub-heading::after {
//     right: 0.625rem;
//   }
// }

// @media (max-width: 519px) {
//   .p-keiyakusya-accordion-inner__sub-content {
//     display: none;
//   }
// }

// @media (max-width: 519px) {
//   .p-keiyakusya-accordion-inner__sub-heading.is-active::after {
//     background-image: url(/common_2024/img/icon/icon_toggle_minus.svg);
//   }
// }

// .p-keiyakusya-accordion-inner__number {
//   display: flex;
//   align-items: center;
//   gap: 0 0.3125rem;
//   font-size: 0.75rem;
//   font-weight: 700;
//   max-width: 20.1875rem;
//   margin-left: auto;
//   margin-right: auto;
// }
// .p-keiyakusya-accordion-inner__number span {
//   max-width: 1.7375rem;
// }

// .p-keiyakusya-accordion-inner__img {
//   max-width: 20.1875rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-keiyakusya-accordion-sub-inner {
//   border-radius: 0.5rem;
//   border: 0.125rem solid #ea5404;
//   padding-bottom: 1.25rem;
//   max-width: 44.75rem;
//   margin-left: auto;
//   margin-right: auto;
// }
// .p-keiyakusya-accordion-sub-inner .c-contact-box {
//   width: 21rem;
//   border-radius: 0.3125rem;
//   border: 0.125rem solid #b3b3b3;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-accordion-sub-inner .c-contact-box {
//     width: 100%;
//     padding-left: 0.75rem;
//     padding-right: 0.75rem;
//   }
// }
// .p-keiyakusya-accordion-sub-inner .c-contact-box__btn .c-btn {
//   height: 3rem;
//   width: 16.25rem;
// }
// .p-keiyakusya-accordion-sub-inner .tel_none {
//   background-image: none;
// }
// .p-keiyakusya-accordion-sub-inner .sm\:u-grid-cols-1 {
//   justify-items: center;
// }
// .p-keiyakusya-accordion-sub-inner .c-contact-box__heading.-icon-tel {
//   padding-right: 0;
// }

// .p-keiyakusya-accordion-sub-inner__heading {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 6.8125rem;
//   color: #fff;
//   font-weight: 700;
//   text-align: center;
//   font-size: 1.375rem;
// }

// .p-keiyakusya-accordion-sub-inner__icon {
//   max-width: 2.25rem;
// }

// .p-keiyakusya-type__title {
//   font-size: 1.5rem;
//   font-weight: 700;
//   line-height: 1.5;
//   letter-spacing: 0.15rem;
//   position: relative;
//   width: -moz-fit-content;
//   width: fit-content;
//   margin: 0 auto;
//   padding: 0 2.5rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-type__title {
//     font-size: 1rem;
//     white-space: nowrap;
//   }
// }
// .p-keiyakusya-type__title span {
//   color: #ea5404;
//   font-size: 2rem;
//   letter-spacing: 0.2rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-type__title span {
//     font-size: 1.75rem;
//   }
// }
// .p-keiyakusya-type__title::before {
//   content: "";
//   width: 2.3125rem;
//   height: 3.9825rem;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   background-image: url(/pc/koutsuujiko/assets/img/koutsuujiko_section_title_before.webp);
//   background-size: contain;
//   background-repeat: no-repeat;
// }
// .p-keiyakusya-type__title::after {
//   content: "";
//   width: 2.3125rem;
//   height: 3.9825rem;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   background-image: url(/pc/koutsuujiko/assets/img/koutsuujiko_section_title_after.webp);
//   background-size: contain;
//   background-repeat: no-repeat;
// }

// .p-keiyakusya-type__banner {
//   max-width: 50.75rem;
// }

// .p-keiyakusha-covenants_description {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.6;
//   letter-spacing: 0.03em;
//   text-align: left;
//   width: -moz-fit-content;
//   width: fit-content;
//   margin: 0 auto;
// }
// .p-keiyakusha-covenants_text {
//   margin-top: 1rem;
//   font-size: 0.625rem;
//   font-weight: 500;
//   line-height: 1.5;
//   letter-spacing: 0.1em;
//   text-align: center;
// }
// .p-keiyakusha-covenants ul {
//   text-align: left;
//   margin: 0 auto;
//   max-width: -moz-fit-content;
//   max-width: fit-content;
// }

// .p-keiyakusya-roadservice_text {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.6;
//   letter-spacing: 0.03em;
//   text-align: left;
//   width: -moz-fit-content;
//   width: fit-content;
//   margin: 0 auto;
// }

// .p-process-list {
//   font-weight: 700;
//   display: grid;
//   gap: 3.5rem;
//   position: relative;
// }
// .p-process-list::after {
//   background: url(/pc/keiyakusya/assets/img/keiyakusha_vector01.png) center center/contain no-repeat;
//   position: absolute;
//   content: "";
//   display: block;
//   left: 50%;
//   top: -6%;
//   transform: translate(-50%, -50%);
//   -webkit-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   height: 4.5625rem;
//   width: 9.625rem;
// }
// @media (max-width: 519px) {
//   .p-process-list::after {
//     top: -5%;
//   }
// }
// .p-process-list::before {
//   content: "事故発生";
//   display: block;
//   height: 1.25rem;
//   position: absolute;
//   top: -6%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   -webkit-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   width: -3.75rem;
//   z-index: 1;
//   color: #fff;
//   letter-spacing: 0.0625rem;
// }
// @media (max-width: 519px) {
//   .p-process-list::before {
//     top: -5%;
//   }
// }
// .p-process-list__item {
//   display: flex;
//   border-radius: 5px;
//   border: 2px solid #ea5404;
//   position: relative;
// }
// .p-process-list__item:not(:first-child)::after {
//   background: url(/common_2024/img/arrow/arrow_round_primary.svg) center center/contain no-repeat;
//   content: "";
//   display: block;
//   height: 1.25rem;
//   position: absolute;
//   top: -2.375rem;
//   width: 100%;
// }
// .p-process-list__head {
//   width: 12.5rem;
//   font-size: 1.625rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-right: 2px solid #ea5404;
// }
// @media (max-width: 519px) {
//   .p-process-list__head {
//     font-size: 0.8125rem;
//     width: 5.375rem;
//   }
// }
// .p-process-list__bottom {
//   padding: 0.8125rem 3.125rem;
//   width: calc(100% - 12.5rem);
// }
// @media (max-width: 519px) {
//   .p-process-list__bottom {
//     padding: 0.8125rem 0.3125rem;
//     width: calc(100% - 6.75rem);
//   }
// }
// .p-process-list__list {
//   list-style: disc;
//   display: grid;
//   line-height: 1.5;
//   gap: 0.4em;
// }
// .p-process-list__dot {
//   text-indent: -1em;
//   padding-left: 1em;
//   font-size: 0.875rem;
// }
// @media (max-width: 519px) {
//   .p-process-list__dot {
//     font-size: 0.8125rem;
//   }
// }
// .p-process-list__text {
//   font-size: 0.875rem;
//   padding-left: 1rem;
//   margin-top: 0.125rem;
//   line-height: 1.5;
// }
// @media (max-width: 519px) {
//   .p-process-list__text {
//     font-size: 0.75rem;
//   }
// }

// .p-qr {
//   justify-content: center;
// }

// .p-qr__image {
//   width: 13.75rem;
//   height: auto;
//   overflow: hidden;
// }
// @media (max-width: 519px) {
//   .p-qr__image {
//     display: none;
//   }
// }

// .p-qr__image img {
//   width: 100%;
//   height: auto;
//   -o-object-fit: cover;
//      object-fit: cover;
// }

// .p-qr__link {
//   width: 11.125rem;
// }
// @media (max-width: 519px) {
//   .p-qr__link {
//     height: 3rem;
//     width: auto;
//   }
// }

// .p-qr__link img {
//   width: 100%;
//   height: auto;
// }

// @media (max-width: 519px) {
//   .p-qr__text-block {
//     display: inline-block;
//   }
// }

// @media (max-width: 519px) {
//   .p-qr__text {
//     display: none;
//   }
// }

// .p-scene-cards {
//   display: flex;
//   width: 100%;
//   max-width: 55.5rem;
// }
// @media (max-width: 959px) {
//   .p-scene-cards {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     max-width: 43.75rem;
//     -moz-column-gap: 6%;
//          column-gap: 6%;
//     row-gap: 1.25rem;
//   }
// }

// .p-scene-card {
//   padding: 1.25rem 0.625rem;
//   border: 2px solid #ea5404;
//   width: 25%;
// }
// @media (max-width: 959px) {
//   .p-scene-card {
//     width: 100%;
//     border-radius: 1.25rem;
//     padding: 0.625rem;
//   }
// }
// .p-scene-card:not(:first-child) {
//   border-left: none;
// }
// @media (max-width: 959px) {
//   .p-scene-card:not(:first-child) {
//     border-left: 2px solid #ea5404;
//   }
// }
// .p-scene-card__head {
//   font-size: 0.875rem;
//   font-weight: bold;
//   line-height: 1.6;
//   text-align: center;
// }
// .p-scene-card__image {
//   width: 9.6875rem;
//   height: 7.375rem;
//   -o-object-fit: cover;
//      object-fit: cover;
//   aspect-ratio: 155/118;
//   margin: 0.625rem auto 0;
//   display: block;
// }
// @media (max-width: 959px) {
//   .p-scene-card__image {
//     width: 7.8125rem;
//     height: 5.9375rem;
//     aspect-ratio: 125/95;
//   }
// }
// .p-scene-card__image img {
//   width: 100%;
//   height: auto;
// }
// .p-scene-card__text {
//   font-size: 0.875rem;
//   font-weight: bold;
//   line-height: 1.6;
//   margin-top: 0.8125rem;
// }
// @media (max-width: 959px) {
//   .p-scene-card__text {
//     margin-top: 0.625rem;
//     font-size: 0.75rem;
//   }
// }

// .p-worried {
//   margin: 0 auto;
// }
// .p-worried__inner {
//   padding-left: 1.25rem;
//   padding-right: 1.25rem;
//   margin-left: auto;
//   margin-right: auto;
//   width: 100%;
// }
// @media (max-width: 959px) {
//   .p-worried__inner {
//     max-width: 43.75rem;
//     font-size: 0.625rem;
//   }
// }
// .p-worried__wrapper {
//   margin-left: auto;
//   margin-right: auto;
//   width: 100%;
//   max-width: 36.625rem;
//   box-sizing: border-box;
//   gap: 10%;
//   font-size: 1.25rem;
//   font-weight: bold;
// }
// @media (max-width: 959px) {
//   .p-worried__wrapper {
//     max-width: 43.75rem;
//     gap: 2%;
//   }
// }
// .p-worried__img {
//   width: 30%;
//   -o-object-fit: cover;
//      object-fit: cover;
//   aspect-ratio: 175/170;
//   margin-top: 0.625rem;
// }
// @media (max-width: 959px) {
//   .p-worried__img {
//     aspect-ratio: 104/101;
//   }
// }
// .p-worried__img img {
//   width: 100%;
//   height: auto;
// }
// .p-worried__container {
//   width: 60%;
//   display: flex;
//   align-items: center;
// }
// @media (max-width: 959px) {
//   .p-worried__container {
//     width: 68%;
//     align-items: flex-start;
//   }
// }

// .p-worried__container__box {
//   flex-direction: column;
// }

// .p-worried__container .p-worried__box .p-worried__head {
//   font-size: 1.25rem;
//   font-weight: bold;
// }
// .p-worried__container .p-worried__box .p-worried__text {
//   font-size: 0.875rem;
//   font-weight: bold;
//   margin-top: 0.25rem;
// }
// @media (max-width: 959px) {
//   .p-worried__container .p-worried__box .p-worried__text {
//     margin-top: 0.125rem;
//   }
// }
// .p-worried__container .p-worried__box .p-worried__primary {
//   font-size: 0.875rem;
//   font-weight: bold;
//   margin-top: 0.25rem;
// }
// @media (max-width: 959px) {
//   .p-worried__container .p-worried__box .p-worried__primary {
//     font-size: 1.125rem;
//     margin-top: 0.125rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-worried__container .p-worried__box .p-worried__primary {
//     font-size: 0.875rem;
//   }
// }
// .p-worried__container .p-worried__box .p-worried__annotation {
//   font-size: 0.6875rem;
//   font-weight: medium;
//   margin-top: 0.5rem;
// }
// @media (max-width: 959px) {
//   .p-worried__container .p-worried__box .p-worried__annotation {
//     font-size: 0.625rem;
//     margin-top: 0.125rem;
//   }
// }

// .p-accordion-check__head {
//   font-size: 0.875rem;
//   font-weight: bold;
//   line-height: 1.6;
// }
// .p-accordion-check__items {
//   padding-left: 1.5em;
//   text-indent: -1em;
// }
// .p-accordion-check__item {
//   font-size: 0.875rem;
//   font-weight: bold;
//   line-height: 2;
//   margin-top: 1.25rem;
// }
// .p-accordion-check__item span {
//   margin-top: 0.625rem;
//   display: inline-block;
// }
// .p-accordion-check__item span .p-accordion-check__annotation {
//   text-indent: 0em;
//   font-size: 0.6875rem;
//   line-height: 1.2;
// }
// .p-accordion-check__lists {
//   padding-left: 1.5em;
//   text-indent: -1em;
// }
// .p-accordion-check__list {
//   font-size: 0.875rem;
//   font-weight: bold;
//   line-height: 2;
// }

// .p-step-list__item {
//   position: relative;
// }
// .p-step-list__item:not(:first-child)::after {
//   background: url(/common_2024/img/arrow/arrow_round_primary.svg) center center/contain no-repeat;
//   content: "";
//   display: block;
//   height: 1.25rem;
//   position: absolute;
//   top: -2.375rem;
//   width: 100%;
// }

// .p-step-list__title {
//   align-items: center;
//   border: 2px solid #ea5404;
//   border-radius: 0.25rem 0.25rem 0 0;
//   display: flex;
//   min-height: 3.3125rem;
//   margin-top: 0;
//   margin-bottom: 0;
//   position: relative;
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .p-step-list__title {
//     padding: 1rem 0.5rem 1rem 3.75rem;
//     border-radius: 0.25rem 0.25rem 0 0;
//   }
// }
// .p-step-list__title::before {
//   align-items: center;
//   background: #ea5404;
//   color: #fff;
//   content: counter(step-list-num);
//   counter-increment: step-list-num;
//   display: flex;
//   font-size: 1.625rem;
//   font-weight: 700;
//   height: 100%;
//   justify-content: center;
//   left: 0;
//   min-height: 3.1875rem;
//   position: absolute;
//   top: 0;
//   width: 3.1875rem;
// }

// .p-step-list__text-wrap {
//   background-color: #fff5ed;
//   border: 2px solid #ea5404;
//   border-top: none;
//   border-radius: 0 0 0.25rem 0.25rem;
// }
// .p-step-list__text-wrap.-flex {
//   align-items: center;
//   padding: 2rem 3.5rem;
//   display: flex;
// }
// @media (max-width: 519px) {
//   .p-step-list__text-wrap.-flex {
//     display: block;
//     padding: 0.75rem 0.75rem 1.5rem;
//   }
// }

// .p-step-list__down-img {
//   width: 8.3125rem;
// }

// .p-step-list__icon {
//   margin-right: 1rem;
// }
// @media (max-width: 519px) {
//   .p-step-list__icon {
//     margin-right: 0;
//     margin-bottom: 1rem;
//     text-align: center;
//   }
// }
// .p-step-list__icon img {
//   height: 7.125rem;
//   width: auto;
// }

// .p-step-list__has-link {
//   background-color: #fff;
// }

// .p-step-list__text-small {
//   line-height: 0.9375rem;
// }

// .p-document-list {
//   border-bottom: 0.125rem solid #ea5404;
//   border-right: 0.125rem solid #ea5404;
//   border-radius: 0.25rem;
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
// }
// @media (max-width: 519px) {
//   .p-document-list {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }
// .p-document-list__item {
//   border-left: 0.125rem solid #ea5404;
//   border-top: 0.125rem solid #ea5404;
//   padding: 1.5rem 0;
// }
// .p-document-list__item:not(:first-child) {
//   border-left: 0.125rem solid #ea5404;
// }
// .p-document-list__item-text {
//   text-align: center;
//   margin-top: 0.75rem;
//   font-size: 1rem;
//   line-height: 1;
//   font-weight: 700;
// }
// @media (max-width: 519px) {
//   .p-document-list__item-text {
//     font-size: 0.875rem;
//   }
// }
// .p-document-list__item-img {
//   width: 9.6875rem;
//   margin: 0 auto;
// }
// @media (max-width: 519px) {
//   .p-document-list__item-img {
//     width: 8.125rem;
//   }
// }

// .p-keiyakusya-kaigai-cta {
//   max-width: 22.5rem;
//   margin-bottom: 2rem;
// }
// @media (max-width: 519px) {
//   .p-keiyakusya-kaigai-cta.-sticky {
//     position: sticky;
//     bottom: 0.625rem;
//     left: 0;
//   }
// }

// .p-keiyakusya-kaigai-cta a {
//   width: 100%;
//   display: block;
//   transition: 0.3s;
//   transition: transform 0.2s ease;
// }
// .p-keiyakusya-kaigai-cta a:focus {
//   transform: translateY(0.1875rem);
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-keiyakusya-kaigai-cta a:hover {
//     transform: translateY(0.1875rem);
//   }
// }

// .p-kaigai-pdf {
//   max-width: 50.5rem;
// }

// .p-kaigai-banner {
//   margin-top: 7.25rem;
//   max-width: 68.75rem;
// }

// .p-keiyakusya-kaigai-type {
//   max-width: 50.75rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-keiyakusya-kaigai-type__items {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   -moz-column-gap: 1.720625rem;
//        column-gap: 1.720625rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-kaigai-type__items {
//     -moz-column-gap: 0.75rem;
//          column-gap: 0.75rem;
//   }
// }

// .p-keiyakusya-kaigai-type__item {
//   border-radius: 0.9375rem;
//   border: 0.0625rem solid #c9c9c9;
//   min-height: 16.4375rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-kaigai-type__item {
//     min-height: 16.3125rem;
//   }
// }

// .p-keiyakusya-kaigai-type__title {
//   font-size: 1.1875rem;
//   letter-spacing: 0.035625rem;
//   margin-top: 0.4375rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-kaigai-type__title {
//     font-size: 0.875rem;
//     letter-spacing: 0.02625rem;
//   }
// }
// .p-keiyakusya-kaigai-type__title span {
//   font-size: 0.625rem;
//   line-height: 1.4;
// }

// .p-keiyakusya-kaigai-type__img {
//   width: 100%;
//   text-align: center;
//   margin-top: 0.5rem;
// }
// .p-keiyakusya-kaigai-type__img picture,
// .p-keiyakusya-kaigai-type__img img {
//   max-width: 12.5rem;
//   margin-right: auto;
//   margin-left: auto;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-kaigai-type__img picture,
//   .p-keiyakusya-kaigai-type__img img {
//     max-width: 100%;
//   }
// }

// .p-keiyakusya-kaigai-type__text {
//   font-size: 0.875rem;
//   line-height: 1.5;
//   font-weight: 700;
//   text-align: center;
//   letter-spacing: 0.02625rem;
// }

// .p-keiyakusya-kaigai-type__link {
//   margin-top: 0.9375rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-kaigai-type__link {
//     margin-top: 1.6875rem;
//   }
// }
// .p-keiyakusya-kaigai-type__link a {
//   text-decoration: underline;
//   position: relative;
//   font-size: 0.875rem;
//   line-height: 1.5;
// }
// .p-keiyakusya-kaigai-type__link a::after {
//   content: "";
//   background-image: url(/common_2024/img/icon/icon_external.webp);
//   background-size: 0.875rem;
//   position: absolute;
//   bottom: 0.208125rem;
//   right: -1.25rem;
//   width: 0.875rem;
//   height: 0.729375rem;
// }

// .p-keiyakusya-kaigai-estimate__title {
//   max-width: 20.713125rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-keiyakusya-kaigai-estimate {
//   max-width: 50.75rem;
//   background-color: #f5f5f5;
//   padding-bottom: 3.50875rem;
// }

// .p-keiyakusya-kaigai-estimate__container {
//   max-width: 16.5rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-keiyakusya-kaigai-estimate-form__wrap {
//   display: flex;
//   align-items: center;
// }

// .p-keiyakusya-kaigai-estimate-form__label {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1;
// }

// .p-keiyakusya-kaigai-estimate-form__select {
//   margin-left: 0.5rem;
// }

// .p-keiyakusya-kaigai-estimate-form-select {
//   max-width: 14.25rem;
//   width: 100%;
// }

// .p-keiyakusya-kaigai-estimate-form-select select {
//   padding: 0.71875rem 3.125rem 0.71875rem 0.9375rem;
//   width: 100%;
//   font-size: 0.875rem;
//   color: #bdbdbd;
//   line-height: 1;
//   border-radius: 0.3125rem;
//   background-color: #fff;
//   border: 1px solid #bdbdbd;
//   background-image: url(/pc/kaigai/assets/img/kaigai_icon_select.webp);
//   background-repeat: no-repeat;
//   background-size: 0.5rem;
//   background-position: right 0.9375rem top 50%;
//   -webkit-appearance: none;
//      -moz-appearance: none;
//           appearance: none;
//   cursor: pointer;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-kaigai-estimate-form-select select {
//     padding: 0.5rem;
//   }
// }

// .p-keiyakusya-kaigai-estimate-form-select select::-ms-expand {
//   display: none;
// }

// .p-keiyakusya-kaigai-estimate-form-select select:focus {
//   border: 1px solid #ccc;
//   box-shadow: none;
//   outline: none;
// }

// .p-keiyakusya-kaigai-estimate-form-select select::-ms-expand {
//   display: none;
// }

// .p-keiyakusya-kaigai-estimate-form-select select:focus {
//   border: 1px solid #ccc;
//   box-shadow: none;
//   outline: none;
// }

// .p-keiyakusya-kaigai-estimate__text {
//   position: relative;
//   max-width: 22.4375rem;
//   margin-top: 1.75rem;
// }
// .p-keiyakusya-kaigai-estimate__text::before {
//   content: "";
//   background-color: #ea5404;
//   width: 2.5rem;
//   height: 0.03125rem;
//   position: absolute;
//   top: 60%;
//   left: 0;
//   transform: translateY(-50%);
// }
// .p-keiyakusya-kaigai-estimate__text::after {
//   content: "";
//   background-color: #ea5404;
//   width: 2.5rem;
//   height: 0.03125rem;
//   position: absolute;
//   top: 60%;
//   right: 0;
//   transform: translateY(-50%);
// }

// .p-keiyakusya-kaigai-estimate__img {
//   max-width: 22.4375rem;
// }

// .p-keiyakusya-table {
//   max-width: 50.75rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table {
//     overflow-x: scroll;
//   }
// }

// .p-keiyakusya-table__wrapper {
//   border-radius: 0.625rem;
//   border: 0.0625rem solid #BDBDBD;
//   padding: 1.25rem;
//   min-width: 29.8125rem;
// }

// .p-keiyakusya-table table td:first-child {
//   min-width: 13.4821875rem;
//   padding: 0;
//   margin-right: -0.875rem;
//   font-size: 0.75rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table table td:first-child {
//     min-width: 8.4375rem;
//     margin-right: -0.5rem;
//   }
// }

// .p-keiyakusya-table table tr {
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   -moz-column-gap: 0.875rem;
//        column-gap: 0.875rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table table tr {
//     -moz-column-gap: 0.5rem;
//          column-gap: 0.5rem;
//   }
// }

// .p-keiyakusya-table table tbody tr:not(:last-child) {
//   border-bottom: 0.125rem dotted #C9C9C9;
//   position: relative;
// }
// .p-keiyakusya-table table tbody tr:not(:last-child)::before {
//   content: "";
//   position: absolute;
//   bottom: -0.125rem;
//   width: 13.4821875rem;
//   height: 0.125rem;
//   background: #fff;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table table tbody tr:not(:last-child)::before {
//     width: 8.4375rem;
//   }
// }

// .p-keiyakusya-table table tbody td {
//   font-size: 0.875rem;
//   font-weight: bold;
//   text-align: right;
//   padding: 0.9375rem 1.75rem 1.4375rem 0;
//   position: relative;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: flex-end;
//   justify-content: flex-end;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table table tbody td {
//     display: block;
//     padding: 0.125rem 0.5rem;
//     min-width: 6.25rem;
//     height: 3.5rem;
//     white-space: nowrap;
//   }
// }
// .p-keiyakusya-table table tbody td strong {
//   font-size: 1.125rem;
// }
// .p-keiyakusya-table table tbody td span {
//   margin-top: 0.1875rem;
//   display: flex;
//   align-items: baseline;
//   justify-content: right;
// }
// .p-keiyakusya-table table tbody td span strong {
//   margin-left: 0.375rem;
// }
// .p-keiyakusya-table table tbody td .important {
//   position: absolute;
//   top: 1rem;
//   right: 0.5rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table table tbody td .important {
//     top: -0.625rem;
//     right: 0.125rem;
//   }
// }

// /* --リスト名ブロック */
// .p-keiyakusya-table table tbody td:first-child {
//   border-radius: 5px 0px 0px 5px;
//   background-color: #F5F5F5;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   border-bottom: 0.0625rem solid #fff;
//   padding-right: 1.125rem;
//   padding-left: 0.3125rem;
// }
// .p-keiyakusya-table table tbody td:first-child a {
//   position: relative;
//   line-height: 1.4166666667;
// }
// .p-keiyakusya-table table tbody td:first-child a span {
//   position: absolute;
//   top: 50%;
//   right: -1.25rem;
//   transform: translateY(-50%);
//   max-width: 0.875rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table table tbody td:first-child a span {
//     right: -1.0625rem;
//   }
// }

// /* --2,3列目 */
// .p-keiyakusya-table table td:nth-child(2),
// .p-keiyakusya-table table td:nth-child(3) {
//   border-right: 1px solid #ffffff;
//   background: #a9bce2;
// }

// .p-keiyakusya-table table td:nth-child(2) {
//   background-color: rgba(176, 134, 69, 0.1);
// }

// .p-keiyakusya-table table td:nth-child(3) {
//   background-color: rgba(144, 144, 144, 0.1);
// }

// .p-keiyakusya-table table td:nth-child(4) {
//   background-color: rgba(189, 159, 0, 0.1);
// }

// /*Header テキストブロック*/
// .p-keiyakusya-table table thead td {
//   border-top: none;
//   border-radius: 6px 6px 0 0;
//   height: 1.625rem;
//   text-align: center;
//   line-height: 1.625rem;
//   padding: 0;
// }

// .p-keiyakusya-table table thead td:nth-child(n+2) {
//   color: #fff;
// }

// .p-keiyakusya-table table thead td:nth-child(2) {
//   background-color: #B08645;
// }

// .p-keiyakusya-table table thead td:nth-child(3) {
//   background-color: #909090;
// }

// .p-keiyakusya-table table thead td:nth-child(4) {
//   background-color: #BD9F00;
// }

// .p-keiyakusya-table-type {
//   max-width: 50.75rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table-type {
//     overflow-x: scroll;
//   }
// }

// .p-keiyakusya-table-type__wrapper {
//   border-radius: 0.625rem;
//   border: 0.0625rem solid #BDBDBD;
//   padding: 1.25rem 1.3125rem;
//   min-width: 43.125rem;
// }

// .p-keiyakusya-table-type__table {
//   display: flex;
// }

// .p-keiyakusya-table-type__title {
//   min-width: 17.0625rem;
//   background-color: #F5F5F5;
// }
// .p-keiyakusya-table-type__title .table__space {
//   height: 1.75rem;
//   background-color: #fff;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table-type__title {
//     min-width: 9.75rem;
//   }
// }

// .p-keiyakusya-table-type__wrap {
//   display: flex;
//   gap: 0 2.0625rem;
//   padding: 1.1875rem 2.125rem 1.3125rem 1.75rem;
//   border-bottom: 0.125rem dashed #C9C9C9;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table-type__wrap {
//     padding: 1.1875rem 0.875rem 1.6875rem 1.375rem;
//   }
// }

// @media (max-width: 959px) {
//   .p-keiyakusya-table-type__icon {
//     display: none;
//   }
// }

// .p-keiyakusya-table-type__icon.--first {
//   width: 2.125rem;
//   margin-left: 1rem;
// }

// .p-keiyakusya-table-type__icon.--second {
//   width: 3.75rem;
// }

// .p-keiyakusya-table-type__text {
//   max-width: 7.74rem;
// }

// .p-keiyakusya-table-type__link {
//   position: relative;
//   font-size: 1.25rem;
//   font-weight: 700;
// }
// .p-keiyakusya-table-type__link span {
//   max-width: 0.875rem;
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   right: -1.25rem;
// }

// .p-keiyakusya-table-type__payment {
//   min-width: 5.5rem;
// }
// .p-keiyakusya-table-type__payment h4 {
//   background-color: #fff;
//   height: 1.75rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .p-keiyakusya-table-type__payment p {
//   background-color: #F5F5F5;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 3.46875rem;
//   border-bottom: 0.125rem dashed #C9C9C9;
//   border-left: 0.125rem dashed #C9C9C9;
// }

// .border__none {
//   border-bottom: none;
// }

// .p-keiyakusya-table-type__course {
//   margin-left: 0.3125rem;
//   min-width: 8.125rem;
// }
// .p-keiyakusya-table-type__course h4 {
//   border-radius: 0.3125rem 0.3125rem 0px 0px;
//   height: 1.75rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .p-keiyakusya-table-type__course p {
//   display: flex;
//   align-items: center;
//   justify-content: right;
//   padding-right: 1.43125rem;
//   height: 3.46875rem;
//   border-bottom: 0.125rem dashed #C9C9C9;
// }
// .p-keiyakusya-table-type__course strong {
//   font-size: 14px;
// }

// .p-keiyakusya-table-type__course.--bronze h4 {
//   background-color: #B08645;
// }
// .p-keiyakusya-table-type__course.--bronze p {
//   background: rgba(176, 134, 69, 0.1);
// }

// .p-keiyakusya-table-type__course.--silver h4 {
//   background-color: #909090;
// }
// .p-keiyakusya-table-type__course.--silver p {
//   background: rgba(144, 144, 144, 0.1);
// }

// .p-keiyakusya-table-type__course.--gold h4 {
//   background-color: #BD9F00;
// }
// .p-keiyakusya-table-type__course.--gold p {
//   background: rgba(189, 159, 0, 0.1);
// }

// .p-keiyakusya-table__plus {
//   max-width: 7.8125rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-table__plus {
//     max-width: 4.5rem;
//   }
// }

// .p-keiyakusya-table-type__wrap-2 {
//   height: 3.46875rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .p-keiyakusya-point__heading p {
//   max-width: 18.75rem;
//   display: flex;
//   align-items: center;
//   gap: 0 1.25rem;
//   margin-left: auto;
//   margin-right: auto;
//   white-space: nowrap;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-point__heading p {
//     max-width: initial;
//     gap: 0 0.6875rem;
//     letter-spacing: 0;
//   }
// }
// .p-keiyakusya-point__heading p span {
//   display: block;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-point__heading p span {
//     padding-left: 1.5625rem;
//   }
// }
// .p-keiyakusya-point__heading p span strong {
//   font-size: 2.1875rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-point__heading p span strong {
//     font-size: 1.875rem;
//   }
// }
// .p-keiyakusya-point__heading p small {
//   font-size: 0.875rem;
//   line-height: 1.6;
// }

// .p-keiyakusya-point__img {
//   max-width: 20rem;
// }

// /* PC時のスタイル */
// .p-keiyakusya-point__wrapper {
//   padding-bottom: 1rem;
//   border-radius: 0px 0px 5px 5px;
//   margin-left: 1.5rem;
//   margin-right: 1.5rem;
//   /* スライドのサイズ調整 */
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-point__wrapper {
//     margin-left: initial;
//     margin-right: initial;
//   }
// }
// .p-keiyakusya-point__wrapper .splide01:not(.is-overflow) .splide__pagination {
//   display: flex;
//   margin-top: 1.3125rem;
// }
// .p-keiyakusya-point__wrapper .splide01.is-initialized:not(.is-active) .splide__list {
//   display: grid;
//   gap: 16px;
//   grid-template-columns: repeat(4, 1fr);
// }
// .p-keiyakusya-point__wrapper .splide__slide img {
//   height: auto;
//   width: 100%;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-point__wrapper .splide__list {
//     gap: 0 0.5rem;
//   }
// }

// .p-keiyakusya-point__contents {
//   max-width: 42.625rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-point__contents {
//     padding: 0 0.375rem;
//   }
//   .p-keiyakusya-point__contents .sm\:u-mt-11 {
//     margin-top: 2.6875rem;
//   }
// }

// .p-keiyakusya__tryangle {
//   max-width: 2.25rem;
//   margin-top: 1.875rem;
// }

// .p-keiyakusya-point__content {
//   max-width: 42.625rem;
//   background-color: #fff;
//   border: 1px solid #c9c9c9;
//   border-radius: 0.5rem;
//   padding: 0.75rem 3.125rem 2rem 3.125rem;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-point__content {
//     padding: 0.875rem 0.5625rem 1.375rem 0.5625rem;
//   }
// }

// .p-keiyakusya-point__item {
//   max-width: 10rem;
//   width: 100%;
// }
// @media (max-width: 959px) {
//   .p-keiyakusya-point__item {
//     max-width: 9.4375rem;
//   }
// }

// .p-keiyakusya-point__items {
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   gap: 2.3125rem 0;
// }

// .p-keiyakusya-point__item .c-text-accent {
//   white-space: nowrap;
//   height: 3.625rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .p-keiyakusya-point__item .c-btn {
//   font-size: 0.9375rem;
//   width: 100%;
//   height: 2.375rem;
//   margin-top: 0.8125rem;
// }

// .p-keiyakusya-point__icon {
//   max-width: 2.5rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .p-keiyakusya-point__icon img {
//   height: 2.125rem;
//   -o-object-fit: contain;
//      object-fit: contain;
// }

// .p-keiyakusya-point-item__text {
//   min-height: 7.5rem;
//   margin-top: 0.5rem;
// }

// .p-keiyakusya-point-item__text.sm-initial {
//   min-height: initial;
// }

// @media (min-width: 960px) {
//   .p-keiyakusya-point-boxes {
//     display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//     gap: 1.25rem 2.25rem;
//   }
// }
// .p-keiyakusya-point-box {
//   max-width: 21.25rem;
//   width: 100%;
//   border-radius: 0.625rem;
//   border: 0.0625rem solid #cfcfcf;
//   background: #fff;
//   min-height: 14.0065rem;
// }

// .p-keiyakusya-point-box__heading {
//   border-radius: 0.625rem 0.625rem 0px 0px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 0 1.375rem;
//   height: 5rem;
// }

// .p-keiyakusya-point-box__icon {
//   max-width: 2.9375rem;
// }

// .p-keiyakusya-point-box__wrap {
//   padding: 0.6875rem 1.25rem 1rem 1.25rem;
// }

// .splide02 {
//   visibility: initial;
// }
// .splide02:not(.is-overflow) .splide__pagination {
//   display: flex;
//   margin-top: 1.3125rem;
// }

// .splide02.is-initialized:not(.is-active) .splide__list {
//   display: flex;
// }

// .p-keiyakusya-point__banner {
//   max-width: 44.75rem;
// }

// .p-kaigai-payment__icons {
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   -moz-column-gap: 2.1875rem;
//        column-gap: 2.1875rem;
//   justify-items: center;
//   align-items: center;
//   max-width: 36.4375rem;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 2.375rem;
// }
// @media (max-width: 959px) {
//   .p-kaigai-payment__icons {
//     grid-template-columns: repeat(3, 1fr);
//     -moz-column-gap: 2.5rem;
//          column-gap: 2.5rem;
//     grid-template-rows: repeat(2, 1fr);
//     row-gap: 1.5rem;
//     max-width: 16.5rem;
//     margin-top: 1.4375rem;
//   }
// }

// .p-kaigai-payment__table {
//   max-width: 34.375rem;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 1.5625rem;
// }
// .p-kaigai-payment__table table {
//   max-width: 30.875rem;
//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
// }
// .p-kaigai-payment__table table tr {
//   display: flex;
// }
// @media (max-width: 959px) {
//   .p-kaigai-payment__table table tr {
//     justify-content: center;
//   }
// }
// .p-kaigai-payment__table table tr:last-child td:last-child {
//   border-bottom: 0.125rem dashed #C9C9C9;
// }
// .p-kaigai-payment__table table td {
//   width: 15.375rem;
//   height: 4.0625rem;
//   display: flex;
//   align-items: center;
//   border-top: 0.125rem dashed #C9C9C9;
// }
// @media (max-width: 959px) {
//   .p-kaigai-payment__table table td {
//     height: 3.8125rem;
//   }
// }
// .p-kaigai-payment__table table td:first-child {
//   gap: 0 0.3125rem;
//   border-top: none;
//   border-bottom: 0.125rem dashed #fff;
//   background-color: #FFF6D1;
//   padding-left: 3.125rem;
// }
// @media (max-width: 959px) {
//   .p-kaigai-payment__table table td:first-child {
//     width: 10.5625rem;
//     padding-left: 0.9375rem;
//   }
// }
// .p-kaigai-payment__table table td:last-child {
//   justify-content: center;
// }
// @media (max-width: 959px) {
//   .p-kaigai-payment__table table td:last-child {
//     width: 7.75rem;
//   }
// }
// @media (max-width: 959px) {
//   .p-kaigai-payment__table table td:last-child svg {
//     width: 1.875rem;
//   }
// }
// .p-kaigai-payment__table table td span {
//   max-width: 2.5rem;
// }
// .p-kaigai-payment__table table td p {
//   min-width: 6.8125rem;
// }

// .p-kaigai__user {
//   margin-top: 7.3125rem;
//   max-width: 50.75rem;
//   border-radius: 1.25rem;
//   border: 0.1875rem solid #EA5404;
// }
// @media (max-width: 959px) {
//   .p-kaigai__user {
//     margin-top: 5.625rem;
//     padding: 0 1.25rem;
//   }
// }

// .p-kaigai-user__img {
//   max-width: 12rem;
// }

// .p-kokunai-feature__cards {
//   max-width: 62.5rem;
//   margin-left: auto;
//   margin-right: auto;
//   display: flex;
//   justify-content: center;
//   gap: 0 1.25rem;
// }
// @media (max-width: 959px) {
//   .p-kokunai-feature__cards {
//     flex-wrap: wrap;
//     gap: 0.5rem 0;
//   }
// }

// .p-kokunai-feature__card {
//   max-width: 20rem;
//   width: 100%;
//   min-height: 20.625rem;
//   border-radius: 0.3125rem;
//   border: 0.0625rem solid #BDBDBD;
// }
// @media (max-width: 959px) {
//   .p-kokunai-feature__card {
//     max-width: 100%;
//     min-height: 18.75rem;
//   }
// }

// .p-kokunai-feature__heading {
//   font-size: 1.375rem;
//   font-weight: 700;
//   line-height: 1.0909090909;
//   color: #ea5404;
//   border-radius: 0.3125rem;
//   background: #F3F3F3;
//   margin: 0.5rem 0.625rem 0 0.625rem;
//   text-align: center;
//   height: 5rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .p-kokunai-feature__img {
//   max-width: 6.25rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-kokunai-service {
//   max-width: 68.75rem;
// }

// .p-kokunai-service__items {
//   display: flex;
//   gap: 1.25rem 2.5rem;
//   flex-wrap: wrap;
// }
// @media (max-width: 959px) {
//   .p-kokunai-service__items {
//     gap: 1rem 0.6875rem;
//     justify-content: space-between;
//   }
// }

// .p-kokunai-service__item {
//   border-radius: 0.5rem;
//   border: 0.0625rem solid #DEDEDE;
//   padding: 1.6875rem 1rem 2.3125rem 1rem;
//   max-width: 21.25rem;
//   width: 100%;
// }
// @media (max-width: 959px) {
//   .p-kokunai-service__item {
//     max-width: 10.3125rem;
//     border-radius: 0.625rem;
//     padding: 1.6875rem 0 2.3125rem 0;
//     min-height: 11.125rem;
//     display: flex;
//     justify-content: center;
//     align-items: flex-end;
//     flex-wrap: wrap;
//   }
// }
// .p-kokunai-service__item .c-btn {
//   width: 19.1875rem;
//   height: 2.375rem;
// }
// @media (max-width: 959px) {
//   .p-kokunai-service__item .c-btn {
//     width: 9.4375rem;
//   }
// }

// .p-kokunai-payment__icons {
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
//   -moz-column-gap: 2.1875rem;
//        column-gap: 2.1875rem;
//   justify-items: center;
//   align-items: center;
//   max-width: 30.875rem;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 2.375rem;
// }
// @media (max-width: 959px) {
//   .p-kokunai-payment__icons {
//     grid-template-columns: repeat(3, 1fr);
//     -moz-column-gap: 2.5rem;
//          column-gap: 2.5rem;
//     grid-template-rows: repeat(2, 1fr);
//     row-gap: 1.5rem;
//     max-width: 16.5rem;
//     margin-top: 1.4375rem;
//   }
// }

// .p-kokunai-payment__table {
//   max-width: 34.375rem;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 1.5625rem;
// }
// .p-kokunai-payment__table table {
//   max-width: 34.375rem;
//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
// }
// .p-kokunai-payment__table table tr {
//   display: flex;
// }
// @media (max-width: 959px) {
//   .p-kokunai-payment__table table tr {
//     justify-content: center;
//   }
// }
// .p-kokunai-payment__table table tr:last-child td:last-child {
//   border-bottom: 0.125rem dashed #C9C9C9;
// }
// .p-kokunai-payment__table table td {
//   width: 15.375rem;
//   height: 4.0625rem;
//   display: flex;
//   align-items: center;
//   border-top: 0.125rem dashed #C9C9C9;
// }
// @media (max-width: 959px) {
//   .p-kokunai-payment__table table td {
//     height: 3.8125rem;
//   }
// }
// .p-kokunai-payment__table table td:first-child {
//   gap: 0 0.3125rem;
//   border-top: none;
//   border-bottom: 0.125rem dashed #fff;
//   background-color: #FFF6D1;
//   padding-left: 3.125rem;
// }
// @media (max-width: 959px) {
//   .p-kokunai-payment__table table td:first-child {
//     width: 10.5625rem;
//     padding-left: 0.9375rem;
//   }
// }
// .p-kokunai-payment__table table td:last-child {
//   justify-content: center;
// }
// @media (max-width: 959px) {
//   .p-kokunai-payment__table table td:last-child {
//     width: 7.75rem;
//   }
// }
// @media (max-width: 959px) {
//   .p-kokunai-payment__table table td:last-child svg {
//     width: 1.875rem;
//   }
// }
// .p-kokunai-payment__table table td span {
//   max-width: 2.5rem;
// }
// .p-kokunai-payment__table table td p {
//   min-width: 6.8125rem;
// }

// .p-news-header {
//   margin-bottom: 2rem;
// }
// .p-news-header .c-post-heading {
//   margin-bottom: 1.5rem;
// }
// .p-news-header .c-post-meta__date {
//   font-weight: 700;
// }
// .p-news-header .c-post-meta__category {
//   color: #000;
//   background: #e5e5e5;
//   font-weight: 700;
// }
// .p-news-header .c-post-meta__category.-orange {
//   background: #f4ddc0;
// }
// .p-news-header .c-post-meta__category.-yellow {
//   background: #fff682;
// }

// .p-golf-feature__cards {
//   max-width: 68.75rem;
//   margin-left: auto;
//   margin-right: auto;
//   display: flex;
//   justify-content: center;
//   gap: 0 1rem;
// }
// @media (max-width: 959px) {
//   .p-golf-feature__cards {
//     flex-wrap: wrap;
//     gap: 0.5rem 0;
//   }
// }

// .p-golf-feature__card {
//   max-width: 16.4375rem;
//   width: 100%;
//   min-height: 17.625rem;
//   border-radius: 0.3125rem;
//   border: 0.0625rem solid #BDBDBD;
// }
// @media (max-width: 959px) {
//   .p-golf-feature__card {
//     max-width: 100%;
//   }
// }
// .p-golf-feature__card .u-text-sm {
//   letter-spacing: initial;
// }
// .p-golf-feature__card:nth-child(2) {
//   position: relative;
// }
// .p-golf-feature__card:nth-child(2) .p-golf-annotation {
//   position: absolute;
//   bottom: -3.5625rem;
//   left: 50%;
//   max-width: 16.4375rem;
//   transform: translateX(-50%);
//   width: 100%;
//   letter-spacing: 0.0625rem;
// }
// @media (max-width: 959px) {
//   .p-golf-feature__card:nth-child(2) .p-golf-annotation {
//     max-width: 20.8125rem;
//     bottom: -2.1875rem;
//   }
// }
// @media (max-width: 959px) {
//   .p-golf-feature__card:nth-child(3) {
//     margin-top: 2.5rem;
//   }
// }

// .p-golf-feature__heading {
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1.0909090909;
//   color: #ea5404;
//   border-radius: 0.3125rem;
//   background: #F3F3F3;
//   margin: 0.5rem 0.625rem 0 0.625rem;
//   text-align: center;
//   height: 5rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .p-golf-feature__img {
//   max-width: 6.25rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-pet-application {
//   background-color: #fffcd1;
//   padding-top: 0.875rem;
//   padding-bottom: 1.125rem;
// }
// @media (max-width: 519px) {
//   .p-pet-application {
//     padding: 0.875rem 1.25rem 1.125rem;
//     margin-left: -0.625rem;
//     margin-right: -0.625rem;
//   }
// }
// .p-pet-application__head {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .p-pet-application__head-title {
//   font-weight: bold;
//   font-size: 1.25rem;
//   letter-spacing: 0.01em;
//   text-align: center;
//   position: relative;
// }
// @media (max-width: 519px) {
//   .p-pet-application__head-title {
//     font-size: 1rem;
//   }
// }
// .p-pet-application__head-title strong {
//   font-size: 1.5625rem;
// }
// @media (max-width: 519px) {
//   .p-pet-application__head-title strong {
//     font-size: 1.5625rem;
//     letter-spacing: 0.1em;
//   }
// }
// .p-pet-application__head ::after {
//   background-image: url(/pc/pet/assets/img/pet_pad_icon.png);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   width: 2.9375rem;
//   height: 2.9375rem;
//   position: absolute;
//   content: "";
//   top: 50%;
//   right: -3rem;
//   transform: translateY(-50%);
// }
// @media (max-width: 519px) {
//   .p-pet-application__head ::after {
//     top: 68%;
//     right: -2rem;
//     transform: translateY(-50%);
//   }
// }
// .p-pet-application__box {
//   display: flex;
//   justify-content: center;
//   gap: 1.5rem;
// }
// @media (max-width: 519px) {
//   .p-pet-application__box {
//     gap: 0.625rem;
//   }
// }
// .p-pet-application__box-btn--yes {
//   color: #fff;
//   background-color: #ea5404;
//   border: 1px solid #ea5404;
//   box-shadow: 0 0.3125rem 0 #ab3b00;
//   font-weight: bold;
//   font-size: 0.75rem;
//   padding-top: 1rem;
//   padding-bottom: 1rem;
//   position: relative;
//   display: inline-block;
//   width: 22.5rem;
//   border-radius: 0.3125rem;
//   text-align: center;
//   transition: all 0.2s ease;
// }
// @media (max-width: 519px) {
//   .p-pet-application__box-btn--yes {
//     width: 10.3125rem;
//     text-align: left;
//     line-height: 1.5;
//     letter-spacing: 0.03em;
//     padding: 0.375rem 0.625rem;
//   }
// }
// .p-pet-application__box-btn--yes strong {
//   font-size: 1.25rem;
//   letter-spacing: 0.03em;
// }
// .p-pet-application__box-btn--yes ::after {
//   position: absolute;
//   content: "";
//   background-image: url(/pc/pet/assets/img/pet_arrow_bold_white.svg);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   top: 50%;
//   right: 1.25rem;
//   transform: translateY(-50%);
//   width: 1rem;
//   height: 0.6875rem;
// }
// @media (max-width: 519px) {
//   .p-pet-application__box-btn--yes ::after {
//     right: 0.5rem;
//     width: 1.4375rem;
//     height: 1.0625rem;
//   }
// }
// .p-pet-application__box-btn--yes:focus {
//   box-shadow: none;
//   transform: translateY(0.25rem);
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-pet-application__box-btn--yes:hover {
//     box-shadow: none;
//     transform: translateY(0.25rem);
//   }
// }
// .p-pet-application__box-btn--no {
//   color: #000;
//   background-color: #fff682;
//   border: 3px solid #ffef28;
//   box-shadow: 0 0.3125rem 0 #d9dd00;
//   font-weight: bold;
//   font-size: 0.75rem;
//   padding-top: 0.875rem;
//   padding-bottom: 0.8125rem;
//   position: relative;
//   display: inline-block;
//   width: 22.125rem;
//   border-radius: 0.3125rem;
//   text-align: center;
//   transition: all 0.2s ease;
// }
// @media (max-width: 519px) {
//   .p-pet-application__box-btn--no {
//     width: 10.3125rem;
//     text-align: left;
//     line-height: 1.5;
//     letter-spacing: 0.03em;
//     padding: 0.25rem 0.625rem;
//   }
// }
// .p-pet-application__box-btn--no strong {
//   font-size: 1.25rem;
//   letter-spacing: 0.03em;
// }
// .p-pet-application__box-btn--no ::after {
//   position: absolute;
//   content: "";
//   background-image: url(/pc/pet/assets/img/pet_arrow_bold_black.svg);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   top: 50%;
//   right: 1.25rem;
//   transform: translateY(-50%);
//   width: 1rem;
//   height: 0.6875rem;
// }
// @media (max-width: 519px) {
//   .p-pet-application__box-btn--no ::after {
//     right: 0.5rem;
//     width: 1.4375rem;
//     height: 1.0625rem;
//   }
// }
// .p-pet-application__box-btn--no:focus {
//   box-shadow: none;
//   transform: translateY(0.25rem);
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-pet-application__box-btn--no:hover {
//     box-shadow: none;
//     transform: translateY(0.25rem);
//   }
// }
// .p-pet-application__text {
//   font-weight: bold;
//   font-size: 1.25rem;
//   text-align: center;
// }
// @media (max-width: 519px) {
//   .p-pet-application__text {
//     font-size: 1rem;
//   }
// }

// .p-pet-balloon-girl {
//   display: flex;
//   max-width: 50rem;
//   margin-left: auto;
//   margin-right: auto;
// }
// @media (max-width: 519px) {
//   .p-pet-balloon-girl {
//     gap: 10px;
//   }
// }
// .p-pet-balloon-girl__img {
//   aspect-ratio: 122/112;
//   overflow: hidden;
//   max-width: 7.625rem;
//   width: 30%;
// }
// @media (max-width: 519px) {
//   .p-pet-balloon-girl__img {
//     width: 45%;
//   }
// }
// .p-pet-balloon-girl__img img {
//   width: 100%;
//   height: 100%;
//   -o-object-fit: contain;
//      object-fit: contain;
// }
// .p-pet-balloon-girl_right {
//   width: 70%;
// }
// @media (max-width: 519px) {
//   .p-pet-balloon-girl_right {
//     width: 55%;
//   }
// }
// .p-pet-balloon-girl__balloon {
//   padding: 0.75rem 0.625rem;
//   position: relative;
//   background: #fff;
//   border: 0.125rem solid #EA5404;
//   border-radius: 5px;
//   line-height: 150%;
// }
// @media (max-width: 519px) {
//   .p-pet-balloon-girl__balloon {
//     padding: 0.625rem 0.25rem 0.625rem 0.625rem;
//   }
// }
// .p-pet-balloon-girl__balloon::before {
//   right: 100%;
//   top: 50%;
//   border: solid transparent;
//   content: "";
//   height: 0;
//   width: 0;
//   position: absolute;
//   pointer-events: none;
//   border-color: rgba(234, 84, 4, 0);
//   border-right-color: #EA5404;
//   border-width: 0.5625rem;
//   margin-top: -0.5625rem;
// }
// .p-pet-balloon-girl__balloon::after {
//   right: 100%;
//   top: 50%;
//   border: solid transparent;
//   content: "";
//   height: 0;
//   width: 0;
//   position: absolute;
//   pointer-events: none;
//   border-color: rgba(255, 255, 255, 0);
//   border-right-color: #fff;
//   border-width: 0.375rem;
//   margin-top: -0.375rem;
// }
// .p-pet-balloon-girl__balloon p {
//   font-size: 1.125rem;
//   font-weight: 700;
//   color: #000;
// }
// @media (max-width: 519px) {
//   .p-pet-balloon-girl__balloon p {
//     font-size: 0.875rem;
//   }
// }
// .p-pet-balloon-girl__balloon p span {
//   color: #EA5404;
// }
// .p-pet-balloon-girl__balloon p i {
//   font-size: 2rem;
// }
// @media (max-width: 519px) {
//   .p-pet-balloon-girl__balloon p i {
//     font-size: 1.1875rem;
//   }
// }
// .p-pet-balloon-girl__us-stamp {
//   margin-top: 0.125rem;
//   color: #5C5C5C;
//   font-size: 0.625rem;
//   font-weight: 700;
//   line-height: 150%; /* 15px */
//   letter-spacing: 1px;
// }
// .p-pet-balloon-girl__text {
//   margin-top: 0.875rem;
//   color: #5C5C5C;
//   font-size: 0.625rem;
//   font-weight: 700;
//   line-height: 150%; /* 15px */
//   letter-spacing: 1px;
//   padding-left: 1em;
//   text-indent: -1em;
// }

// .p-pet-btn {
//   display: inline-block;
//   padding: 1.25rem 1.25rem 1.25rem 0.625rem;
//   border-radius: 10px;
//   width: 24.3125rem;
//   transition: all 0.2s ease;
// }
// .p-pet-btn:focus {
//   box-shadow: none;
//   transform: translateY(0.25rem);
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-pet-btn:hover {
//     box-shadow: none;
//     transform: translateY(0.25rem);
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-btn {
//     width: auto;
//     padding: 0 1.875rem 0.625rem;
//   }
// }
// .p-pet-btn--dog {
//   background-color: #abecf1;
//   box-shadow: 0 0.25rem 0 #60898c;
// }
// .p-pet-btn--cat {
//   background-color: #fdf060;
//   box-shadow: 0 0.25rem 0 #aba120;
// }
// .p-pet-btn__container {
//   display: flex;
//   gap: 2%;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .p-pet-btn__container {
//     flex-direction: column;
//   }
// }
// .p-pet-btn__container-img {
//   aspect-ratio: 115/84;
//   -o-object-fit: cover;
//      object-fit: cover;
//   width: 7.1875rem;
//   height: 5.25rem;
// }
// @media (max-width: 519px) {
//   .p-pet-btn__container-img {
//     aspect-ratio: 88/66;
//     width: 5.5rem;
//     height: 4.125rem;
//   }
// }
// .p-pet-btn__container-img img {
//   width: 100%;
//   height: auto;
// }
// .p-pet-btn__container-text {
//   font-weight: bold;
//   font-size: 1.125rem;
//   letter-spacing: 0.01em;
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .p-pet-btn__container-text {
//     font-size: 0.875rem;
//     line-height: 1.4285714286;
//     display: flex;
//     flex-direction: column;
//     margin-top: 0.875rem;
//   }
// }
// .p-pet-btn__container-text::after {
//   background-image: url(/pc/pet/assets/img/pet_arrow_bold_primary.svg);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   width: 1.125rem;
//   height: 0.9375rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   content: "";
//   margin-left: 1.1875rem;
// }
// @media (max-width: 519px) {
//   .p-pet-btn__container-text::after {
//     margin-left: 0;
//     margin-top: 0.75rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-btn--single {
//     width: 21.25rem;
//     padding: 0.875rem 1.375rem 0.8125rem 1.0625rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-btn--single .p-pet-btn__container {
//     flex-direction: row;
//     gap: 0 1.35rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-btn--single .p-pet-btn__container-text {
//     flex-direction: row;
//     margin-top: initial;
//   }
//   .p-pet-btn--single .p-pet-btn__container-text::after {
//     margin-top: initial;
//     margin-left: 1.6875rem;
//   }
// }

// .p-pet-choice__container {
//   margin-inline: auto;
//   max-width: 50.75rem;
//   padding: 1.25rem 5.375rem;
//   border: 1px solid #bdbdbd;
//   border-radius: 0.3125rem;
// }
// @media (max-width: 519px) {
//   .p-pet-choice__container {
//     padding: 0.625rem 1.125rem 4.625rem;
//   }
// }
// .p-pet-choice__content-heading {
//   color: #ea5404;
//   font-size: 1.5rem;
//   font-style: normal;
//   font-weight: 700;
//   letter-spacing: -0.0266666667em;
//   line-height: 1.625;
//   text-align: center;
// }
// .p-pet-choice__content-heading em {
//   font-size: 2.25rem;
//   font-style: normal;
//   letter-spacing: -0.04em;
// }
// .p-pet-choice__wrapper {
//   margin-top: 0.625rem;
//   display: flex;
//   align-items: center;
//   gap: 2rem;
// }
// @media (max-width: 519px) {
//   .p-pet-choice__wrapper {
//     flex-direction: column;
//     gap: 1rem;
//   }
// }
// .p-pet-choice__image {
//   flex-shrink: 0;
//   width: 26.1755485893%;
// }
// @media (max-width: 519px) {
//   .p-pet-choice__image {
//     width: 55.5371900826%;
//   }
// }
// .p-pet-choice__content {
//   flex: 1;
// }
// .p-pet-choice__description {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.6;
//   letter-spacing: 0.03em;
// }
// .p-pet-choice__description + .p-pet-choice__description {
//   margin-top: 0.5rem;
// }
// .p-pet-choice__btn {
//   margin-top: 1rem;
//   padding-left: 32%;
// }
// @media (max-width: 519px) {
//   .p-pet-choice__btn {
//     padding-left: 0;
//     text-align: center;
//   }
// }
// .p-pet-choice__btn .c-btn.-md {
//   width: 100%;
//   max-width: 21.25rem;
// }

// .p-pet-column {
//   background-color: #f5f5f5;
//   border-radius: 0.625rem;
// }
// @media (max-width: 519px) {
//   .p-pet-column {
//     margin-left: -0.625rem;
//     margin-right: -0.625rem;
//   }
// }
// .p-pet-column__head {
//   text-align: center;
// }
// .p-pet-column__head-sub {
//   color: #ea5404;
//   font-size: 1rem;
//   font-weight: bold;
// }
// .p-pet-column__head-title {
//   position: relative;
//   display: inline-block;
//   font-size: 1.5rem;
//   font-weight: bold;
//   letter-spacing: 0.02em;
// }
// .p-pet-column__head-title::before {
//   background-image: url(/pc/pet/assets/img/pet_pad_icon.png);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   width: 1.8125rem;
//   height: 1.8125rem;
//   position: absolute;
//   content: "";
//   top: 3%;
//   left: -1.875rem;
//   transform: rotate(-20deg);
// }
// @media (max-width: 519px) {
//   .p-pet-column__head-title::before {
//     left: -0.9375rem;
//   }
// }
// .p-pet-column__head-title::after {
//   background-image: url(/pc/pet/assets/img/pet_pad_icon.png);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   width: 1.8125rem;
//   height: 1.8125rem;
//   position: absolute;
//   content: "";
//   top: 50%;
//   right: -1.5625rem;
//   transform: translateY(-50%);
// }
// @media (max-width: 519px) {
//   .p-pet-column__head-title::after {
//     top: 58%;
//     right: 2.8125rem;
//   }
// }
// .p-pet-column__head-text {
//   font-size: 1rem;
//   font-weight: bold;
//   line-height: 1.5714285714;
// }
// @media (max-width: 519px) {
//   .p-pet-column__head-text {
//     font-size: 0.875rem;
//     line-height: 1.4285714286;
//   }
// }
// .p-pet-column__body {
//   margin-top: 1.0625rem;
// }
// @media (max-width: 519px) {
//   .p-pet-column__body {
//     margin-top: 1.625rem;
//   }
// }
// .p-pet-column__body .p-pet-column-page {
//   background-color: #cbcbcb;
//   height: 0.125rem;
//   width: 2.1875rem;
//   transition: 0.5s all;
//   margin-top: 1.125rem;
// }
// .p-pet-column__body .p-pet-column-page.is-active {
//   background-color: #ea5404;
//   opacity: 1;
// }
// .p-pet-column__post-card {
//   padding: 1.125rem 0.625rem 0.4375rem;
//   background-color: #fff;
//   display: flex;
//   flex-direction: column;
//   min-height: 18.25rem;
// }
// @media (max-width: 519px) {
//   .p-pet-column__post-card {
//     padding: 0.625rem;
//     min-height: 17.25rem;
//   }
// }
// .p-pet-column__post-card-img {
//   aspect-ratio: 280/157;
// }
// @media (max-width: 519px) {
//   .p-pet-column__post-card-img {
//     aspect-ratio: 299/149;
//   }
// }
// .p-pet-column__post-card-img img {
//   width: 100%;
//   height: auto;
// }
// .p-pet-column__post-card-title {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.5714285714;
//   letter-spacing: 0.03em;
//   margin-top: 0.625rem;
// }
// @media (max-width: 519px) {
//   .p-pet-column__post-card-title {
//     margin-top: 1.375rem;
//     letter-spacing: 0.02em;
//     color: #4b4b4b;
//   }
// }
// .p-pet-column__post-card-readmore {
//   margin-top: auto;
//   text-align: right;
// }
// .p-pet-column__post-card-readmore a {
//   color: #ea5404;
//   font-size: 0.875rem;
//   font-weight: 700;
//   width: -moz-fit-content;
//   width: fit-content;
//   background: url(/common_2024/img/arrow/arrow_lg_primary.svg) right center/0.4375rem no-repeat;
//   padding-right: 1rem;
//   transition: opacity 0.2s;
// }
// .p-pet-column__btn {
//   margin-top: 2rem;
// }
// .p-pet-column .splide:not(.is-overflow) .splide__pagination {
//   display: flex;
// }
// .p-pet-column__medical-text {
//   margin-left: 1.25rem;
// }
// @media (max-width: 519px) {
//   .p-pet-column__medical-text {
//     margin-left: 0;
//   }
// }

// .p-pet-compensation-cont__balloon {
//   margin-top: 3.4375rem;
// }
// @media (max-width: 519px) {
//   .p-pet-compensation-cont__balloon {
//     margin-top: 2.0625rem;
//   }
// }

// .p-pet-customer__inner {
//   padding: 0 0.9375rem;
//   margin-right: auto;
//   margin-left: auto;
//   max-width: 50.75rem;
//   width: 100%;
//   height: inherit;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__inner {
//     padding: 0 0.3125rem;
//   }
// }
// .p-pet-customer__head {
//   text-align: center;
//   background-color: #dcdcdc;
//   border-radius: 0.9375rem 0.9375rem 0 0;
//   position: relative;
//   padding: 1.125rem 0.9375rem;
//   cursor: pointer;
// }
// .p-pet-customer__head.is-active ::after {
//   background-image: url(/common_2024/img/icon/icon_toggle_minus.svg);
// }
// .p-pet-customer__head ::after {
//   background: url(/common_2024/img/icon/icon_toggle_plus.svg) center center/cover no-repeat;
//   position: absolute;
//   content: "";
//   top: 50%;
//   right: 1.5rem;
//   transform: translateY(-50%);
//   height: 1.25rem;
//   width: 1.25rem;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__head ::after {
//     right: 1rem;
//   }
// }
// .p-pet-customer__head-title {
//   font-weight: bold;
//   font-size: 1.25rem;
//   letter-spacing: 0.1em;
// }
// .p-pet-customer__head-sub {
//   font-weight: bold;
//   font-size: 0.625rem;
// }
// .p-pet-customer__body {
//   background-color: #f3f3f3;
//   border-radius: 0 0 0.9375rem 0.9375rem;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body {
//     text-align: center;
//   }
// }
// .p-pet-customer__body-wrapper {
//   display: flex;
//   padding: 2.5rem 0;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body-wrapper {
//     display: inline-block;
//     text-align: center;
//   }
// }
// .p-pet-customer__body-wrapper-left {
//   width: 50%;
//   text-align: center;
//   padding: 0 3.125rem;
// }
// .p-pet-customer__body-wrapper-left-login {
//   width: 100%;
//   max-width: 19.25rem;
//   background: #fff url(/common_2024/img/arrow/arrow_bold_login.svg) right 1.375rem center no-repeat;
//   background-size: 1.125rem;
//   box-shadow: 0 0.25rem 0 #d1d1d1;
//   height: 3.625rem;
//   text-align: center;
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1;
//   display: inline-flex;
//   align-items: center;
//   border-radius: 0.625rem;
//   overflow: hidden;
//   padding-right: 1.5rem;
//   transition: transform 0.2s ease, box-shadow 0.2s ease;
// }
// .p-pet-customer__body-wrapper-left-login-icon {
//   width: 3.625rem;
//   height: 100%;
//   background: url(/common_2024/img/icon/icon_user.svg) center center/1.25rem #404ac7 no-repeat;
// }
// .p-pet-customer__body-wrapper-left-login-text {
//   flex: 1;
//   text-align: center;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body-wrapper-left-login-text {
//     font-size: 1rem;
//   }
// }
// .p-pet-customer__body-wrapper-left-login:focus {
//   box-shadow: 0 0.0625rem 0 #d1d1d1;
//   transform: translateY(0.1875rem);
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-pet-customer__body-wrapper-left-login:hover {
//     box-shadow: 0 0.0625rem 0 #d1d1d1;
//     transform: translateY(0.1875rem);
//   }
// }
// .p-pet-customer__body-wrapper-left-procedure {
//   font-weight: bold;
//   font-size: 0.875rem;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body-wrapper-left-procedure {
//     font-size: 0.8125rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body-wrapper-left {
//     width: 100%;
//     display: inline-block;
//     padding: 0;
//     text-align: center;
//   }
// }
// .p-pet-customer__body-wrapper-right {
//   width: 50%;
//   padding: 0 3.125rem;
//   border-left: 2px solid #fff;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body-wrapper-right {
//     width: auto;
//     padding: 0;
//     border-left: none;
//   }
// }
// .p-pet-customer__body-wrapper-right-container {
//   display: flex;
//   gap: 0.75rem;
// }
// .p-pet-customer__body-wrapper-right-container-btn {
//   background-color: #fff;
//   background: url(/common_2024/img/arrow/arrow_bold_primary.svg) right 1rem center no-repeat;
//   background-size: 1.125rem;
//   border: 1px solid #d1d1d1;
//   border-radius: 1.25rem;
//   height: 6.875rem;
//   box-shadow: 0 0.25rem 0 #d1d1d1;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-around;
//   background-position: center bottom 0.75rem;
//   min-height: 10rem;
//   width: 9.25rem;
//   flex-direction: column;
//   padding: 1rem 0 1.875rem;
//   background-position: center bottom 0.75rem;
//   min-height: 10rem;
//   width: 9.25rem;
//   transition: all 0.2s ease;
// }
// .p-pet-customer__body-wrapper-right-container-btn-icon {
//   aspect-ratio: 1/1;
//   width: 3.5rem;
//   height: 3.5rem;
//   margin: 0;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body-wrapper-right-container-btn-icon {
//     width: 2.8125rem;
//     height: 2.8125rem;
//   }
// }
// .p-pet-customer__body-wrapper-right-container-btn-icon img {
//   width: 100%;
//   height: auto;
// }
// .p-pet-customer__body-wrapper-right-container-btn-text {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.25;
//   letter-spacing: 0.05em;
//   text-align: center;
//   display: flex;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body-wrapper-right-container-btn-text {
//     line-height: 1.4285714286;
//   }
// }
// .p-pet-customer__body-wrapper-right-container-btn:focus {
//   border-color: #ea5404;
//   box-shadow: none;
//   transform: translateY(0.25rem);
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-pet-customer__body-wrapper-right-container-btn:hover {
//     border-color: #ea5404;
//     box-shadow: none;
//     transform: translateY(0.25rem);
//   }
// }
// .p-pet-customer__body-wrapper-right-info a {
//   background-color: #fff;
//   font-size: 0.875rem;
//   line-height: 1.4;
//   letter-spacing: 0.01em;
//   font-weight: 700;
//   border-radius: 0.5rem;
//   border: 1px solid #d1d1d1;
//   width: 18.75rem;
//   height: 3.75rem;
//   background-image: url(/common_2024/img/arrow/arrow_md_primary.svg);
//   background-size: 0.4375rem;
//   background-position: right 0.8125rem center;
//   background-repeat: no-repeat;
//   box-shadow: 0px 0.25rem 0px 0px #d1d1d1;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   transition: transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease 0.1s;
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body-wrapper-right-info a {
//     line-height: 1.2;
//   }
// }
// .p-pet-customer__body-wrapper-right-info a:focus {
//   box-shadow: none;
//   transform: translateY(0.25rem);
//   border-color: #ea5404;
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-pet-customer__body-wrapper-right-info a:hover {
//     box-shadow: none;
//     transform: translateY(0.25rem);
//     border-color: #ea5404;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-customer__body-wrapper-right-info a a {
//     width: 100%;
//   }
// }

// .p-pet-course-table {
//   border-radius: 5px;
//   width: 100%;
//   border-collapse: separate;
//   border: none;
//   border-radius: 3px;
//   position: relative;
//   /* 左上 */
//   /* 右上 */
//   /* 左下 */
//   /* 右下 */
// }
// .p-pet-course-table::before {
//   content: "人気";
//   display: block;
//   position: absolute;
//   width: 2.125rem;
//   height: 2.125rem;
//   -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_star.svg);
//           mask-image: url(/pc/pet/assets/img/pet_cat_star.svg);
//   -webkit-mask-repeat: no-repeat;
//           mask-repeat: no-repeat;
//   -webkit-mask-position: center;
//           mask-position: center;
//   -webkit-mask-size: contain;
//           mask-size: contain;
//   background-color: #fff682;
//   color: #ea5404;
//   top: 0;
//   left: 4px;
//   transform: translateY(-50%);
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
//   padding-top: 4%;
//   font-size: 0.625rem;
//   font-weight: 700;
// }
// .p-pet-course-table__heading {
//   color: #fff;
//   text-align: center;
//   font-family: "メイリオ", "Meiryo", sans-serif;
//   font-size: 1.125rem;
//   font-weight: 700;
//   line-height: 276%;
//   background-color: #ea5404;
// }
// .p-pet-course-table__heading span {
//   font-size: 0.8125rem;
//   font-family: "Zen Kaku Gothic New", -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN", sans-serif;
// }
// .p-pet-course-table__title {
//   color: #ea5404;
//   font-size: 1rem;
//   font-weight: 700;
//   line-height: 236%;
//   background: #ffdaba;
// }
// .p-pet-course-table__price {
//   color: #000;
//   font-size: 3.5rem;
//   font-weight: 700;
//   background: #fff3e8;
//   padding: 0.625rem 0.625rem 0.625rem 1.875rem;
// }
// .p-pet-course-table__price span {
//   font-size: 1.0625rem;
//   font-weight: 700;
// }
// .p-pet-course-table th, .p-pet-course-table td {
//   border-left: 0.375rem solid #ffdaba;
// }
// .p-pet-course-table th:first-child, .p-pet-course-table td:first-child {
//   border-left: none;
// }
// .p-pet-course-table th:first-child {
//   border-radius: 3px 0 0 0;
// }
// .p-pet-course-table th:last-child {
//   border-radius: 0 3px 0 0;
// }
// .p-pet-course-table tr:last-child td:first-child {
//   border-radius: 0 0 0 3px;
// }
// .p-pet-course-table tr:last-child td:last-child {
//   border-radius: 0 0 3px 0;
// }

// .p-pet-course-table--yellow .p-pet-course-table__heading {
//   color: #000;
//   background-color: #fff682;
// }
// .p-pet-course-table--yellow .p-pet-course-table__title {
//   color: #000;
//   background: #fffaba;
// }
// .p-pet-course-table--yellow .p-pet-course-table__price {
//   color: #000;
//   background: #fffeea;
//   padding: 0.625rem;
// }
// .p-pet-course-table--yellow::before {
//   background-color: #ea5404;
//   color: #fff;
// }
// .p-pet-course-table--yellow th, .p-pet-course-table--yellow td {
//   border-left: 0.375rem solid #fff;
// }

// .p-pet-icon-btn {
//   font-weight: 700;
//   border-radius: 0.5rem;
//   border: 1px solid #d1d1d1;
//   background: #fff no-repeat;
//   box-shadow: 0px 0.25rem 0px 0px #d1d1d1;
//   display: inline-flex;
//   align-items: center;
//   transition: transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease 0.1s;
// }
// .p-pet-icon-btn:focus {
//   box-shadow: none;
//   transform: translateY(0.25rem);
//   border-color: #ea5404;
// }
// @media (hover: hover) and (pointer: fine) {
//   .p-pet-icon-btn:hover {
//     box-shadow: none;
//     transform: translateY(0.25rem);
//     border-color: #ea5404;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-icon-btn {
//     width: 100%;
//   }
// }
// .p-pet-icon-btn.-bg-yellow {
//   background-color: #fff0c9;
// }
// .p-pet-icon-btn.-lg {
//   font-size: 1.25rem;
//   letter-spacing: 0.1;
//   width: 18.75rem;
//   height: 3.75rem;
//   background-image: url(/common_2024/img/arrow/arrow_lg_primary.svg);
//   background-size: 0.4375rem;
//   background-position: right 0.8125rem center;
//   padding: 0.5rem;
// }
// .p-pet-icon-btn.-lg.-s {
//   font-size: 0.875rem;
// }
// .p-pet-icon-btn.-md {
//   font-size: 1rem;
//   width: 21.25rem;
//   height: 3rem;
//   background-image: url(/common_2024/img/arrow/arrow_md_primary.svg);
//   background-position: right 0.5rem center;
//   background-size: 0.375rem;
// }
// .p-pet-icon-btn__img {
//   aspect-ratio: 44/44;
//   overflow: hidden;
//   max-width: 2.75rem;
//   height: 2.75rem;
//   margin-right: 0.75rem;
// }
// .p-pet-icon-btn__img img {
//   width: 100%;
//   height: 100%;
//   -o-object-fit: cover;
//      object-fit: cover;
//   background-color: #f8f5f2;
//   border-radius: 50%;
// }
// .p-pet-icon-btn p {
//   text-align: center;
//   font-size: 1.0625rem;
//   font-weight: 700;
//   letter-spacing: 0.10625rem;
// }

// @media (min-width: 960px) {
//   .p-pet-mv-image__img {
//     aspect-ratio: 1100/400;
//   }
// }
// .p-pet-mv-image__img img {
//   width: 100%;
//   height: 100%;
//   -o-object-fit: cover;
//      object-fit: cover;
// }

// @media (max-width: 519px) {
//   .p-pet-mv-images {
//     margin-top: 0.5rem;
//     margin-left: -0.625rem;
//     margin-right: -0.625rem;
//   }
// }
// .p-pet-mv-images__img {
//   aspect-ratio: 785/320;
// }
// @media (max-width: 519px) {
//   .p-pet-mv-images__img {
//     aspect-ratio: 311/190;
//   }
// }
// .p-pet-mv-images__img img {
//   width: 100%;
//   height: 100%;
//   -o-object-fit: cover;
//      object-fit: cover;
// }

// .p-pet-mv-images__img.splide__slide {
//   transition: transform 0.3s;
// }

// .p-pet-mv-images__img.splide__slide.is-prev,
// .p-pet-mv-images__img.splide__slide.is-next {
//   transform: scale(0.85);
// }
// @media (max-width: 519px) {
//   .p-pet-mv-images__img.splide__slide.is-prev,
//   .p-pet-mv-images__img.splide__slide.is-next {
//     transform: scale(1);
//   }
// }

// .splide:not(.is-overflow) .splide__pagination {
//   display: flex;
// }

// .p-pet-link {
//   padding: 2.5rem 0;
//   text-align: center;
//   border: 3px solid #ea5404;
//   border-radius: 1.25rem;
//   max-width: 50.75rem;
//   margin: 0 auto;
// }
// @media (max-width: 519px) {
//   .p-pet-link {
//     padding: 1.25rem 0;
//   }
// }
// .p-pet-link__title {
//   color: #ea5404;
//   font-size: 1.5rem;
//   font-weight: bold;
//   line-height: 1.2;
// }
// .p-pet-link__img-items {
//   margin-top: 1.5rem;
//   display: flex;
//   gap: 0.875rem;
//   justify-content: center;
//   align-items: center;
// }
// .p-pet-link__img-item {
//   -o-object-fit: cover;
//      object-fit: cover;
// }
// .p-pet-link__img-item:nth-child(1) {
//   aspect-ratio: 93/67;
//   width: 5.8125rem;
//   height: 4.1875rem;
// }
// .p-pet-link__img-item:nth-child(1) img {
//   width: 100%;
//   height: auto;
// }
// .p-pet-link__img-item:nth-child(2) {
//   aspect-ratio: 20/32;
//   width: 1.25rem;
//   height: 2rem;
// }
// .p-pet-link__img-item:nth-child(2) img {
//   width: 100%;
//   height: auto;
// }
// .p-pet-link__img-item:nth-child(3) {
//   aspect-ratio: 51/70;
//   width: 3.1875rem;
//   height: 4.375rem;
// }
// .p-pet-link__img-item:nth-child(3) img {
//   width: 100%;
//   height: auto;
// }
// .p-pet-link__text-item-primary {
//   color: #ea5404;
//   font-size: 0.875rem;
//   line-height: 1.25;
//   font-weight: bold;
// }
// @media (max-width: 519px) {
//   .p-pet-link__text-item-primary {
//     font-size: 0.875rem;
//   }
// }
// .p-pet-link__text-item-primary span {
//   font-size: 1.125rem;
// }
// @media (max-width: 519px) {
//   .p-pet-link__text-item-primary span {
//     font-size: 1rem;
//   }
// }
// .p-pet-link__text-item-nomal {
//   font-size: 0.875rem;
//   line-height: 1.25;
//   font-weight: bold;
// }
// .p-pet-link__text-item-nomal span {
//   margin-top: 0.625rem;
//   display: inline-block;
// }
// @media (max-width: 519px) {
//   .p-pet-link__text-item-nomal {
//     font-size: 0.875rem;
//     line-height: 1.5;
//   }
// }
// .p-pet-link__text-item-small {
//   color: #5c5c5c;
//   font-size: 0.75rem;
//   font-weight: bold;
//   line-height: 1.5;
// }
// @media (max-width: 519px) {
//   .p-pet-link__text-item-small {
//     font-size: 0.625rem;
//   }
// }

// .p-pet-meta__head {
//   font-size: 1.5rem;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 1.45;
//   letter-spacing: 0.1em;
//   text-align: center;
//   position: relative;
//   margin-top: 7rem;
//   margin-bottom: 1.25rem;
// }
// .p-pet-meta__head::after {
//   content: "";
//   width: 2.5rem;
//   height: 0.1875rem;
//   background: #ea5404;
//   display: block;
//   margin: 0.5rem auto 0;
// }
// .p-pet-meta__sub {
//   font-size: 1.25rem;
//   font-weight: 700;
//   line-height: 1.5;
//   letter-spacing: 0.1em;
// }
// .p-pet-meta__body {
//   display: flex;
//   justify-content: center;
//   gap: 2rem;
// }
// @media (max-width: 519px) {
//   .p-pet-meta__body {
//     gap: 0.8125rem;
//   }
// }
// .p-pet-meta__container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .p-pet-meta__container-box {
//   display: flex;
//   align-items: center;
// }
// .p-pet-meta__container-box-img {
//   -o-object-fit: cover;
//      object-fit: cover;
//   aspect-ratio: 82/37;
//   width: 5.125rem;
//   height: 2.3125rem;
// }
// @media (max-width: 519px) {
//   .p-pet-meta__container-box-img {
//     aspect-ratio: 38/17;
//     width: 2.375rem;
//     height: 1.0625rem;
//   }
// }
// .p-pet-meta__container-box-img img {
//   width: 100%;
//   height: auto;
// }
// .p-pet-meta__container-box-text {
//   font-weight: bold;
//   font-size: 1.25rem;
//   letter-spacing: 0.1em;
//   color: #5c5c5c;
//   display: block;
// }
// @media (max-width: 519px) {
//   .p-pet-meta__container-box-text {
//     font-size: 0.75rem;
//     letter-spacing: -0.1em;
//   }
// }

// .p-pet-payment__description {
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.4478571429;
//   text-align: center;
// }
// @media (max-width: 519px) {
//   .p-pet-payment__description {
//     font-size: 0.8125rem;
//   }
// }
// .p-pet-payment__logo-items {
//   margin-top: 2.25rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 1.25rem 2.5rem;
// }
// @media (max-width: 519px) {
//   .p-pet-payment__logo-items {
//     margin: 1.5625rem auto 0;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     max-width: 18.75rem;
//   }
// }
// .p-pet-payment__logo-item:nth-child(1) {
//   width: 2.4375rem;
// }
// .p-pet-payment__logo-item:nth-child(2) {
//   width: 4.5625rem;
// }
// .p-pet-payment__logo-item:nth-child(3) {
//   width: 3.75rem;
// }
// .p-pet-payment__logo-item:nth-child(4) {
//   width: 3.6875rem;
// }
// .p-pet-payment__logo-item:nth-child(5) {
//   width: 5.4375rem;
// }
// .p-pet-payment__logo-item:nth-child(6) {
//   width: 4.1875rem;
// }
// .p-pet-payment__table {
//   margin: 1.875rem auto 0;
//   max-width: 33.25rem;
//   width: 100%;
//   padding-left: 3.125rem;
// }
// @media (max-width: 519px) {
//   .p-pet-payment__table {
//     margin: 1.5625rem auto 0;
//   }
// }
// .p-pet-payment__th-logo {
//   width: 5.75rem;
//   padding-left: 3.125rem;
//   background-color: #fff6d1;
//   text-align: center;
//   vertical-align: middle;
// }
// @media (max-width: 519px) {
//   .p-pet-payment__th-logo {
//     padding-left: 0.9375rem;
//   }
// }
// .p-pet-payment .p-pet-payment__th-logo-wrapper {
//   display: inline-block;
// }
// .p-pet-payment__tr:nth-child(1) .p-pet-payment__th-logo-wrapper {
//   width: 1.3125rem;
// }
// .p-pet-payment__tr:nth-child(2) .p-pet-payment__th-logo-wrapper {
//   width: 2rem;
// }
// .p-pet-payment__tr:nth-child(3) .p-pet-payment__th-logo-wrapper {
//   width: 2.625rem;
// }
// .p-pet-payment__tr:nth-child(4) .p-pet-payment__th-logo-wrapper {
//   width: 2.3125rem;
// }
// .p-pet-payment__tr:nth-child(n+2) th {
//   background-image: linear-gradient(to right, #fff 2px, transparent 2px);
//   background-size: 7px 2px;
//   background-repeat: repeat-x;
//   background-position: left top;
// }
// .p-pet-payment__th-text {
//   padding-right: 2.5rem;
//   background-color: #fff6d1;
//   font-size: 0.75rem;
//   font-weight: 700;
//   line-height: 1.3333333333;
//   text-align: center;
//   vertical-align: middle;
// }
// @media (max-width: 519px) {
//   .p-pet-payment__th-text {
//     padding-right: 0.9375rem;
//   }
// }
// .p-pet-payment__td {
//   width: 50%;
//   padding: 1rem;
//   text-align: center;
//   vertical-align: middle;
//   background-image: linear-gradient(to right, #c9c9c9 2px, transparent 2px);
//   background-size: 7px 2px;
//   background-repeat: repeat-x;
//   background-position: left top;
// }
// .p-pet-payment__tr:last-child td {
//   position: relative;
// }
// .p-pet-payment__tr:last-child td::before {
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   display: block;
//   width: 100%;
//   height: 2px;
//   background-image: linear-gradient(to right, #c9c9c9 2px, transparent 2px);
//   background-size: 7px 2px;
//   background-repeat: repeat-x;
//   background-position: left top;
//   content: "";
// }
// .p-pet-payment__td-inner {
//   display: inline-block;
//   width: 1.875rem;
// }
// .p-pet-payment__note {
//   margin: 0.875rem auto 0;
//   max-width: 34.375rem;
//   font-size: 0.75rem;
//   font-weight: 500;
//   line-height: 1.5;
// }
// .p-pet-payment__btn {
//   margin-top: 0.9375rem;
//   text-align: center;
// }

// .p-pet-photo-sample {
//   margin-left: auto;
//   margin-right: auto;
// }
// .p-pet-photo-sample__title {
//   color: #EA5404;
//   font-size: 1.3125rem;
//   text-align: center;
//   font-weight: 700;
// }
// .p-pet-photo-sample__images {
//   display: flex;
//   gap: 1.875rem;
// }
// @media (max-width: 519px) {
//   .p-pet-photo-sample__images {
//     flex-direction: column;
//   }
// }
// .p-pet-photo-sample__body {
//   width: 50%;
//   margin-top: 1rem;
// }
// @media (max-width: 519px) {
//   .p-pet-photo-sample__body {
//     width: 100%;
//     display: flex;
//     gap: 0.625rem;
//   }
// }
// .p-pet-photo-sample__img {
//   width: 100%;
//   aspect-ratio: 423/250;
//   overflow: hidden;
// }
// @media (max-width: 519px) {
//   .p-pet-photo-sample__img {
//     width: 67.11%;
//   }
// }
// .p-pet-photo-sample__img img {
//   width: 100%;
//   height: 100%;
// }
// .p-pet-photo-sample__text {
//   margin-left: 29.24%;
//   font-size: 0.875rem;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 150%;
// }
// @media (max-width: 519px) {
//   .p-pet-photo-sample__text {
//     width: 66.11%;
//     margin-left: 0;
//     display: flex;
//     align-items: center;
//   }
// }

// .p-pet-premium__title {
//   text-align: center;
// }
// .p-pet-premium__info {
//   max-width: 51.875rem;
//   margin-left: auto;
//   margin-right: auto;
// }
// .p-pet-premium__box {
//   margin-top: 2.0625rem;
//   text-align: center;
//   display: flex;
//   gap: 2rem;
// }
// @media (max-width: 519px) {
//   .p-pet-premium__box {
//     flex-direction: column;
//     text-align: left;
//     gap: 1em;
//   }
// }
// .p-pet-premium__type {
//   width: calc(50% - 1rem);
//   max-width: 33.375rem;
//   border-radius: 5px;
// }
// @media (max-width: 519px) {
//   .p-pet-premium__type {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//   }
// }
// .p-pet-premium__name {
//   color: #fff;
//   background-color: #ea5404;
//   font-size: 1.5rem;
//   font-weight: 700;
//   padding: 0.5em;
//   border-radius: 0.625rem 0.625rem 0 0;
//   position: relative;
// }
// @media (max-width: 519px) {
//   .p-pet-premium__name {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 1.25rem;
//   }
// }
// .p-pet-premium__name p {
//   display: flex;
//   justify-content: center;
//   align-items: baseline;
// }
// .p-pet-premium__name p::before, .p-pet-premium__name p::after {
//   content: "";
//   display: block;
//   position: absolute;
//   width: 2.5rem;
//   height: 2.5rem;
//   -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//           mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//   -webkit-mask-repeat: no-repeat;
//           mask-repeat: no-repeat;
//   -webkit-mask-position: center;
//           mask-position: center;
//   -webkit-mask-size: contain;
//           mask-size: contain;
//   background-color: #ffdaba;
//   top: 50%;
//   transform: translateY(-50%);
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
// }
// .p-pet-premium__name p::before {
//   left: 20%;
// }
// @media (max-width: 519px) {
//   .p-pet-premium__name p::before {
//     left: 15%;
//   }
// }
// .p-pet-premium__name p::after {
//   right: 20%;
// }
// @media (max-width: 519px) {
//   .p-pet-premium__name p::after {
//     right: 15%;
//   }
// }
// .p-pet-premium__name p span {
//   font-size: 0.875rem;
// }
// @media (max-width: 519px) {
//   .p-pet-premium__name p span {
//     font-size: 1.25rem;
//     text-align: right;
//     margin-left: auto;
//     display: block;
//     line-height: 150%;
//     padding-right: 0.3125rem;
//   }
// }
// .p-pet-premium__type--yellow .p-pet-premium__name {
//   background-color: #fff682;
//   color: #000000;
// }
// .p-pet-premium__type--yellow .p-pet-premium__name p::before, .p-pet-premium__type--yellow .p-pet-premium__name p::after {
//   background-color: #ea5404;
// }
// .p-pet-premium__body {
//   border-radius: 0 0 0.625rem 0.625rem;
//   background-color: #ffdaba;
// }
// .p-pet-premium__bodyWrap {
//   max-width: 20rem;
//   margin-right: auto;
//   margin-left: auto;
//   padding: 0.75rem;
// }
// .p-pet-premium__targetBox {
//   display: flex;
//   gap: 1.25rem;
//   align-items: center;
//   background-color: #fff;
//   padding: 0.5rem 1.25rem;
//   margin-right: auto;
//   margin-left: auto;
//   border-radius: 0.3125rem;
//   justify-content: center;
// }
// .p-pet-premium__target {
//   color: #000;
//   font-size: 1rem;
//   font-weight: 700;
//   line-height: 133%;
// }
// .p-pet-premium__targetList {
//   color: #ea5404;
//   text-align: center;
//   font-size: 1rem;
//   font-weight: 700;
//   line-height: 133%;
//   display: flex;
//   gap: 0.5rem;
// }
// .p-pet-premium__targetList p {
//   display: flex;
//   background-color: #fff682;
//   padding: 0.25rem;
//   align-items: center;
// }
// .p-pet-premium__course {
//   display: flex;
//   gap: 0.5625rem;
//   margin-top: 0.75rem;
//   margin-left: auto;
//   margin-right: auto;
// }
// .p-pet-premium__courseList {
//   color: #000;
//   text-align: center;
//   width: calc(50% - 0.28125rem);
//   background-color: #fff;
//   padding: 0.375rem;
//   border-radius: 0.3125rem;
// }
// .p-pet-premium__courseList p {
//   font-size: 1rem;
//   font-weight: 700;
//   line-height: 125%;
//   letter-spacing: 0.05rem;
// }
// .p-pet-premium__courseList p span {
//   font-size: 0.75rem;
// }
// .p-pet-premium__price {
//   display: flex;
//   gap: 0.125rem;
//   justify-content: space-around;
// }
// .p-pet-premium__priceLeft {
//   color: #fff;
//   background-color: #ea5404;
//   font-weight: 700;
//   font-size: 0.8125rem;
//   writing-mode: vertical-rl;
//   padding: 0.125rem 0.25rem;
//   text-align: center;
// }
// .p-pet-premium__priceRight {
//   color: #ea5404;
//   font-weight: 700;
//   font-size: 0.875rem;
// }
// .p-pet-premium__priceRight span {
//   font-size: 2.25rem;
// }
// .p-pet-premium__priceRight i {
//   font-family: "メイリオ", "Meiryo", sans-serif;
// }
// .p-pet-premium__type--yellow .p-pet-premium__body {
//   background-color: #fffbcc;
// }
// .p-pet-premium__bottom {
//   margin-top: 2.875rem;
//   margin-right: auto;
//   margin-left: auto;
// }
// .p-pet-premium__btnTxt {
//   color: #ea5404;
//   text-align: center;
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 213%;
// }
// .p-pet-premium__btn {
//   margin-top: 0.5rem;
//   text-align: center;
// }

// .p-pet-type-info__box {
//   margin-top: 2.0625rem;
//   text-align: center;
//   display: flex;
//   gap: 2.9%;
// }
// @media (max-width: 519px) {
//   .p-pet-type-info__box {
//     flex-direction: column;
//     text-align: left;
//     gap: 1em;
//   }
// }
// .p-pet-type-info__type {
//   border: 2px solid #ea5404;
//   width: 48.55%;
//   max-width: 33.375rem;
//   border-radius: 5px 5px 5px 5px;
// }
// @media (max-width: 519px) {
//   .p-pet-type-info__type {
//     width: 100%;
//     display: flex;
//   }
// }
// .p-pet-type-info__type--yellow {
//   border: 2px solid #fff682;
//   color: #000000;
// }
// .p-pet-type-info__name {
//   color: #fff;
//   background-color: #ea5404;
//   font-size: 1.5rem;
//   font-weight: 700;
//   padding: 0.5rem;
//   position: relative;
//   border-radius: 0 0 0 0;
// }
// @media (max-width: 519px) {
//   .p-pet-type-info__name {
//     width: 34%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 0;
//     cursor: pointer;
//   }
// }
// .p-pet-type-info__name p::before {
//   content: "";
//   display: block;
//   position: absolute;
//   width: 2.5rem;
//   height: 2.5rem;
//   -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//           mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//   -webkit-mask-repeat: no-repeat;
//           mask-repeat: no-repeat;
//   -webkit-mask-position: center;
//           mask-position: center;
//   -webkit-mask-size: contain;
//           mask-size: contain;
//   background-color: #ffdaba;
//   top: 50%;
//   left: 26%;
//   transform: translateY(-50%);
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
// }
// @media (max-width: 519px) {
//   .p-pet-type-info__name p::before {
//     display: none;
//   }
// }
// .p-pet-type-info__name p::after {
//   content: "";
//   display: block;
//   position: absolute;
//   width: 2.5rem;
//   height: 2.5rem;
//   -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//           mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//   -webkit-mask-repeat: no-repeat;
//           mask-repeat: no-repeat;
//   -webkit-mask-position: center;
//           mask-position: center;
//   -webkit-mask-size: contain;
//           mask-size: contain;
//   background-color: #ffdaba;
//   top: 50%;
//   right: 26%;
//   transform: translateY(-50%);
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
// }
// @media (max-width: 519px) {
//   .p-pet-type-info__name p::after {
//     display: none;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-type-info__name p span {
//     font-size: 0.875rem;
//     text-align: right;
//     margin-left: auto;
//     display: block;
//     line-height: 150%;
//     padding-right: 0.3125rem;
//   }
// }
// .p-pet-type-info__type--yellow .p-pet-type-info__name {
//   background-color: #fff682;
//   color: #000000;
// }
// .p-pet-type-info__type--yellow .p-pet-type-info__name p::before {
//   content: "";
//   display: block;
//   position: absolute;
//   width: 2.5rem;
//   height: 2.5rem;
//   -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//           mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//   -webkit-mask-repeat: no-repeat;
//           mask-repeat: no-repeat;
//   -webkit-mask-position: center;
//           mask-position: center;
//   -webkit-mask-size: contain;
//           mask-size: contain;
//   background-color: #ea5404;
//   top: 50%;
//   left: 26%;
//   transform: translateY(-50%);
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
// }
// @media (max-width: 519px) {
//   .p-pet-type-info__type--yellow .p-pet-type-info__name p::before {
//     display: none;
//   }
// }
// .p-pet-type-info__type--yellow .p-pet-type-info__name p::after {
//   content: "";
//   display: block;
//   position: absolute;
//   width: 2.5rem;
//   height: 2.5rem;
//   -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//           mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//   -webkit-mask-repeat: no-repeat;
//           mask-repeat: no-repeat;
//   -webkit-mask-position: center;
//           mask-position: center;
//   -webkit-mask-size: contain;
//           mask-size: contain;
//   background-color: #ea5404;
//   top: 50%;
//   right: 26%;
//   transform: translateY(-50%);
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
// }
// @media (max-width: 519px) {
//   .p-pet-type-info__type--yellow .p-pet-type-info__name p::after {
//     display: none;
//   }
// }
// .p-pet-type-info__text {
//   font-size: 1rem;
//   font-weight: 700;
//   color: #000000;
//   padding: 1.5em;
//   line-height: 150%;
//   letter-spacing: 0.05rem;
// }
// @media (max-width: 519px) {
//   .p-pet-type-info__text {
//     width: 66%;
//     font-size: 0.875rem;
//     padding: 1.25rem 0.125rem 1.25rem 0.625rem;
//   }
// }
// .p-pet-type-info__text span {
//   color: #ea5404;
// }
// .p-pet-type-info__type--yellow {
//   color: #000000;
// }

// .p-pet-type-info--tabChange .p-pet-type-info__type {
//   border-radius: 16px 16px 5px 5px;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__name {
//   border-radius: 11px 11px 0 0;
//   padding: 1.2rem;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__body {
//   max-width: 21.25rem;
//   margin-left: auto;
//   margin-right: auto;
//   padding-bottom: 1.875rem;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__feature {
//   display: inline-block;
//   margin: 1.5em 0;
//   padding: 0.5rem 1.25rem;
//   min-width: 120px;
//   max-width: 100%;
//   color: #000;
//   font-size: 1rem;
//   border-radius: 5px;
//   background: #fff682;
//   font-weight: 700;
//   position: relative;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__feature::before {
//   content: "";
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   margin-left: -15px;
//   border: 0.625rem solid transparent;
//   border-top: 0.625rem solid #fff682;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__tableName {
//   font-size: 1rem;
//   font-weight: 700;
//   line-height: 150%;
//   letter-spacing: 1.6px;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__table {
//   width: 100%;
//   margin-top: 0.9375rem;
//   border-collapse: separate;
//   border-radius: 3px;
//   /* 左上 */
//   /* 右上 */
//   /* 左下 */
//   /* 右下 */
// }
// .p-pet-type-info--tabChange .p-pet-type-info__table th {
//   border-bottom: 1px solid #afafaf;
//   border-right: 1px solid #afafaf;
//   width: 25%;
//   padding: 0.625rem 0.125rem;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__table th:last-child {
//   border-right: none;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__table td {
//   border-bottom: 1px solid #afafaf;
//   border-right: 1px solid #afafaf;
//   width: 25%;
//   padding: 0.625rem;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__table td:first-child {
//   border-left: 1px solid #afafaf;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__table th:first-child {
//   border-radius: 3px 0 0 0;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__table th:last-child {
//   border-radius: 0 3px 0 0;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__table tr:last-child td:first-child {
//   border-radius: 0 0 0 3px;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__table tr:last-child td:last-child {
//   border-radius: 0 0 3px 0;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__tableHeading {
//   color: #fff;
//   background-color: #ea5404;
//   text-align: center;
//   font-size: 0.75rem;
//   font-weight: 700;
//   line-height: 166%;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__tableBody {
//   max-width: 1.5rem;
//   max-height: 2.5rem;
//   overflow: hidden;
//   margin-left: auto;
//   margin-right: auto;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__tableBody img {
//   width: 100%;
//   height: 100%;
//   -o-object-fit: cover;
//      object-fit: cover;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__priceBox {
//   color: #fff;
//   text-align: center;
//   font-weight: 700;
//   background-color: #ea5404;
//   padding: 0.3125rem 0.625rem 1rem 0.625rem;
//   margin-top: 1rem;
//   border-radius: 3px;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__priceBox p {
//   font-size: 0.8125rem;
//   font-weight: 700;
//   line-height: 276%;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__price {
//   display: flex;
//   justify-content: center;
//   gap: 0.625rem;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__priceLeft {
//   font-size: 0.8125rem;
//   writing-mode: vertical-rl;
//   border: 1px solid #fff;
//   padding: 0.125rem 0.25rem;
//   text-align: center;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__priceRight {
//   font-size: 1.0625rem;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__priceRight span {
//   font-size: 2.9375rem;
//   line-height: 80%;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__priceRight i {
//   font-family: "メイリオ", "Meiryo", sans-serif;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__course {
//   max-width: 19.125rem;
//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__courseTitle {
//   color: #5c5c5c;
//   font-size: 0.9375rem;
//   font-weight: 700;
//   line-height: 37.457px; /* 236.862% */
//   margin-top: 0.3125rem;
// }
// .p-pet-type-info--tabChange .p-pet-type-info__courseTable {
//   margin-top: 1.75rem;
// }

// .p-pet-type-info__type--yellow .p-pet-type-info__feature {
//   background: #ffdaba;
// }
// .p-pet-type-info__type--yellow .p-pet-type-info__feature::before {
//   border-top: 0.625rem solid #ffdaba;
// }
// .p-pet-type-info__type--yellow .p-pet-type-info__tableHeading {
//   color: #000;
//   background-color: #fff682;
// }
// .p-pet-type-info__type--yellow .p-pet-type-info__priceBox {
//   color: #000;
//   background-color: #fff682;
// }
// .p-pet-type-info__type--yellow .p-pet-type-info__priceLeft {
//   border: 1px solid #000;
// }

// .p-pet-type-info--tabChange .p-pet-type-info__tabs {
//   display: flex;
// }
// @media (max-width: 519px) {
//   .p-pet-type-info--tabChange .p-pet-type-info__tab {
//     padding-bottom: 2px;
//     width: 50%;
//     border-radius: 10px 10px 0 0;
//     position: relative;
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__tab.is-active {
//     border-top: 2px solid #ea5404;
//     border-right: 2px solid #ea5404;
//     border-left: 2px solid #ea5404;
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__tab.is-active::before {
//     background-color: #fff;
//     content: "";
//     height: 4px;
//     width: 100%;
//     position: absolute;
//     bottom: -1px;
//     left: 50%;
//     transform: translateX(-50%);
//     z-index: 2;
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__tab::after {
//     background-color: #fff682;
//     content: "";
//     height: 2px;
//     width: 100%;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-type-info--tabChange .p-pet-type-info__tab--yellow.is-active {
//     border-top: 2px solid #fff682;
//     border-right: 2px solid #fff682;
//     border-left: 2px solid #fff682;
//     border-radius: 10px 10px 0 0;
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__tab--yellow.is-active::before {
//     background-color: #fff;
//     content: "";
//     height: 4px;
//     width: 100%;
//     position: absolute;
//     bottom: -1px;
//     left: 50%;
//     transform: translateX(-50%);
//     z-index: 2;
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__tab--yellow::after {
//     background-color: #ea5404;
//     content: "";
//     height: 2px;
//     width: 100%;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-type-info--tabChange .p-pet-type-info__name {
//     width: 100%;
//     padding: 1rem 1rem 0.625rem 1rem;
//     border-radius: 10px 10px 0 0;
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__name p::before {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 2.5rem;
//     height: 2.5rem;
//     -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//             mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//     -webkit-mask-repeat: no-repeat;
//             mask-repeat: no-repeat;
//     -webkit-mask-position: center;
//             mask-position: center;
//     -webkit-mask-size: contain;
//             mask-size: contain;
//     background-color: #ffdaba;
//     top: 50%;
//     left: 0;
//     transform: translateY(-50%);
//     -webkit-transform: translateY(-50%);
//     -ms-transform: translateY(-50%);
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__name p::after {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 2.5rem;
//     height: 2.5rem;
//     -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//             mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//     -webkit-mask-repeat: no-repeat;
//             mask-repeat: no-repeat;
//     -webkit-mask-position: center;
//             mask-position: center;
//     -webkit-mask-size: contain;
//             mask-size: contain;
//     background-color: #ffdaba;
//     top: 50%;
//     right: 0;
//     transform: translateY(-50%);
//     -webkit-transform: translateY(-50%);
//     -ms-transform: translateY(-50%);
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__name.is-active {
//     color: #000;
//     background-color: #fff;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-type-info--tabChange .p-pet-type-info__name--yellow {
//     color: #000;
//     background-color: #fff682;
//     border-radius: 10px 10px 0px 0px;
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__name--yellow p::before {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 2.5rem;
//     height: 2.5rem;
//     -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//             mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//     -webkit-mask-repeat: no-repeat;
//             mask-repeat: no-repeat;
//     -webkit-mask-position: center;
//             mask-position: center;
//     -webkit-mask-size: contain;
//             mask-size: contain;
//     background-color: #ea5404;
//     top: 50%;
//     left: 0;
//     transform: translateY(-50%);
//     -webkit-transform: translateY(-50%);
//     -ms-transform: translateY(-50%);
//   }
//   .p-pet-type-info--tabChange .p-pet-type-info__name--yellow p::after {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 2.5rem;
//     height: 2.5rem;
//     -webkit-mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//             mask-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//     -webkit-mask-repeat: no-repeat;
//             mask-repeat: no-repeat;
//     -webkit-mask-position: center;
//             mask-position: center;
//     -webkit-mask-size: contain;
//             mask-size: contain;
//     background-color: #ea5404;
//     top: 50%;
//     right: 0;
//     transform: translateY(-50%);
//     -webkit-transform: translateY(-50%);
//     -ms-transform: translateY(-50%);
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-type-info--tabChange .p-pet-type-info__type {
//     display: none;
//     text-align: center;
//     border: none;
//     border-right: 2px solid #ea5404;
//     border-bottom: 2px solid #ea5404;
//     border-left: 2px solid #ea5404;
//     border-radius: 0 0 5px 5px;
//     padding: 0 0.625rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-type-info--tabChange .p-pet-type-info__type.is-active {
//     display: block;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-type-info--tabChange .p-pet-type-info__type--yellow {
//     border: none;
//     border-right: 2px solid #fff682;
//     border-bottom: 2px solid #fff682;
//     border-left: 2px solid #fff682;
//   }
// }
// @media (max-width: 519px) {
//   .p-pet-type-info--tabChange .p-pet-type-info__box {
//     margin-top: 0;
//     gap: 0;
//   }
// }

// .p-pet-type-info__sub-title {
//   font-size: 1.25rem;
//   font-weight: 700;
//   width: -moz-fit-content;
//   width: fit-content;
//   margin-left: auto;
//   margin-right: auto;
//   padding: 0 2.5rem;
//   position: relative;
// }
// .p-pet-type-info__sub-title::before {
//   content: "";
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   left: 0;
//   background-size: 2.5rem;
//   background-repeat: no-repeat;
//   width: 2.5rem;
//   height: 2.5rem;
// }
// .p-pet-type-info__sub-title::after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   right: 0;
//   background-size: 2.5rem;
//   background-repeat: no-repeat;
//   width: 2.5rem;
//   height: 2.5rem;
// }

// .p-pet-type-info__sub-title.--yellow::before {
//   background-image: url(/pc/pet/assets/img/pet_yellow_icon.webp);
// }
// .p-pet-type-info__sub-title.--yellow::after {
//   background-image: url(/pc/pet/assets/img/pet_yellow_icon.webp);
// }

// .p-pet-type-info__sub-title.--orange::before {
//   background-image: url(/pc/pet/assets/img/pet_orange_icon.webp);
// }
// .p-pet-type-info__sub-title.--orange::after {
//   background-image: url(/pc/pet/assets/img/pet_orange_icon.webp);
// }

// .p-koutsuujiko__recommend {
//   max-width: 51.875rem;
//   margin-left: auto;
//   margin-right: auto;
//   padding: 1.5rem 0;
//   border-radius: 1rem;
//   border: 0.0625rem solid #BDBDBD;
//   margin-top: 2.3125rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko__recommend {
//     padding: 1.5rem 0.75rem;
//   }
// }
// .p-koutsuujiko__recommend .c-list-dot {
//   max-width: 25.8125rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-koutsuujiko-point__wrapper {
//   max-width: 62.5rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-koutsuujiko-point__cards {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   gap: 1.5rem 0;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-point__cards {
//     justify-content: center;
//     gap: 0.9375rem 0;
//   }
// }

// .p-koutsuujiko-point__card {
//   max-width: 30.3125rem;
//   width: 100%;
//   min-height: 18.4375rem;
//   border-radius: 0.3125rem;
//   border: 0.0625rem solid #bdbdbd;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-point__card {
//     max-width: 100%;
//   }
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-point__card .c-text-accent {
//     font-size: 1.25rem;
//   }
// }

// .p-koutsuujiko-point__heading {
//   font-size: 1.875rem;
//   color: #ea5404;
//   font-weight: 700;
//   border-radius: 0.3125rem;
//   background: #f3f3f3;
//   margin: 0.5rem 0.625rem 0 0.625rem;
//   padding-left: 2.25rem;
//   height: 5rem;
//   line-height: 4rem;
//   position: relative;
// }
// .p-koutsuujiko-point__heading span {
//   font-size: 3rem;
// }

// .p-koutsuujiko-point__icon {
//   position: absolute;
//   top: 45%;
//   right: 0.9375rem;
//   transform: translateY(-50%);
//   max-width: 5.5rem;
// }
// .p-koutsuujiko-point__icon.-point4 {
//   top: 55%;
//   max-width: 6.125rem;
// }

// .p-koutsuujiko-point__recommend {
//   margin-top: 1.5rem;
//   border-radius: 0.5rem;
//   border: 0.125rem solid #ea5404;
//   padding: 1.875rem 0 2.3125rem 0;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-point__recommend {
//     padding: 1.9375rem 0.5625rem 1.3125rem 0.5625rem;
//   }
// }
// .p-koutsuujiko-point__recommend .c-text-accent.--underline {
//   border-bottom: 0.0625rem dotted #ea5404;
//   width: -moz-fit-content;
//   width: fit-content;
//   margin-left: auto;
//   margin-right: auto;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-point__recommend .c-text-accent.--underline {
//     font-size: 1.25rem;
//   }
// }

// .p-koutsuujiko-point__fukidashi {
//   max-width: 14.125rem;
//   margin-top: 2.25rem;
// }

// .p-koutsuujiko-point__banner {
//   max-width: 33.3125rem;
//   margin-top: 2.125rem;
// }

// .p-koutsuujiko-detail__wrapper {
//   max-width: 62.5rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-koutsuujiko-detail__cards {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   gap: 1.875rem 0;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__cards {
//     gap: 0.9375rem 0;
//   }
// }

// .p-koutsuujiko-detail__card {
//   max-width: 100%;
//   width: 100%;
//   border-radius: 0.3125rem;
//   border: 0.0625rem solid #bdbdbd;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__card {
//     position: relative;
//   }
// }

// .p-koutsuujiko-detail__heading {
//   font-size: 1.875rem;
//   color: #ea5404;
//   font-weight: 700;
//   border-radius: 0.3125rem;
//   background: #f3f3f3;
//   margin: 0.5rem 0.625rem 0 0.625rem;
//   height: 5rem;
//   line-height: 4rem;
//   display: flex;
//   align-items: center;
//   justify-content: left;
//   padding-left: 30%;
// }
// @media (max-width: 519px) {
//   .p-koutsuujiko-detail__heading {
//     text-align: left;
//     font-size: 1.25rem;
//     padding-left: 0.625rem;
//     line-height: 1.5625rem;
//   }
// }
// .p-koutsuujiko-detail__heading span {
//   font-size: 3rem;
//   padding-right: 1.875rem;
// }
// @media (max-width: 519px) {
//   .p-koutsuujiko-detail__heading span {
//     padding-right: 0.625rem;
//   }
// }

// .p-koutsuujiko-detail__wrap {
//   display: flex;
//   gap: 0 1.5rem;
//   padding: 0 2.9375rem;
//   justify-content: center;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__wrap {
//     flex-wrap: wrap;
//     padding: 0 1.5625rem;
//   }
// }

// .p-koutsuujiko-detail__wrap.no__wrap {
//   flex-wrap: nowrap;
//   justify-content: center;
//   align-items: center;
//   gap: 0 0.5rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__wrap.no__wrap {
//     gap: 0 0.3125rem;
//   }
// }

// .p-koutsuujiko-detail__img {
//   max-width: 15rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__img {
//     max-width: 28.125rem;
//   }
// }

// .p-koutsuujiko-detail__sub-img {
//   max-width: 15rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__sub-img {
//     max-width: 7rem;
//   }
// }

// .p-koutsuujiko-detail__icon {
//   max-width: 15rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__icon {
//     position: absolute;
//     max-width: 7.3125rem;
//     top: 0;
//     right: 0.25rem;
//   }
// }

// .p-koutsuujiko-detail__text {
//   max-width: 40.125rem;
// }
// .p-koutsuujiko-detail__text .c-text-accent.--check {
//   background: url(/common_2024/img/icon/check_circle.webp) left center no-repeat;
//   background-size: 0.91875rem;
//   padding-left: 1.5625rem;
//   display: inline-block;
//   line-height: 1.5;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__text .c-text-accent.--check {
//     font-size: 0.875rem;
//   }
// }

// .p-koutsuujiko-detail__card .c-accordion-4 {
//   width: 100%;
//   border: none;
// }

// .p-koutsuujiko-detail__card .c-accordion-4__heading {
//   max-width: 21.25rem;
//   border: 1px solid #d1d1d1;
// }

// .p-koutsuujiko-detail__recommend {
//   border-radius: 1rem;
//   border: 0.125rem solid #ea5404;
//   position: relative;
//   padding: 1rem 0;
// }
// .p-koutsuujiko-detail__recommend .c-btn-important-1 {
//   width: 21.25rem;
//   height: 4.3125rem;
//   justify-content: center;
//   gap: 1.25rem;
//   padding: 0 0.5rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__recommend .c-btn-important-1 {
//     width: 100%;
//     gap: initial;
//     background-position: right 1rem center;
//     flex-direction: initial;
//     min-height: initial;
//   }
// }
// .p-koutsuujiko-detail__recommend .c-btn-important-1__text {
//   white-space: nowrap;
// }
// .p-koutsuujiko-detail__recommend .c-btn-important-1__icon {
//   width: 3.3125rem;
//   height: 2.3125rem;
//   margin-right: 0.5rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-detail__recommend {
//     padding: 1.6875rem 0.625rem 1.5rem 0.625rem;
//     margin: 2.1875rem -0.9375rem 0;
//   }
// }

// .p-koutsuujiko-detail__fukidashi {
//   position: absolute;
//   top: -0.78125rem;
//   left: 1.25rem;
//   max-width: 6.375rem;
// }

// @media (max-width: 519px) {
//   .p-koutsuujiko-table_pt10 {
//     padding-top: 0.75rem !important;
//   }
//   .p-koutsuujiko-table_pt10 .important {
//     top: -0.375rem !important;
//   }
// }
// .js-up-accordion-body {
//   transition: height 0.5s ease;
//   overflow: hidden;
//   padding-bottom: 1.875rem;
// }

// .u-link-has-arrow.--right {
//   padding-left: initial;
//   padding-right: 1.25rem;
//   background: url(/common_2024/img/arrow/arrow_sm_black.svg) right center no-repeat;
//   background-size: 0.625rem;
// }

// .p-koutsuujiko-accordion__inner {
//   margin: auto;
//   max-width: 50.8125rem;
//   width: 100%;
// }

// @media (max-width: 959px) {
//   .p-koutsuujiko-accordion-lists {
//     padding: 0 0.5625rem;
//   }
// }

// .p-koutsuujiko-accordion-list {
//   display: grid;
//   -moz-column-gap: 1.5rem;
//        column-gap: 1.5rem;
//   grid-template-columns: repeat(3, 1fr);
//   grid-template-rows: repeat(2, 1fr);
//   row-gap: 2.25rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-accordion-list {
//     -moz-column-gap: 0.75rem;
//          column-gap: 0.75rem;
//     grid-template-columns: repeat(2, 1fr);
//     grid-template-rows: repeat(3, 1fr);
//     row-gap: 1.5625rem;
//   }
// }

// .p-koutsuujiko-accordion-list__title {
//   text-align: center;
//   font-size: 0.875rem;
//   font-weight: 500;
//   line-height: 1.5;
//   letter-spacing: 0.02625rem;
//   margin-top: 0.25rem;
// }

// .p-koutsuujiko-accordion-service {
//   max-width: 61.25rem;
//   margin-left: auto;
//   margin-right: auto;
//   border-radius: 1rem;
//   border: 0.125rem solid #ea5404;
//   margin-top: 1.0625rem;
//   padding: 1rem 0 0.8125rem 0;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-accordion-service {
//     padding: 1.5625rem 0 2.5625rem 0;
//     margin: 1.0625rem 0.5625rem 0;
//   }
// }

// .p-koutsuujiko-accordion-service__wrap {
//   display: flex;
//   justify-content: center;
//   gap: 0 1.5rem;
//   padding: 0 2.9375rem;
//   margin-top: 0.875rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-accordion-service__wrap {
//     flex-wrap: wrap;
//     padding: 0 1.125rem;
//   }
// }

// .p-koutsuujiko-accordion-service__img {
//   max-width: 13.625rem;
// }

// .p-koutsuujiko-accordion-service__text {
//   max-width: 31rem;
// }

// .p-koutsuujiko-accordion-service__choice {
//   max-width: 43.25rem;
//   margin-left: auto;
//   margin-right: auto;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-accordion-service__choice {
//     padding-left: 0.9375rem;
//     padding-right: 0.9375rem;
//   }
// }

// .p-koutsuujiko-accordion-service__content {
//   max-width: 45rem;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-koutsuujiko-accordion-service__items {
//   display: flex;
//   gap: 1.25rem 2.5rem;
//   flex-wrap: wrap;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-accordion-service__items {
//     gap: 1rem 0;
//     justify-content: space-between;
//   }
// }

// .p-koutsuujiko-accordion-service__item {
//   border-radius: 0.5rem;
//   border: 0.0625rem solid #dedede;
//   padding: 1.6875rem 1rem 2.3125rem 1rem;
//   max-width: 21.25rem;
//   width: 100%;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-accordion-service__item {
//     max-width: 10.3125rem;
//     border-radius: 0.625rem;
//     padding: 1.6875rem 0 2.3125rem 0;
//     min-height: 11.125rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-wrap: wrap;
//   }
// }
// .p-koutsuujiko-accordion-service__item small {
//   color: #5c5c5c;
// }
// .p-koutsuujiko-accordion-service__item .c-btn {
//   width: 19.1875rem;
//   height: 2.375rem;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-accordion-service__item .c-btn {
//     width: 9.4375rem;
//   }
// }

// .p-koutsuujiko-accordion-service__item__announce {
//   position: relative;
// }
// .p-koutsuujiko-accordion-service__item__announce small {
//   position: absolute;
//   bottom: -1.25rem;
//   left: 0;
// }
// @media (max-width: 959px) {
//   .p-koutsuujiko-accordion-service__item__announce small {
//     white-space: nowrap;
//     left: 8%;
//   }
// }

// @media (max-width: 959px) {
//   .p-sports-point {
//     display: none;
//   }
// }

// .p-sports-point__cards {
//   max-width: 68.75rem;
//   margin-left: auto;
//   margin-right: auto;
//   display: flex;
//   justify-content: center;
//   gap: 0 1rem;
// }
// @media (max-width: 959px) {
//   .p-sports-point__cards {
//     flex-wrap: wrap;
//     gap: 0.5rem 0;
//   }
// }

// .p-sports-point__card {
//   max-width: 16.4375rem;
//   width: 100%;
//   min-height: 15rem;
//   border-radius: 0.3125rem;
//   border: 0.0625rem solid #BDBDBD;
// }
// @media (max-width: 959px) {
//   .p-sports-point__card {
//     max-width: 100%;
//   }
// }

// .p-sports-point__heading {
//   font-size: 1.125rem;
//   font-weight: 700;
//   color: #ea5404;
//   border-radius: 0.3125rem;
//   background: #F3F3F3;
//   margin: 0.5rem 0.625rem 0 0.625rem;
//   text-align: center;
//   height: 5rem;
//   display: flex;
//   align-items: baseline;
//   line-height: 2;
//   padding-left: 1.5625rem;
//   position: relative;
// }
// .p-sports-point__heading span {
//   font-size: 2.1875rem;
// }

// .p-sports-point__img {
//   max-width: 4.6875rem;
//   position: absolute;
//   right: 0.9375rem;
//   bottom: 0;
// }

// .p-hoken-floating {
//   position: fixed;
//   top: 35%;
//   right: 1rem;
//   width: 8.375rem;
//   height: 6.125rem;
//   opacity: 0;
//   visibility: hidden;
//   transition: all 0.5s;
//   z-index: 10001;
// }
// @media (max-width: 959px) {
//   .p-hoken-floating {
//     top: initial;
//     bottom: 0;
//     width: 80%;
//     right: 10.5%;
//   }
// }

// .p-hoken-floating.visible {
//   opacity: 1;
//   visibility: visible;
// }

// .p-pet-dog-manzokudo {
//   margin-inline: auto;
//   justify-content: center;
//   max-width: 36.875rem;
//   width: 100%;
// }
// .p-pet-dog-manzokudo__icon {
//   margin-right: 1.25rem;
//   width: 8.125rem;
// }

// .p-pet-dog-lead__img {
//   margin-inline: auto;
//   width: 15rem;
// }

// .p-pet-dog-banner {
//   margin-inline: auto;
//   width: 21.875rem;
// }

// .popup-open {
//   position: fixed;
//   right: 2rem;
//   bottom: 10rem;
//   z-index: 10000;
// }
// @media (max-width: 959px) {
//   .popup-open {
//     right: 1rem;
//     bottom: 1rem;
//   }
// }
// .popup-open img {
//   position: relative;
//   z-index: -1;
// }

// .popup-input {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   -webkit-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   z-index: 10;
//   opacity: 0; /* 初期状態は非表示 */
//   visibility: hidden; /* 初期状態は非表示 */
//   transition: opacity 0.3s ease, visibility 0.3s ease; /* トランジションを追加 */
// }
// .popup-input.active {
//   opacity: 1; /* 表示時の透明度を1に設定 */
//   visibility: visible; /* 表示時の可視性をvisibleに設定 */
// }
// .popup-input .bg {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   -webkit-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   background-color: rgba(0, 0, 0, 0.5);
//   width: 105vw;
//   height: 105vh;
// }
// .popup-input-content {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   -webkit-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   background-color: #fff;
//   width: 60rem;
//   height: 24rem;
//   border-radius: 0.5rem;
// }
// @media (max-width: 519px) {
//   .popup-input-content {
//     width: 22.5rem;
//     height: calc(100vh - 10rem);
//   }
// }

// .p-bycle-pouupcontent .p-bycle-popupclose {
//   width: 5.6rem;
//   height: 5.6rem;
//   background-color: #ea5404;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   top: 0;
//   right: 0;
//   border-radius: 0 0.5rem 0 0;
// }
// @media (max-width: 519px) {
//   .p-bycle-pouupcontent .p-bycle-popupclose {
//     width: 3.5rem;
//     height: 3.5rem;
//   }
// }
// .p-bycle-pouupcontent .p-bycle-popupclose__cross {
//   width: 1.5rem;
//   height: 1.5rem;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .p-bycle-pouupcontent .p-bycle-popupclose__cross span {
//   position: absolute;
//   top: 50%;
//   left: 0%;
//   transform: translateY(-50%);
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
//   width: 1.625rem;
//   height: 0.125rem;
//   background-color: #fff;
// }
// .p-bycle-pouupcontent .p-bycle-popupclose__cross span:first-child {
//   transform: rotate(45deg);
// }
// .p-bycle-pouupcontent .p-bycle-popupclose__cross span:last-child {
//   transform: rotate(135deg);
// }
// .p-bycle-pouupcontent .p-bycle-popupclose p {
//   padding-top: 0.625rem;
//   color: #fff;
//   text-align: center;
// }
// @media (max-width: 519px) {
//   .p-bycle-pouupcontent .p-bycle-popupclose p {
//     font-size: 0.75rem;
//   }
// }
// .p-bycle-pouupcontent__banner {
//   width: 100%;
//   height: 100%;
//   padding: 3.125rem 2.125rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }
// @media (max-width: 519px) {
//   .p-bycle-pouupcontent__banner {
//     padding: 1.5rem 0.625rem;
//   }
// }
// .p-bycle-pouupcontent__banner .p-bycle-pouupcontent-text {
//   font-size: 1.5rem;
//   text-align: center;
//   padding-bottom: 4rem;
// }
// @media (max-width: 519px) {
//   .p-bycle-pouupcontent__banner .p-bycle-pouupcontent-text {
//     font-size: 1.125rem;
//     line-height: 1.6;
//     padding-bottom: 1rem;
//   }
// }
// .p-bycle-pouupcontent__banner ul {
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
// }
// @media (max-width: 519px) {
//   .p-bycle-pouupcontent__banner ul {
//     flex-direction: column;
//   }
// }
// .p-bycle-pouupcontent__banner ul li {
//   height: 10rem;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   flex-direction: column;
//   padding: 0 1rem;
// }
// @media (max-width: 519px) {
//   .p-bycle-pouupcontent__banner ul li {
//     max-width: 100%;
//     margin-bottom: 1rem;
//     height: auto;
//     width: 100%;
//     padding: 0 0 0 0;
//   }
// }
// .p-bycle-pouupcontent__banner ul li:last-child {
//   margin-bottom: 0;
// }
// .p-bycle-pouupcontent__banner ul li a {
//   width: 100%;
// }
// .p-bycle-pouupcontent__banner ul li a p {
//   font-size: 1.125rem;
//   font-weight: bold;
//   text-align: center;
//   padding-bottom: 0.5rem;
// }
// @media (max-width: 519px) {
//   .p-bycle-pouupcontent__banner ul li a p {
//     font-size: 1rem;
//     line-height: 1.5;
//     padding-bottom: 0;
//   }
// }

// /*　タブ切り替え　ベース　*/
// .tab-buttons {
//   display: flex;
//   justify-content: space-between;
// }
// .tab-buttons button {
//   cursor: pointer;
// }

// .tab-content {
//   display: none;
//   opacity: 0;
//   animation: fadeIn 0.5s ease forwards;
// }
// .tab-content.active {
//   display: block;
//   opacity: 1;
// }
// .tab-content .p-bycletab-inner {
//   padding: 3rem;
// }
// @media (max-width: 519px) {
//   .tab-content .p-bycletab-inner {
//     padding: 0.5rem;
//   }
// }

// @keyframes fadeIn {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
// .p-bycle-tab-change {
//   border: 1px solid #ea5404;
//   border-radius: 1rem;
//   margin-top: 10px;
//   position: relative;
//   padding: 4rem 0;
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change {
//     padding: 6.25rem 0;
//     border-radius: 0.625rem;
//   }
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs {
//   width: 100%;
//   border: 1px solid #ea5404;
//   border-radius: 1rem;
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change .p-bycle-tab-change__tabs {
//     flex-wrap: wrap;
//     height: 6.6rem;
//     border-radius: 0.625rem;
//     align-content: flex-start;
//   }
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs:first-child {
//   position: absolute;
//   bottom: auto;
//   top: -1px;
//   margin-bottom: 0.625rem;
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs:last-child {
//   position: absolute;
//   top: auto;
//   bottom: -1px;
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button {
//   min-width: 25%;
//   width: 100%;
//   border-radius: 0rem;
//   border: none;
//   padding: 0.625rem;
//   justify-content: flex-start;
//   align-items: center;
//   color: #ea5404;
//   font-size: 1rem;
//   line-height: 1.6;
//   font-weight: bold;
//   position: relative;
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button {
//     min-width: 50%;
//     width: 50%;
//     height: 3rem;
//     font-size: 0.8125rem;
//   }
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button::after {
//   content: "";
//   width: 1px;
//   height: 4rem;
//   background-color: #ea5404;
//   position: absolute;
//   top: 0;
//   right: 0;
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button::after {
//     height: 3.25rem;
//   }
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button span {
//   font-size: 1.5rem;
//   padding-right: 0.625rem;
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button span {
//     font-size: 1.25rem;
//   }
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button:first-child {
//   border-radius: 1rem 0 0 1rem;
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button:first-child {
//     border-radius: 0.625rem 0 0 0;
//   }
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button:nth-child(2) {
//     border-radius: 0 0.625rem 0 0;
//   }
//   .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button:nth-child(2)::after {
//     content: none;
//   }
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button:nth-child(3) {
//     border-radius: 0 0 0 0.625rem;
//     border-top: 1px solid #ea5404;
//   }
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button:last-child {
//   border-radius: 0 1rem 1rem 0;
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button:last-child {
//     border-radius: 0 0 0.625rem 0;
//     border-top: 1px solid #ea5404;
//   }
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button:last-child::after {
//   content: none;
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button.active {
//   background-color: #ea5404;
//   color: #fff;
//   height: 4rem;
//   box-shadow: none;
// }
// @media (max-width: 519px) {
//   .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button.active {
//     min-width: 50%;
//     height: 3.25rem;
//   }
// }
// .p-bycle-tab-change .p-bycle-tab-change__tabs .tab-button:focus {
//   transform: translateY(0rem);
//   -webkit-transform: translateY(0rem);
//   box-shadow: none;
// }

// /*　---------------　*/
// /*　タブ切り替え　5つのポイント　*/
// .p-byclebest-tab-ranking img {
//   max-width: 499px;
//   width: 100%;
//   margin: 0 auto;
// }

// .p-bycle-course__title p {
//   font-size: 1.5rem;
//   line-height: 1.6;
// }
// .p-bycle-course__img {
//   max-width: 29.5rem;
//   width: 100%;
//   margin: 0 auto;
// }

// .bycle-course-type__text {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   padding-bottom: 2.5rem;
// }
// .bycle-course-type__text p {
//   font-weight: bold;
//   line-height: 1.6;
// }
// .bycle-course-type__text h2 {
//   line-height: 1.6;
//   font-size: 1.25rem;
//   font-weight: bold;
//   border-bottom: 0.125rem dotted #ea5404;
// }
// .bycle-course-type .bycle-course-text {
//   max-width: 100%;
//   width: 100%;
//   justify-content: center;
// }
// @media (max-width: 959px) {
//   .bycle-course-type .bycle-course-text {
//     flex-direction: column;
//   }
// }
// .bycle-course-type .bycle-course-text .bycle-course-text__pic {
//   align-items: center;
//   justify-content: center;
// }
// .bycle-course-type .bycle-course-text .bycle-course-text__pic .bycle-course-text__pic-icon {
//   width: 5rem;
//   height: 5rem;
// }
// .bycle-course-type__advice {
//   text-align: center;
//   color: #ea5404;
//   font-size: 0.875rem;
//   font-weight: bold;
//   padding-top: 2rem;
// }

// .p-bycle-loadservice .p-bycle-loadservice-text {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }
// .p-bycle-loadservice .p-bycle-loadservice-text p {
//   text-align: center;
//   font-weight: bold;
// }
// .p-bycle-loadservice .p-bycle-loadservice-text p.p-bycle-loadservice-level {
//   padding-bottom: 0.25rem;
// }
// .p-bycle-loadservice .p-bycle-loadservice-text p.p-bycle-loadservice-service {
//   font-size: 0.875rem;
//   line-height: 1.6;
// }
// .p-bycle-loadservice .p-bycle-loadservice-list li {
//   justify-content: flex-start;
//   align-items: center;
// }
// .p-bycle-loadservice .p-bycle-loadservice-list__point {
//   width: 40px;
//   height: 40px;
//   justify-content: center;
//   align-items: center;
// }
// .p-bycle-loadservice .p-bycle-loadservice-list__text span {
//   line-height: 1.6;
// }
// .p-bycle-loadservice .p-bycle-loadservice-list__text p {
//   line-height: 1.6;
// }
// .p-bycle-loadservice .p-bycle-loadservice-buttom {
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }
// .p-bycle-loadservice .p-bycle-loadservice-buttom .p-bycle-loadservice-buttom-size {
//   padding: 2rem 0 0.25rem 0;
//   max-width: 20rem;
//   width: 100%;
// }
// .p-bycle-loadservice .p-bycle-loadservice-buttom .p-bycle-loadservice-buttom-size a span {
//   border-bottom: 0.0625rem solid;
// }
// .p-bycle-loadservice .p-bycle-loadservice-buttom .p-bycle-loadservice-buttom-size p {
//   text-align: left;
//   padding-top: 0.25rem;
//   line-height: 1.6;
// }

// .p-bycle-point-wrapper {
//   justify-content: space-between;
// }
// @media (max-width: 959px) {
//   .p-bycle-point-wrapper {
//     flex-direction: column;
//   }
// }
// .p-bycle-point-wrapper__left, .p-bycle-point-wrapper__right {
//   max-width: calc((100% - 2rem) / 2);
//   width: 100%;
// }
// @media (max-width: 959px) {
//   .p-bycle-point-wrapper__left, .p-bycle-point-wrapper__right {
//     max-width: 100%;
//   }
// }
// @media (max-width: 959px) {
//   .p-bycle-point-wrapper__right {
//     padding-top: 2rem;
//   }
// }
// @media (max-width: 959px) {
//   .p-bycle-point-wrapper .p-bycle-point-wrapper-box {
//     padding: 0.625rem;
//   }
// }

// .p-bycle-loadservice {
//   height: 100%;
// }
// .p-bycle-loadservice .p-koutsuujiko-detail__card {
//   height: 100%;
// }

// .p-bycle-customer {
//   padding-top: 2rem;
// }

// .p-bycle-gimuka-img {
//   justify-content: center;
// }
// .p-bycle-gimuka-img img {
//   max-width: 30rem;
// }

// .p-bycle-case .p-bycle-case-subtitle p {
//   line-height: 1.5;
// }
// .p-bycle-case .p-bycle-case-list {
//   justify-content: space-between;
// }
// .p-bycle-case .p-bycle-case-list .p-bycle-hoten {
//   padding-top: 1rem;
//   max-width: calc((100% - 7rem) / 3);
//   width: 100%;
// }
// @media (max-width: 1331px) {
//   .p-bycle-case .p-bycle-case-list .p-bycle-hoten {
//     max-width: calc((100% - 2.5rem) / 3);
//   }
// }
// @media (max-width: 519px) {
//   .p-bycle-case .p-bycle-case-list .p-bycle-hoten {
//     max-width: 100%;
//   }
// }
// .p-bycle-case .p-bycle-case-list .p-bycle-hoten .p-koutsuujiko-detail__card {
//   height: 100%;
// }

// .p-bycle-gimuka-note {
//   justify-content: center;
//   align-items: center;
// }
// .p-bycle-gimuka-note .p-bycle-gimuka-note__text {
//   max-width: -moz-max-content;
//   max-width: max-content;
// }

// .p-bycle-compensation-box {
//   justify-content: center;
//   align-items: center;
// }
// .p-bycle-compensation-box .p-bycle-compensation-box-text {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// }
// @media (max-width: 1331px) {
//   .p-bycle-compensation-box .p-bycle-compensation-box-text {
//     flex-direction: column;
//     padding-right: 1rem;
//     max-width: 50%;
//   }
//   .p-bycle-compensation-box .p-bycle-compensation-box-text p {
//     text-align: center;
//     padding: 0;
//   }
// }
// .p-bycle-compensation-box .p-bycle-compensation-box-text .p-bycle-compensation__pic-icon {
//   max-width: 12.5rem;
// }
// @media (max-width: 1331px) {
//   .p-bycle-compensation-box .p-bycle-compensation-box-text .p-bycle-compensation__pic-icon {
//     max-width: 100%;
//   }
// }
// .p-bycle-compensation-box .p-bycle-compensation-box-text .p-bycle-compensation__pic-icon img {
//   width: 100%;
// }
// .p-bycle-compensation-box .p-bycle-compensation__pic-img {
//   max-width: 24.5rem;
// }
// @media (max-width: 1331px) {
//   .p-bycle-compensation-box .p-bycle-compensation__pic-img {
//     max-width: 50%;
//   }
// }
// .p-bycle-compensation-box .p-bycle-compensation__pic-img img {
//   width: 100%;
// }

// .p-tabbox .p-bycle-point .p-koutsuujiko-detail__heading {
//   position: relative;
// }
// @media (max-width: 519px) {
//   .p-tabbox .p-bycle-point .p-koutsuujiko-detail__heading {
//     justify-content: flex-start;
//     padding-left: 1rem;
//   }
// }
// .p-tabbox .p-bycle-point .p-koutsuujiko-detail__heading::after {
//   content: "";
//   width: 6rem;
//   height: 5rem;
//   position: absolute;
//   background-size: contain;
//   bottom: 0;
//   right: 4px;
// }
// .p-tabbox .p-bycle-point .p-byclebest-title-course::after {
//   background-image: url(/src/img/sub/bycle/bycle_tabpoint1-01_pc.png);
// }
// .p-tabbox .p-bycle-point .p-byclebest-title-loadservice::after {
//   background-image: url(/src/img/sub/bycle/bycle_tabpoint2-01_pc.png);
// }
// .p-tabbox .p-bycle-point .p-byclebest-title-hoten::after {
//   background-image: url(/src/img/sub/bycle/bycle_tabpoint3-01_pc.png);
// }
// .p-tabbox .p-bycle-point .p-byclebest-title-customer::after {
//   background-image: url(/src/img/sub/bycle/bycle_tabpoint4-01_pc.png);
// }
// .p-tabbox .p-bycle-point .p-byclebest-title-compensation::after {
//   background-image: url(/src/img/sub/bycle/bycle_tabpoint5-01_pc.png);
// }
// @media (max-width: 519px) {
//   .p-tabbox .p-bycle-point .c-btn.-md {
//     max-width: calc(100% - 1.25rem);
//   }
// }

// .p-bycle-case .p-bycle-point h3 {
//   justify-content: flex-start;
//   position: relative;
// }
// .p-bycle-case .p-bycle-point h3::after {
//   content: "";
//   height: 6.25rem;
//   width: 7.25rem;
//   display: block;
//   position: absolute;
//   bottom: 0;
//   right: 16px;
//   background-size: contain;
//   background-repeat: no-repeat;
// }
// .p-bycle-case .p-bycle-point:first-child h3::after {
//   background-image: url(/src/img/sub/bycle/bycle_gimu02_pc.png);
// }
// .p-bycle-case .p-bycle-point:nth-child(2) h3::after {
//   background-image: url(/src/img/sub/bycle/bycle_gimu03_pc.png);
// }
// .p-bycle-case .p-bycle-point:nth-child(3) h3::after {
//   background-image: url(/src/img/sub/bycle/bycle_gimu04_pc.png);
// }

/*　--------------------　*/
/*　タブ切り替え　補償内容　*/
// .tab2.bycle-hosyou .p-koutsuujiko-detail__card .p-koutsuujiko-detail__wrap {
//   padding: 0 0.5rem;
// }
// .tab2.bycle-hosyou .p-koutsuujiko-detail__card .p-koutsuujiko-detail__wrap .p-koutsuujiko-detail__recommend {
//   padding: 1.5rem;
// }
// @media (max-width: 519px) {
//   .tab2.bycle-hosyou .p-koutsuujiko-detail__card .p-koutsuujiko-detail__wrap .p-koutsuujiko-detail__recommend {
//     padding: 1rem;
//     margin-left: 0;
//     margin-right: 0;
//   }
// }
// .tab2.bycle-hosyou .p-koutsuujiko-detail__card .p-koutsuujiko-accordion-service {
//   max-width: 100%;
//   padding-left: 1rem;
//   padding-right: 1rem;
// }
// .tab2.bycle-hosyou .p-koutsuujiko-detail__card .p-koutsuujiko-accordion-service__wrap {
//   padding: 0;
// }
// .tab2.bycle-hosyou .p-koutsuujiko-detail__card .p-koutsuujiko-accordion-service .c-accordion-4__wrapper {
//   padding: 0;
// }
// .tab2.bycle-hosyou .p-koutsuujiko-detail__card .p-koutsuujiko-accordion-service .c-accordion-4__content {
//   border-color: #f3f3f3;
// }
// .tab2.bycle-hosyou .p-koutsuujiko-detail__card .p-koutsuujiko-accordion-service .c-accordion-4__heading {
//   max-width: 100%;
// }
// .tab2.bycle-hosyou .js-up-accordion-body {
//   padding-bottom: 0;
// }
// .tab2.bycle-hosyou .p-koutsuujiko-accordion {
//   padding-bottom: 0;
// }
// .tab2.bycle-hosyou .point01 .p-koutsuujiko-detail__wrap .p-koutsuujiko-detail__text {
//   max-width: 43rem;
// }
// .tab2.bycle-hosyou .point01 .jidan-daikou__flex {
//   max-width: 50.5rem;
//   width: 100%;
//   margin: 0 auto;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: left;
//   align-items: center;
//   gap: 1.5rem;
// }
// @media (max-width: 519px) {
//   .tab2.bycle-hosyou .point01 .jidan-daikou__flex {
//     justify-content: center;
//     gap: 1rem;
//   }
// }
// .tab2.bycle-hosyou .point01 .jidan-daikou__flex--img {
//   max-width: 13.625rem;
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .tab2.bycle-hosyou .point01 .jidan-daikou__flex--text {
//     width: 100%;
//   }
// }
// .tab2.bycle-hosyou .point02 .p-koutsuujiko-detail__heading {
//   position: relative;
// }
// .tab2.bycle-hosyou .point02 .p-koutsuujiko-detail__heading picture {
//   display: none;
//   position: absolute;
//   right: 0;
//   top: -1rem;
//   max-width: 6rem;
// }
// @media (max-width: 959px) {
//   .tab2.bycle-hosyou .point02 .p-koutsuujiko-detail__heading picture {
//     display: block;
//   }
// }
// @media (max-width: 959px) {
//   .tab2.bycle-hosyou .point02 .p-koutsuujiko-detail__heading picture {
//     top: -0.5rem;
//   }
// }
// @media (max-width: 959px) {
//   .tab2.bycle-hosyou .point02 .p-koutsuujiko-detail__icon {
//     display: none;
//   }
// }
// .tab2.bycle-hosyou .point02 .p-koutsuujiko-accordion__list {
//   grid-template-rows: none;
// }
// .tab2.bycle-hosyou .point02 .p-koutsuujiko-detail__recommend p {
//   display: block;
// }
// .tab2.bycle-hosyou .point03 .p-koutsuujiko-detail__heading {
//   position: relative;
// }
// .tab2.bycle-hosyou .point03 .p-koutsuujiko-detail__heading picture {
//   display: none;
//   position: absolute;
//   right: 0;
//   top: -0.5rem;
//   max-width: 6rem;
// }
// @media (max-width: 959px) {
//   .tab2.bycle-hosyou .point03 .p-koutsuujiko-detail__heading picture {
//     display: block;
//   }
// }
// @media (max-width: 959px) {
//   .tab2.bycle-hosyou .point04 .p-koutsuujiko-detail__icon {
//     max-width: 5rem;
//     top: 1.5rem;
//     right: 0.5rem;
//   }
// }
// .tab2.bycle-hosyou .point04 .p-koutsuujiko-detail__wrap {
//   max-width: 50.5rem;
//   width: 100%;
//   justify-content: left;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .tab2.bycle-hosyou .point04 .p-koutsuujiko-detail__wrap {
//     padding: 0;
//   }
// }
// .tab2.bycle-hosyou .point04 .p-koutsuujiko-detail__text {
//   max-width: 27.25rem;
//   width: 100%;
// }
// @media (max-width: 959px) {
//   .tab2.bycle-hosyou .point04 .p-koutsuujiko-detail__text {
//     max-width: 100%;
//   }
// }
// .tab2.bycle-hosyou .bycle__smart-service {
//   padding: 2.5rem 1rem;
// }
// .tab2.bycle-hosyou .bycle__smart-service__wrap {
//   max-width: 50.5rem;
//   width: 100%;
//   display: flex;
//   justify-content: left;
//   gap: 2.5rem;
// }
// @media (max-width: 519px) {
//   .tab2.bycle-hosyou .bycle__smart-service__wrap {
//     gap: 1rem;
//     align-items: center;
//   }
// }
// .tab2.bycle-hosyou .bycle__smart-service__wrap--img {
//   max-width: 5.375rem;
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .tab2.bycle-hosyou .bycle__smart-service__wrap--img {
//     max-width: 3.5rem;
//   }
// }
// .tab2.bycle-hosyou .bycle__smart-service__wrap--text {
//   max-width: 42.5rem;
//   width: 100%;
// }

// .p-bycle__orange-content {
//   position: relative;
//   border: 0.125rem solid #ea5404;
//   border-radius: 1rem;
//   padding: 2.5rem 1rem;
// }
// .p-bycle__orange-content .fukidasi {
//   position: absolute;
//   width: 100%;
//   max-width: 10.5rem;
//   top: -2.25rem;
//   left: 50%;
//   transform: translateX(-50%);
//   -webkit-transform: translateX(-50%);
// }
// @media (max-width: 519px) {
//   .p-bycle__orange-content {
//     padding: 1.5rem 1rem;
//   }
// }
// .p-bycle__orange-content__flex {
//   max-width: 51.25rem;
//   width: 100%;
//   display: flex;
//   justify-content: left;
// }
// @media (max-width: 519px) {
//   .p-bycle__orange-content__flex {
//     flex-wrap: wrap;
//     justify-content: center;
//   }
// }
// .p-bycle__orange-content__flex--img {
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .p-bycle__orange-content__flex--text {
//     width: 100%;
//   }
// }

// .p-bycle__btn-grid {
//   max-width: 45rem;
//   width: 100%;
//   display: grid;
//   grid-template-columns: repeat(2, minmax(0, 1fr));
//   gap: 1rem 2.5rem;
// }
// @media (max-width: 519px) {
//   .p-bycle__btn-grid {
//     gap: 0.5rem;
//   }
// }
// .p-bycle__btn-grid--btn {
//   border: 0.0625rem solid #bdbdbd;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
//   padding: 2rem 1rem;
//   border-radius: 0.5rem;
// }
// .p-bycle__btn-grid--btn p {
//   width: 100%;
// }
// .p-bycle__btn-grid--btn.annot small {
//   bottom: -1.25rem;
//   left: 0;
//   position: absolute;
// }

// .list-img {
//   display: flex;
//   justify-content: left;
//   align-items: flex-end;
//   gap: 1.5rem;
// }
// .list-img__imgblock {
//   max-width: 4rem;
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .list-img__imgblock {
//     padding-left: 0rem;
//     width: 20%;
//     max-width: 3rem;
//   }
// }

// .gray_block {
//   width: 100%;
//   padding: 0.5rem;
//   background-color: #f3f3f3;
//   display: flex;
//   justify-content: left;
//   align-items: center;
//   gap: 1rem;
// }
// @media (max-width: 519px) {
//   .gray_block {
//     gap: 0.5rem;
//   }
// }
// .gray_block--title {
//   background-color: #ea5404;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
//   max-width: 3.75rem;
//   width: 100%;
//   height: 3.75rem;
//   border-radius: 0.25rem;
// }
// .gray_block--title p {
//   width: 100%;
//   white-space: nowrap;
// }

// .c-btn.-lg {
//   padding: 0 2.5rem;
//   font-size: 0.875rem;
// }
// @media (max-width: 519px) {
//   .c-btn.-lg {
//     padding: 0 0.75rem;
//   }
// }

// .c-btn.-md {
//   width: 100%;
//   max-width: 21.25rem;
// }

// .additional_services li span {
//   width: 3.75rem;
//   height: 3.75rem;
// }

// /*　----------------　*/
// /*　決済方法　*/
// .p-kokunai-payment__icons.bycle {
//   grid-template-columns: repeat(6, 1fr);
// }
// @media (max-width: 959px) {
//   .p-kokunai-payment__icons.bycle {
//     grid-template-columns: repeat(3, 1fr);
//   }
// }

// /*　------　*/
// /*　タブ切り替え　保険金額と保険料　*/
// .p-byclehoken-tab-money .p-keiyakusya-table table {
//   width: 100%;
// }
// .p-byclehoken-tab-money .p-keiyakusya-table table tr.-p-byclepic-ful {
//   max-width: 100%;
//   width: 100%;
//   grid-template-areas: "p-bycle-hoken-title p-bycle-hoken-pic p-bycle-hoken-pic p-bycle-hoken-pic";
// }
// .p-byclehoken-tab-money .p-keiyakusya-table table tr.-p-byclepic-ful .p-bycle-hoken-title {
//   grid-area: p-bycle-hoken-title;
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-money .p-keiyakusya-table table tr.-p-byclepic-ful .p-bycle-hoken-title {
//     min-width: 7.6875rem;
//   }
// }
// .p-byclehoken-tab-money .p-keiyakusya-table table tr.-p-byclepic-ful .p-bycle-hoken-pic {
//   grid-area: p-bycle-hoken-pic;
//   text-align: center;
//   background-color: rgba(144, 144, 144, 0.1);
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-money .p-keiyakusya-table table tr.-p-byclepic-ful .p-bycle-hoken-pic {
//     min-width: 100%;
//   }
// }
// .p-byclehoken-tab-money .p-keiyakusya-table table tr.-p-byclepic-ful .p-bycle-hoken-pic strong {
//   line-height: 1.2;
//   font-size: 0.875rem;
// }

// .p-byclehoken-tab-money .P-byclehoken-text {
//   line-height: 1.5rem;
// }
// .p-byclehoken-tab-money table thead tr td:nth-child(2), .p-byclehoken-tab-money table thead tr td:nth-child(3), .p-byclehoken-tab-money table thead tr td:nth-child(4) {
//   min-width: 8.75rem;
// }
// .p-byclehoken-tab-money table tbody tr .p-bycle-boder {
//   border-bottom: 1px solid #000;
// }
// .p-byclehoken-tab-money table tbody tr.-p-byclepic-ful .p-bycle-hoken-pic {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .p-byclehoken-tab-money table tbody tr td {
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-money table tbody tr td {
//     line-height: 1rem;
//     min-width: 8.75rem;
//     justify-content: center;
//     align-items: center;
//   }
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-money table tbody tr td span {
//     margin-top: 0;
//   }
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-money table tbody tr td strong {
//     font-size: 1rem;
//   }
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-money table tbody tr:not(:last-child)::before {
//     width: 11.75rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-money table tbody tr:not(:last-child)::before {
//     width: 8.5rem;
//   }
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-money .p-bycle-table__wrappe {
//     min-width: 37.9375rem;
//   }
// }

// @media (max-width: 519px) {
//   .p-byclehoken-tab-price .p-keiyakusya-type__title {
//     padding: 0 2rem;
//   }
// }
// .p-byclehoken-tab-price .p-keiyakusya-type__title::before, .p-byclehoken-tab-price .p-keiyakusya-type__title::after {
//   width: 2rem;
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-price .p-keiyakusya-type__title span {
//     font-size: 1.5rem;
//   }
// }
// .p-byclehoken-tab-price .p-keiyakusya-type__title span.u-bycle-subtext {
//   font-size: 1.5rem;
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-price .p-keiyakusya-type__title span.u-bycle-subtext {
//     font-size: 1rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-price .p-keiyakusya-type__title span.u-bycle-subtext {
//     font-size: 1.2rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-price .p-keiyakusya-type__title {
//     width: 100%;
//     font-size: 1.2rem;
//   }
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper {
//     min-width: 42.875rem;
//   }
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table {
//     width: 100%;
//   }
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn {
//   position: relative;
//   border: none;
//   grid-template-areas: "p-byclehoten-type p-byclehoten-price1 p-byclehoten-price2 p-byclehoten-price3" "p-byclehoten-type p-byclehoten-price4 p-byclehoten-price5 p-byclehoten-price6";
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn::before, .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn::after {
//   background: #fff;
//   position: absolute;
//   content: "";
//   width: calc(100% - 8.482188rem);
//   top: 50%;
//   right: 0;
//   height: 1px;
//   border-top: 0.125rem dashed #c9c9c9;
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn::before, .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn::after {
//     width: calc(100% - 9.0625rem);
//   }
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn::before {
//   top: auto;
//   bottom: 0;
//   z-index: 1;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn:last-child::before {
//   content: none;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td:first-child {
//   grid-area: p-byclehoten-type;
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td:first-child {
//     height: 100%;
//   }
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td:nth-child(2) {
//   grid-area: p-byclehoten-price1;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td:nth-child(3) {
//   grid-area: p-byclehoten-price2;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td:nth-child(4) {
//   grid-area: p-byclehoten-price3;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td:nth-child(5) {
//   grid-area: p-byclehoten-price4;
//   background-color: rgba(176, 134, 69, 0.1);
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td:nth-child(6) {
//   grid-area: p-byclehoten-price5;
//   background-color: rgba(144, 144, 144, 0.1);
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td:nth-child(7) {
//   grid-area: p-byclehoten-price6;
//   background-color: rgba(189, 159, 0, 0.1);
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td .p-byclehoten-pay-temporary {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td .p-byclehoten-pay-temporary p {
//   height: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td .p-byclehoten-pay-temporary p:first-child {
//   background-color: #fff6f2;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type {
//   justify-content: space-between;
//   padding: 0;
//   border: none;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type .p-byclehoten-type__inner {
//   width: calc(100% - 5rem);
//   padding: 0.5rem;
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type .p-byclehoten-type__inner {
//     width: calc(100% - 3.5rem);
//   }
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type .p-byclehoten-type__inner {
//     width: calc(100% - 5rem);
//   }
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type .p-byclehoten-type__inner .p-byclehoten-type__inner-img {
//   padding-bottom: 0.25rem;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type .p-byclehoten-type__inner .p-byclehoten-type__inner-text {
//   width: 100%;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type .p-byclehoten-type__inner .p-byclehoten-type__inner-text a {
//   padding-right: 1rem;
//   padding-bottom: 0.0625rem;
//   border-bottom: 0.0625rem solid #000;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type .p-byclehoten-type__inner .p-byclehoten-type__inner-text a span {
//   right: 0;
//   width: 0.875rem;
//   margin: 0;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type .p-byclehoten-type__inner .p-byclehoten-type__inner-text a span img {
//   width: 100%;
//   height: auto;
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody .p-byclytable-twocolumn td.p-byclehoten-type .p-byclehoten-type__inner .p-byclehoten-type__inner-text p {
//   width: 100%;
//   line-height: 1.6;
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table thead td:first-child {
//     min-width: 14rem;
//   }
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody td {
//     height: 100%;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//   }
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody td:first-child {
//   border-bottom: none;
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody td:first-child {
//     min-width: 14rem;
//   }
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody td .p-byclehoten-pay-temporary {
//   max-width: 5rem;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #ffe8dc;
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody td .p-byclehoten-pay-temporary {
//     max-width: 3.5rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody td .p-byclehoten-pay-temporary {
//     max-width: 5rem;
//   }
// }
// .p-byclehoken-tab-price .p-bycle-table__wrapper table tbody td .p-byclehoten-pay-temporary p {
//   max-width: 100%;
//   width: 100%;
// }
// @media (max-width: 959px) {
//   .p-byclehoken-tab-price .p-bycle-table_course table tbody tr {
//     height: 3.5rem;
//   }
// }
// .p-byclehoken-tab-price .p-bycle-table_course table tbody tr td:first-child {
//   justify-content: space-between;
//   padding: 0;
// }
// .p-byclehoken-tab-price .p-bycle-table_course table tbody tr td:first-child p {
//   width: 100%;
//   text-align: center;
// }

// @media (max-width: 519px) {
//   .p-byclehoken-tab-money .p-keiyakusya-table,
//   .p-byclehoken-tab-price .p-keiyakusya-table {
//     padding-bottom: 1rem;
//   }
//   .p-byclehoken-tab-money .p-keiyakusya-table::-webkit-scrollbar,
//   .p-byclehoken-tab-price .p-keiyakusya-table::-webkit-scrollbar {
//     width: 2rem;
//     height: 2px;
//   }
//   .p-byclehoken-tab-money .p-keiyakusya-table::-webkit-scrollbar-track,
//   .p-byclehoken-tab-price .p-keiyakusya-table::-webkit-scrollbar-track {
//     background-color: #cbcbcb;
//   }
//   .p-byclehoken-tab-money .p-keiyakusya-table::-webkit-scrollbar-thumb,
//   .p-byclehoken-tab-price .p-keiyakusya-table::-webkit-scrollbar-thumb {
//     background-color: #ee802f;
//   }
// }

// /*　----------------------　*/
// /*　タブ切り替え　自転車ロードサービス　*/
// .tab4.bycle-roadservice .bycle-roadservice__block01__box--img {
//   position: relative;
// }
// .tab4.bycle-roadservice .bycle-roadservice__block01__box--img .cover-text {
//   position: absolute;
//   background: rgba(255, 255, 255, 0.7);
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   min-height: 6.375rem;
// }
// .tab4.bycle-roadservice .bycle-roadservice__block02__box--items {
//   max-width: 36rem;
//   width: 100%;
// }
// .tab4.bycle-roadservice .bycle-roadservice__block04__grid {
//   gap: 2.5rem 5rem;
// }
// @media (max-width: 519px) {
//   .tab4.bycle-roadservice .bycle-roadservice__block04__grid {
//     gap: 2.5rem;
//   }
// }
// .tab4.bycle-roadservice .bycle-roadservice__block05 .l-container {
//   max-width: 51rem;
//   width: 100%;
// }
// .tab4.bycle-roadservice .bycle-roadservice__block05 .l-container .c-btn-important-1 {
//   justify-content: center;
//   height: 3.75rem;
// }
// @media (max-width: 519px) {
//   .tab4.bycle-roadservice .bycle-roadservice__block05 .l-container .c-btn-important-1 {
//     width: 100%;
//     height: auto;
//     min-height: auto;
//     background-position: right 1rem center;
//     padding: 0.5rem;
//   }
// }
// .tab4.bycle-roadservice .bycle-roadservice__block06--img {
//   margin: 0 auto;
//   max-width: 21rem;
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .tab4.bycle-roadservice .bycle-roadservice__block06--img {
//     max-width: 16.56rem;
//   }
// }
// .tab4.bycle-roadservice .bycle-roadservice__block07 .c-download-pdf {
//   margin: 0;
// }

// .bycle-flexbox01 {
//   display: flex;
//   justify-content: space-between;
// }
// .bycle-flexbox01__left {
//   max-width: 13.43rem;
//   width: 100%;
//   display: flex;
//   justify-content: center;
// }
// .bycle-flexbox01__left picture {
//   max-width: 10.875rem;
//   width: 100%;
// }
// .bycle-flexbox01__right {
//   max-width: calc(100% - 15.93rem);
//   width: 100%;
// }
// .bycle-flexbox01__right--btns {
//   gap: 1.5rem 1rem;
// }
// @media (max-width: 519px) {
//   .bycle-flexbox01__left {
//     max-width: 5.5rem;
//   }
//   .bycle-flexbox01__left picture {
//     max-width: 100%;
//   }
//   .bycle-flexbox01__right {
//     max-width: calc(100% - 6rem);
//   }
// }

// .bycle__c-voice {
//   display: flex;
//   justify-content: space-between;
// }
// .bycle__c-voice--img {
//   max-width: 5rem;
//   width: 100%;
//   display: flex;
//   justify-content: center;
// }
// .bycle__c-voice--img picture {
//   max-width: 3.56rem;
//   width: 100%;
// }
// .bycle__c-voice--text {
//   max-width: calc(100% - 6rem);
//   width: 100%;
// }

// .bycle__check-text {
//   padding-left: 1.5625rem;
//   position: relative;
// }
// .bycle__check-text::after {
//   content: "";
//   position: absolute;
//   background: url(/common_2024/img/icon/check_circle.webp) no-repeat;
//   background-size: contain;
//   top: 0.25rem;
//   left: 0;
//   width: 1rem;
//   height: 1rem;
// }

// /*　-------------------------　*/
// .-p-bycle-voice {
//   max-width: 100%;
//   width: 100%;
// }
// .-p-bycle-voice__list {
//   justify-content: space-between;
// }

// .p-bycle-box {
//   max-width: calc((100% - 12rem) / 3);
//   width: 100%;
// }
// @media (max-width: 959px) {
//   .p-bycle-box {
//     max-width: calc((100% - 6rem) / 3);
//   }
// }
// @media (max-width: 519px) {
//   .p-bycle-box {
//     max-width: 100%;
//     width: 100%;
//   }
// }
// .p-bycle-box .p-bycle-box__type {
//   justify-content: center;
//   align-items: center;
// }
// .p-bycle-box .p-bycle-box__type-icon {
//   width: 7.25rem;
//   height: 7.25rem;
// }
// .p-bycle-box .p-bycle-box__type-name {
//   width: calc(100% - 7.25rem);
// }
// .p-bycle-box .p-bycle-box__type-name .p-bycle-box-voice-typename {
//   justify-content: flex-start;
//   align-items: center;
// }
// .p-bycle-box .p-bycle-box__type-name .p-bycle-box-voice-typename span {
//   width: 5.25rem;
//   height: 1.5rem;
//   justify-content: center;
//   align-items: center;
// }
// .p-bycle-box .p-bycle-box__inner p {
//   line-height: 1.5;
// }

// /*　プラン診断　*/
// .-p-bycle-plan-back {
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }
// .-p-bycle-plan-back .p-bycle-plan-title {
//   max-width: 316px;
// }
// .-p-bycle-plan-back p {
//   width: 100%;
//   text-align: center;
// }
// .-p-bycle-plan-back p a {
//   background-image: url(/common_2024/img/arrow/arrow_sm_white.svg);
// }

// .-p-bycle-plan-title img {
//   width: 20rem;
// }
// .-p-bycle-plan-progressbar {
//   display: flex;
//   width: 100%;
//   padding: 0 6rem;
// }
// @media (max-width: 519px) {
//   .-p-bycle-plan-progressbar {
//     padding: 0;
//   }
// }
// .-p-bycle-plan-progressbar-step {
//   position: relative;
//   width: 33%;
//   text-align: center;
//   align-items: center;
//   justify-content: center;
//   padding: 1rem 0;
//   background: #fff;
// }
// .-p-bycle-plan-progressbar-step:not(:last-child)::before {
//   position: absolute;
//   z-index: 2;
//   top: 0;
//   bottom: 0;
//   left: 100%;
//   content: "";
//   border: 2rem solid transparent;
//   border-left: 1rem solid #fff;
//   margin: auto;
// }
// .-p-bycle-plan-progressbar-step:not(:last-child)::after {
//   position: absolute;
//   z-index: 2;
//   top: 0;
//   bottom: 0;
//   left: 100%;
//   content: "";
//   border: 2rem solid transparent;
//   border-left: 1rem solid #fff;
//   margin: auto;
// }
// .-p-bycle-plan-progressbar-step:not(:last-child)::before {
//   margin-left: 2px;
//   border-left-color: #f3f3f3;
// }
// .-p-bycle-plan-progressbar-step.active {
//   z-index: 1;
//   background: #ea5404;
//   color: #fff;
// }
// .-p-bycle-plan-progressbar-step.active:not(:last-child)::after {
//   border-left-color: #ea5404;
// }
// .-p-bycle-plan-progressbar-step.active:not(:last-child)::before {
//   border-left: none;
// }
// .-p-bycle-plan-select {
//   width: 100%;
//   padding: 0 15rem;
// }
// @media (max-width: 519px) {
//   .-p-bycle-plan-select {
//     padding: 0.5rem;
//   }
// }
// .-p-bycle-plan-select-btn {
//   background: #fff;
// }
// .-p-bycle-plan-select-btn label {
//   padding: 0.5rem;
//   padding-top: 2rem;
//   width: 100%;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   cursor: pointer;
//   height: 100%;
//   border: 0.0625rem solid #d1d1d1;
// }
// .-p-bycle-plan-select-btn label picture {
//   max-width: 5rem;
//   width: 100%;
// }
// .-p-bycle-plan-select-btn label.honin picture {
//   max-width: 3.5rem;
// }
// .-p-bycle-plan-select-btn label.kazoku picture {
//   max-width: 3.5rem;
// }
// .-p-bycle-plan-select-btn label.sinzoku picture {
//   max-width: 4.5rem;
// }
// .-p-bycle-plan-select-btn label::before {
//   content: "";
//   display: block;
//   border-radius: 50%;
//   position: absolute;
//   transform: translateY(-50%) translateX(-50%);
//   top: 1rem;
//   left: 50%;
//   border: 0.0625rem solid #d1d1d1;
//   border-radius: 50%;
//   width: 1rem;
//   height: 1rem;
// }
// .-p-bycle-plan-select-btn label::after {
//   content: "";
//   display: block;
//   border-radius: 50%;
//   position: absolute;
//   transform: translateY(-50%) translateX(-50%);
//   top: 1rem;
//   left: 50%;
//   background-color: #ea5404;
//   border-radius: 50%;
//   opacity: 0;
//   width: 0.5rem;
//   height: 0.5rem;
// }
// .-p-bycle-plan-select-btn label .tag-area {
//   position: absolute;
//   top: 0;
//   left: 0;
// }
// @media (max-width: 519px) {
//   .-p-bycle-plan-select-btn label .tag-area {
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     flex-direction: row-reverse;
//   }
// }
// .-p-bycle-plan-select-btn label .tag-area .tag {
//   padding: 0.25rem;
//   border: 0.0625rem solid #d1d1d1;
// }
// .-p-bycle-plan-select-btn label .tag-area .tag.recom {
//   background-color: #fff682;
//   border: none;
// }
// .-p-bycle-plan-select-btn input:checked + label::after {
//   opacity: 1;
// }
// .-p-bycle-plan-select-btn input:checked + label {
//   border: 0.0625rem dashed #ea5404;
// }
// .-p-bycle-plan-select-btn .visually-hidden {
//   position: absolute;
//   white-space: nowrap;
//   border: 0;
//   clip: rect(0 0 0 0);
//   -webkit-clip-path: inset(50%);
//           clip-path: inset(50%);
//   overflow: hidden;
//   height: 1px;
//   width: 1px;
//   margin: -1px;
//   padding: 0;
// }

// .u-bycle-plan-access {
//   padding-top: 4rem;
//   max-width: 30rem;
//   width: 100%;
//   margin: 0 auto;
// }
// @media (max-width: 519px) {
//   .u-bycle-plan-access {
//     max-width: calc(100% - 1.25rem);
//   }
// }

// /*　-------　*/
// .p-bycle-list li {
//   padding-bottom: 2rem;
//   display: flex;
//   justify-content: flex-start;
//   align-items: flex-start;
// }
// @media (max-width: 519px) {
//   .p-bycle-list li {
//     padding-bottom: 1.5rem;
//   }
// }
// .p-bycle-list li::before {
//   content: "";
//   width: 0.875rem;
//   height: 0.875rem;
//   margin-top: 0.5rem;
//   background-repeat: no-repeat;
//   background-image: url(/common_2024/img/icon/check_circle.png);
//   background-size: contain;
// }
// @media (max-width: 519px) {
//   .p-bycle-list li::before {
//     flex-shrink: 0;
//   }
// }
// .p-bycle-list li p {
//   font-size: 1.25rem;
//   font-weight: bold;
//   padding-left: 0.5rem;
//   line-height: 1.6;
// }
// .p-bycle-list li:last-child {
//   padding-bottom: 0;
// }

// .p-byclebest-about__inner .p-byclebest-about__inner-text {
//   font-size: 1rem;
//   line-height: 1.5;
//   font-weight: bold;
// }
// .p-byclebest-about__inner .p-byclebest-about__inner-text span {
//   color: #367AD1;
//   font-size: 2rem;
// }
// .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle {
//   padding: 2rem 0 1.5rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   max-width: calc(100% - 7rem);
//   margin: 0 auto;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle {
//     flex-direction: column;
//     position: relative;
//     max-width: calc(100% - 5rem);
//   }
// }
// .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle::after, .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle::before {
//   content: "";
//   width: 3.5rem;
//   height: 5.75rem;
//   background-size: contain;
//   background-repeat: no-repeat;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle::after, .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle::before {
//     position: absolute;
//     bottom: 2rem;
//     width: 2rem;
//     height: 3.75rem;
//   }
// }
// .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle::after {
//   background-image: url(/src/img/sub/bycle-best/byclebest_tab-about03_pc.svg);
//   right: 0;
// }
// .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle::before {
//   background-image: url(/src/img/sub/bycle-best/byclebest_tab-about02_pc.svg);
//   left: 0;
// }
// .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle p {
//   color: #367AD1;
//   font-size: 2rem;
//   font-weight: bold;
//   padding-left: 2rem;
//   line-height: 1.6;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle p {
//     padding: 0;
//     font-size: 1.5rem;
//     padding-top: 1.5rem;
//   }
// }
// .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle img {
//   width: 8.125rem;
//   height: 8.125rem;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__inner .p-byclebest-about__inner-sabtitle img {
//     width: 4.6875rem;
//     height: 4.6875rem;
//   }
// }
// .p-byclebest-about__box {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   max-width: 52.25rem;
//   margin: 0 auto;
//   padding-top: 4rem;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__box {
//     padding-top: 1rem;
//     flex-direction: column;
//   }
// }
// .p-byclebest-about__box .p-byclebest-about__box-img {
//   width: 12.5rem;
// }
// .p-byclebest-about__box .p-byclebest-about__box-text {
//   max-width: calc(100% - 1.25rem);
//   width: 100%;
//   padding-left: 2rem;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__box .p-byclebest-about__box-text {
//     padding-left: 0;
//     padding-top: 1.25rem;
//   }
// }
// .p-byclebest-about__box .p-byclebest-about__box-text p {
//   font-size: 1.125rem;
//   line-height: 1.6;
// }
// .p-byclebest-about__picup {
//   padding-bottom: 5rem;
// }
// .p-byclebest-about__picup .p-byclebest-picup {
//   max-width: 50.5rem;
//   width: 100%;
//   margin: 0 auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__picup .p-byclebest-picup {
//     flex-direction: column;
//   }
// }
// .p-byclebest-about__picup .p-byclebest-picup__img {
//   width: 10rem;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__picup .p-byclebest-picup__img {
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding-bottom: 2rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__picup .p-byclebest-picup__img-box {
//     width: calc(50% - 0.25rem);
//   }
// }
// .p-byclebest-about__picup .p-byclebest-picup__img-box:first-child {
//   padding-bottom: 0.5rem;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__picup .p-byclebest-picup__img-box:first-child {
//     padding-bottom: 0;
//   }
// }
// .p-byclebest-about__picup .p-byclebest-picup__list {
//   max-width: calc(100% - 10rem);
//   padding-left: 2.5rem;
// }
// @media (max-width: 519px) {
//   .p-byclebest-about__picup .p-byclebest-picup__list {
//     padding: 0;
//     max-width: 100%;
//   }
// }

// .byclebest_price.p-byclehoken-tab-price .p-keiyakusya-type__title span {
//   color: #367AD1;
// }
// .byclebest_price.p-byclehoken-tab-price .p-keiyakusya-type__title::before {
//   background-image: url(/src/img/sub/bycle-best/byclebest_tab-title01_pc.svg);
// }
// .byclebest_price.p-byclehoken-tab-price .p-keiyakusya-type__title::after {
//   background-image: url(/src/img/sub/bycle-best/byclebest_tab-title02_pc.svg);
// }

// .p-pet-cat-feature__ttl-nikukyu {
//   font-size: 1.875rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .p-pet-cat-feature__ttl-nikukyu::before, .p-pet-cat-feature__ttl-nikukyu::after {
//   background-image: url(/pc/pet/assets/img/pet_cat_icon01.svg);
//   content: "";
//   width: 2.1875rem;
//   height: 2.1875rem;
// }
// .p-pet-cat-feature__ttl-nikukyu::before {
//   rotate: -20deg;
// }
// .p-pet-cat-feature__ttl-nikukyu::after {
//   rotate: 20deg;
// }

// .p-pet-cat-feature__card-img {
//   margin-inline: auto;
//   width: 12.25rem;
// }

// .p-pet-cat-feature__card-ttl {
//   border-color: #bdbdbd;
// }

// .p-pet-cat-feature__card-img02 {
//   margin-inline: auto;
//   width: 9.375rem;
// }

// .p-pet-cat-bubble {
//   background: #fff682;
//   border-radius: 5px;
//   color: #000;
//   display: inline-block;
//   font-size: 1rem;
//   font-weight: 700;
//   /* margin: 1.5em 0; */
//   max-width: 100%;
//   min-width: 120px;
//   padding: 0.5rem 1.25rem;
//   position: relative;
// }
// .p-pet-cat-bubble::before {
//   border: 0.625rem solid rgba(0, 0, 0, 0);
//   border-top: 0.625rem solid #fff682;
//   content: "";
//   left: 50%;
//   margin-left: -15px;
//   position: absolute;
//   top: 100%;
// }
// .p-pet-cat-bubble p {
//   margin-top: 0;
// }

// @media (max-width: 519px) {
//   .p-pet-cat-feature__point3-flex {
//     flex-direction: column-reverse;
//   }
// }

// .p-pet-cat-feature__consultation-img {
//   position: relative;
//   width: 7rem;
//   min-width: 112px;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__consultation-img {
//     width: 100%;
//     margin: 0 auto;
//   }
// }
// .p-pet-cat-feature__consultation-img img {
//   vertical-align: bottom;
// }

// .p-pet-cat-feature__consultation-img-label {
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 3.6875rem;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__consultation-img-label {
//     top: 0;
//     width: 8.625rem;
//   }
// }

// .p-pet-cat-point-box__photo {
//   max-width: -moz-fit-content;
//   max-width: fit-content;
// }

// .p-pet-cat-primary-band {
//   background-color: #ea5404;
//   color: #fff;
// }

// .p-pet-cat-feature__point4-head {
//   align-items: center;
//   justify-content: center;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__point4-head {
//     flex-direction: column-reverse;
//   }
// }

// .p-pet-cat-feature__point4-head-img {
//   margin-right: 1.5rem;
//   width: 12.5rem;
//   min-width: 200px;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__point4-head-img {
//     margin-right: 0;
//   }
// }

// .p-pet-cat-feature__point4-card {
//   width: 17.5rem;
// }
// .p-pet-cat-feature__point4-card-list {
//   display: flex;
//   justify-content: center;
//   gap: 2.5rem;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__point4-card-list {
//     justify-content: normal;
//     gap: 0;
//   }
// }

// .splide.is-initialized:not(.is-active) .splide__list {
//   display: flex;
// }

// .p-pet-cat-feature__point4-card-head {
//   color: #fff;
//   background-color: #ea5404;
//   border-radius: 5px 5px 0 0;
// }

// .p-pet-cat-feature__point4-card-body {
//   border: 1px solid #bdbdbd;
//   border-top: 0;
//   border-radius: 0 0 5px 5px;
// }

// .p-pet-cat-feature__point4-card-img {
//   margin-inline: auto;
//   width: 11.875rem;
// }

// .p-pet-cat-feature__anipos-item {
//   margin-right: 2.5rem;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__anipos-item {
//     margin-right: 1rem;
//   }
// }

// .p-pet-cat-feature__anipos-logo {
//   margin-inline: auto;
//   width: 14.375rem;
// }

// .p-pet-cat-feature__anipos-qr {
//   width: 6.125rem;
// }

// .p-pet-cat-feature__anipos-download-link {
//   display: block;
//   width: 11.125rem;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__anipos-download-link {
//     width: auto;
//   }
// }

// .p-pet-cat-feature__pethouse-wrap {
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__pethouse-wrap {
//     flex-direction: column;
//   }
// }

// .p-pet-cat-feature__pethouse-iten {
//   margin-right: 3.5rem;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__pethouse-iten {
//     margin-right: 1.5rem;
//   }
// }

// .p-pet-cat-feature__pethouse-img {
//   margin-right: 2.5rem;
//   width: 25.5rem;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__pethouse-img {
//     margin-right: 0;
//     width: -moz-fit-content;
//     width: fit-content;
//   }
// }

// .p-pet-cat-feature__pethouse-qr {
//   width: 4.25rem;
// }

// .p-pet-cat-feature__pethouse-download {
//   align-items: center;
// }

// .p-pet-cat-feature__pethouse-download-link {
//   display: block;
//   width: 5.125rem;
// }
// @media (max-width: 519px) {
//   .p-pet-cat-feature__pethouse-download-link {
//     width: -moz-fit-content;
//     width: fit-content;
//   }
// }

// @media (max-width: 519px) {
//   .p-block-area {
//     margin-top: 50px;
//   }
// }
// .p-block-area h2 {
//   line-height: 1.5;
//   font-size: 1.25rem;
//   font-weight: bold;
//   border-top: #909090 solid 1px;
//   margin-top: 1.5625rem;
//   padding: 0.9375rem 0 0 7.375rem;
// }
// @media (max-width: 519px) {
//   .p-block-area h2 {
//     padding-left: 0;
//   }
// }
// .p-block-area .ex_list {
//   padding: 1.5625rem 0 0 0;
// }
// @media (max-width: 519px) {
//   .p-block-area .ex_list {
//     padding: 0 0 0.9375rem 0.625rem;
//   }
// }
// @media (max-width: 519px) {
//   .p-block-area .ex_list li {
//     text-indent: -1em;
//   }
// }

// .p-block-area__text {
//   line-height: 1.5;
//   max-width: 53.75rem;
//   margin: 0 auto;
//   padding-top: 1.25rem;
// }
// @media (max-width: 519px) {
//   .p-block-area__text {
//     padding: 0px 0 0.9375rem 0;
//   }
// }
// .p-block-area__text p {
//   padding: 0.625rem 0 0 0;
// }
// .p-block-area__text h4 {
//   padding: 1.875rem 0 0.3125rem 0;
// }
// @media (max-width: 519px) {
//   .p-block-area__text h4 {
//     padding: 20px 0 20px 0px;
//     text-indent: -1em;
//     margin-left: 1em;
//   }
// }

// .p-pet-dog-colum__title {
//   position: relative;
//   max-width: 43.75rem;
// }

// .p-pet-dog-colum__title::before {
//   content: "";
//   background-image: url(/pc/pet-dog/assets/img/column/pet_icon01_pc.png);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   width: 1.8125rem;
//   height: 1.8125rem;
//   position: absolute;
//   top: 1.125rem;
//   left: 14%;
//   transform: rotate(-25deg);
// }
// @media (max-width: 519px) {
//   .p-pet-dog-colum__title::before {
//     top: 1.5rem;
//     left: 7%;
//   }
// }

// .p-pet-dog-colum__title::after {
//   content: "";
//   background-image: url(/pc/pet-dog/assets/img/column/pet_icon02_pc.png);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   position: absolute;
//   width: 1.8125rem;
//   height: 1.8125rem;
//   top: 2.5rem;
//   right: 13%;
// }
// @media (max-width: 519px) {
//   .p-pet-dog-colum__title::after {
//     top: 4.5rem;
//     right: 23%;
//   }
// }

// .p-simulation {
//   border-radius: 0.25rem;
//   border: 0.125rem solid #ea5404;
//   padding-bottom: 1.3125rem;
// }

// .p-simulation__title {
//   display: flex;
//   align-items: center;
//   height: 3.3125rem;
//   border: 0.0625rem solid #ea5404;
// }
// .p-simulation__title h4 {
//   color: #fff;
//   text-align: center;
//   font-size: 1.625rem;
//   font-weight: 700;
//   background-color: #ea5404;
//   padding: 0 1.25rem;
//   height: inherit;
//   line-height: 3.3125rem;
// }
// .p-simulation__title p {
//   margin-left: 1.875rem;
//   height: inherit;
//   line-height: 3.3125rem;
//   font-size: 1rem;
//   font-weight: 700;
// }
// @media (max-width: 519px) {
//   .p-simulation__title p {
//     margin-left: 0.625rem;
//   }
// }

// .p-simulation__content {
//   margin-top: 1rem;
//   padding: 0 0.625rem;
// }
// @media (min-width: 520px) {
//   .p-simulation__content {
//     margin-left: 7.5625rem;
//     margin-top: 2rem;
//     padding: 0;
//   }
// }

// .p-simulation__wrap {
//   display: flex;
//   align-items: center;
// }
// @media (max-width: 519px) {
//   .p-simulation__wrap {
//     margin-left: 0.8125rem;
//   }
// }

// .p-simulation__label {
//   font-size: 0.875rem;
//   font-weight: 700;
// }

// .p-simulation-select {
//   margin-left: 2.1875rem;
// }

// .p-simulation-select select {
//   padding: 0.6875rem 4.1875rem 0.6875rem 1rem;
//   width: 100%;
//   font-size: 0.875rem;
//   line-height: 1;
//   border-radius: 0;
//   border: 1px solid #cfcfcf;
//   background-image: url(/common_2024/img/icon/icon_tryangle_orange.png);
//   background-repeat: no-repeat;
//   background-size: 0.875rem;
//   background-position: right 0.875rem top 0.875rem;
//   -webkit-appearance: none;
//      -moz-appearance: none;
//           appearance: none;
//   cursor: pointer;
//   font-weight: 700;
// }

// .p-simulation-select select::-ms-expand {
//   display: none;
// }

// .p-simulation-select select:focus {
//   border: 1px solid #ccc;
//   box-shadow: none;
//   outline: none;
// }

// .p-simulation-radio label {
//   display: block;
// }
// @media (max-width: 519px) {
//   .p-simulation-radio label {
//     margin-left: 1.25rem;
//   }
// }

// .p-simulation-radio input {
//   display: none;
// }

// .p-simulation-radio input + span {
//   padding: 0 0 0 1.25rem;
//   position: relative;
//   display: block;
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1;
//   cursor: pointer;
// }
// @media (max-width: 519px) {
//   .p-simulation-radio input + span {
//     min-width: 4.1875rem;
//   }
// }

// .p-simulation-radio input + span::before {
//   content: "";
//   width: 0.9375rem;
//   height: 0.9375rem;
//   display: block;
//   position: absolute;
//   top: 50%;
//   left: 0;
//   transform: translateY(-50%);
//   background: #fff;
//   border: 1px solid #ccc;
//   border-radius: 50%;
// }

// .p-simulation-radio input + span::after {
//   content: "";
//   padding: 0.125rem;
//   width: 0.4375rem;
//   height: 0.4375rem;
//   position: absolute;
//   top: 50%;
//   left: 0.25rem;
//   transform: translateY(-50%);
//   border: 1px solid transparent;
//   border-radius: 50%;
//   background: #ea5404;
//   opacity: 0;
//   transition: 0.3s;
// }

// .p-simulation-radio input:checked + span::after {
//   opacity: 1;
// }

// @media (min-width: 520px) {
//   .p-simulation__items {
//     display: flex;
//     align-items: center;
//     gap: 0 4.375rem;
//   }
// }

// .p-simulation__text {
//   margin-left: 2.1875rem;
//   font-size: 0.875rem;
//   font-weight: 500;
//   line-height: 1.5;
//   margin-top: 1rem;
// }

// .modal {
//   display: block;
//   width: 22.5rem;
//   max-width: 100%;
//   position: fixed;
//   z-index: 100;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   border-radius: 0.75rem;
//   background: #FFF;
//   box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
//   min-height: 12.5rem;
// }

// .closed {
//   display: none;
// }

// .modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }

// .modal-guts {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   overflow: auto;
//   padding: 4.375rem 4.1875rem 1.3125rem 1.25rem;
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 2;
// }

// .modal .close-button {
//   position: absolute;
//   z-index: 1;
//   top: 0;
//   right: 0;
//   border: 0;
//   background: #ea5404;
//   color: #fff;
//   font-weight: 700;
//   padding: 0.5625rem 0.3125rem 0.5rem 0.3125rem;
//   font-size: 0.75rem;
//   text-align: center;
//   border-radius: 0 0.75rem 0 0;
// }
// .modal .close-button span {
//   max-width: 1.25rem;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
// }

// .p-pet-column-pickup {
//   display: flex;
//   gap: 0.5rem;
// }
// @media (max-width: 519px) {
//   .p-pet-column-pickup {
//     display: block;
//   }
// }
// .p-pet-column-pickup__item {
//   border: 1px solid #ea5404;
//   width: 25%;
//   padding: 1.125rem 0.875rem 1.6875rem;
// }
// @media (max-width: 519px) {
//   .p-pet-column-pickup__item {
//     width: 100%;
//     margin-left: auto;
//     margin-right: auto;
//   }
// }
// .p-pet-column-pickup__wrapper {
//   display: flex;
//   gap: 0.625rem;
//   align-items: center;
// }
// .p-pet-column-pickup__img {
//   width: 36%;
//   max-width: 5.9375rem;
// }
// @media (max-width: 519px) {
//   .p-pet-column-pickup__img {
//     width: 100%;
//   }
// }
// .p-pet-column-pickup__text {
//   width: 54%;
// }
// @media (max-width: 519px) {
//   .p-pet-column-pickup__text {
//     width: 100%;
//   }
// }

// .p-pet-case {
//   border: 0.125rem solid #ea5404;
//   border-radius: 0.5rem;
//   padding: 2.8125rem 0 2.5rem 2.125rem;
// }
// @media (max-width: 519px) {
//   .p-pet-case {
//     padding: 0.75rem 0.73125rem 1.6875rem 0.73125rem;
//   }
// }

// @media (min-width: 960px) {
//   .p-pet-case__contents {
//     display: flex;
//     gap: 0 3rem;
//   }
// }

// .p-pet-case__icon {
//   max-width: 12.8125rem;
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .p-pet-case__icon {
//     margin-left: auto;
//     margin-right: auto;
//   }
// }

// @media (min-width: 960px) {
//   .p-pet-case__content {
//     max-width: 34.0625rem;
//   }
// }

// @media (max-width: 519px) {
//   .p-pet-case__heading .c-heading3-has-tag__tag {
//     min-width: 5.5625rem;
//     padding: 0.3125rem 0.5rem;
//     text-align: center;
//     line-height: initial;
//   }
// }

// .p-keiyakusya-kaigai__mv p.u-text-xs {
//   color: #5c5c5c;
// }

// .-p-bicycle-banner__contents {
//   max-width: 50rem;
//   width: 100%;
//   margin: 0 auto;
// }
// .-p-bicycle-banner__contents__banner {
//   width: 100%;
// }
// .-p-bicycle-banner__contents__banner p.u-text-xs {
//   color: #5c5c5c;
// }
// .-p-bicycle-banner__contents__banner picture img {
//   display: block;
// }
// .-p-bicycle-banner__contents__banner--btn {
//   border-radius: 0 0 0.625rem 0.625rem;
//   width: 100%;
//   padding: 1rem;
//   display: flex;
//   justify-content: center;
// }
// .-p-bicycle-banner__contents__banner--btn .c-btn {
//   background-color: #fff;
//   max-width: 27.5rem;
//   width: 100%;
// }
// .-p-bicycle-banner__contents__banner--btn .c-btn.-lg {
//   padding: 0.75rem 1rem;
//   height: auto;
//   background-size: 1.4375rem;
// }
// .-p-bicycle-banner__contents__banner--btn .c-btn .u-flex {
//   width: 100%;
//   align-items: center;
// }
// .-p-bicycle-banner__contents__banner--btn .c-btn .u-flex p {
//   width: 50%;
// }
// .-p-bicycle-banner__contents__banner--btn .c-btn .u-flex p.price {
//   border-right: #9d9d9d solid 0.0625rem;
// }
// .-p-bicycle-banner__contents__banner--btn .c-btn .u-flex p.price span {
//   font-size: 1.875rem;
// }
// @media (max-width: 519px) {
//   .-p-bicycle-banner__contents__banner--btn .c-btn .u-flex p.price {
//     width: 43%;
//   }
// }
// @media (max-width: 519px) {
//   .-p-bicycle-banner__contents__banner--btn .c-btn .u-flex p.text {
//     width: 57%;
//   }
// }
// .-p-bicycle-banner__contents__banner--btn.blue-banner {
//   background-color: #057cd8;
// }
// .-p-bicycle-banner__contents__banner--btn.blue-banner .c-btn:hover {
//   border-color: #057cd8;
// }
// .-p-bicycle-banner__contents__banner--btn.blue-banner .c-btn.-lg {
//   background-image: url(/common_2024/img/arrow/arrow_bold_blue.svg);
// }
// .-p-bicycle-banner__contents__banner--btn.blue-banner .c-btn .u-flex p.price {
//   color: #057cd8;
// }
// .-p-bicycle-banner__contents__banner--btn.orange-banner {
//   background-color: #ea5404;
// }
// .-p-bicycle-banner__contents__banner--btn.orange-banner .c-btn:hover {
//   border-color: #ea5404;
// }
// .-p-bicycle-banner__contents__banner--btn.orange-banner .c-btn.-lg {
//   background-image: url(/common_2024/img/arrow/arrow_bold_primary.svg);
// }
// .-p-bicycle-banner__contents__banner--btn.orange-banner .c-btn .u-flex p.price {
//   color: #ea5404;
// }
// .-p-bicycle-banner__contents__banner--btn.green-banner {
//   background-color: #6b9e13;
// }
// .-p-bicycle-banner__contents__banner--btn.green-banner .c-btn:hover {
//   border-color: #6b9e13;
// }
// .-p-bicycle-banner__contents__banner--btn.green-banner .c-btn.-lg {
//   background-image: url(/common_2024/img/arrow/arrow_bold_green.svg);
// }
// .-p-bicycle-banner__contents__banner--btn.green-banner .c-btn .u-flex p.price {
//   color: #6b9e13;
// }

// .gray_block__custom {
//   background-color: #f3f3f3;
//   width: 100%;
//   padding: 0.5rem;
// }

// .p-bycle-s-tab .p-bycle-tab-change__tabs .ruby-text {
//   display: flex;
//   align-items: flex-end;
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .p-bycle-tab-change__tabs .ruby-text {
//     flex-wrap: wrap;
//   }
// }
// .p-bycle-s-tab .p-bycle-tab-change__tabs ruby rt {
//   text-align: left;
//   font-size: 0.625rem;
// }
// .p-bycle-s-tab .max700 {
//   max-width: 44.375rem;
//   width: 100%;
//   margin: 0 auto;
// }
// .p-bycle-s-tab .p-bycle-customer {
//   padding-top: 0;
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab1 .c-btn.-md {
//     max-width: 100%;
//   }
// }
// .p-bycle-s-tab .bycle-s__tab1__block1__flexbox {
//   display: flex;
//   justify-content: space-between;
//   max-width: 48rem;
//   width: 100%;
// }
// .p-bycle-s-tab .bycle-s__tab1__block1__flexbox .img {
//   max-width: 9.5rem;
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab1__block1__flexbox .img {
//     max-width: 100%;
//   }
// }
// .p-bycle-s-tab .bycle-s__tab1__block1__flexbox .text {
//   max-width: calc(100% - 11.5rem);
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab1__block1__flexbox .text {
//     max-width: 100%;
//   }
// }
// .p-bycle-s-tab .bycle-s__tab1__block1__flexbox .text .sp-img {
//   max-width: 7.625rem;
//   width: 100%;
// }
// .p-bycle-s-tab .bycle-s__tab1__block2 .l-container .c-heading2-bg-primary {
//   border-radius: 0.25rem 0.25rem 0 0;
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab1__block2 .l-container .c-heading2-bg-primary {
//     padding: 0.625rem 0.5rem;
//   }
// }
// .p-bycle-s-tab .bycle-s__tab1 .bycle-s__points .point03 .p-byclebest-title-hoten::after {
//   background-image: url(/pc/bycle-s/assets/img/bycle-s_tab1-illust02.png);
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 9.25rem;
//   height: 4.8125rem;
//   bottom: 1rem;
//   right: 0;
// }
// .p-bycle-s-tab .bycle-s__tab1 .bycle-s__points .point04 .p-byclebest-title-bengosi::after {
//   background-image: url(/pc/bycle-s/assets/img/bycle-s_tab1-illust03.png);
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 7.25rem;
//   height: 5.25rem;
//   bottom: 0;
//   right: 0;
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab3 .c-table.special {
//     overflow-x: scroll;
//     margin-right: 0;
//     padding-right: 0;
//   }
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table {
//   border-collapse: collapse;
//   border-color: #ea5404;
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table {
//     width: 34.375rem;
//   }
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table td {
//   font-size: 1.25rem;
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table td.c-table__heading {
//   font-size: 1rem;
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table td.text {
//   font-size: 0.875rem;
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table th,
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table td {
//   height: 3rem;
//   padding: 0.75rem;
//   border-left: 1px solid #ea5404;
//   border-right: 1px solid #ea5404;
//   border-top: 1px solid #ea5404;
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table .double-img {
//   position: relative;
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table .double-img picture {
//   position: absolute;
//   max-width: 5.5rem;
//   width: 100%;
//   top: 50%;
//   right: 0;
//   transform: translateY(-50%) translateX(50%);
//   -webkit-transform: translateY(-50%) translateX(50%);
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table .double-img picture {
//     max-width: 4rem;
//   }
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table .c-table__gray {
//   background-color: #5c5c5c;
//   border-right: 1px solid #5c5c5c;
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table .price {
//   font-size: 1.25rem;
// }
// .p-bycle-s-tab .bycle-s__tab3 .c-table.special .c-table__table .price span {
//   font-size: 1.75rem;
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab3__block01 .c-table__table th.heading,
//   .p-bycle-s-tab .bycle-s__tab3__block01 .c-table__table td.heading {
//     min-width: 13.0625rem;
//   }
// }
// .p-bycle-s-tab .bycle-s__tab3__block01 .u-text-xs {
//   max-width: 55.5rem;
//   width: 100%;
// }
// .p-bycle-s-tab .bycle-s__tab3__block02 .fukidasi {
//   max-width: 10.5rem;
//   width: 100%;
//   margin: 0 auto;
// }
// .p-bycle-s-tab .bycle-s__tab3__block02 .u-text-xs {
//   max-width: 55.5rem;
//   width: 100%;
// }
// .p-bycle-s-tab .bycle-s__tab3__block03 .special-txt {
//   font-size: 2.5rem;
// }
// .p-bycle-s-tab .bycle-s__tab3__block03 .special-txt span {
//   font-size: 3rem;
// }
// .p-bycle-s-tab .bycle-s__tab3__block03 .c-table {
//   max-width: 45.5625rem;
//   width: 100%;
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab3__block03 .c-table {
//     overflow-x: scroll;
//     margin-right: 0;
//     padding-right: 0;
//   }
// }
// @media (max-width: 519px) {
//   .p-bycle-s-tab .bycle-s__tab3__block03 .c-table .c-table__table {
//     width: 45.5625rem;
//   }
// }
// .p-bycle-s-tab .bycle-s__tab3__block03 .c-table .c-table__table th,
// .p-bycle-s-tab .bycle-s__tab3__block03 .c-table .c-table__table td {
//   width: 50%;
// }
// .p-bycle-s-tab .bycle-s__tab3__block03 .c-table .c-table__table tbody th,
// .p-bycle-s-tab .bycle-s__tab3__block03 .c-table .c-table__table tbody td {
//   padding: 0.5rem;
// }
// .p-bycle-s-tab .bycle-s__tab3__block03 .c-table .c-table__table .price {
//   font-size: 1.25rem;
// }
// .p-bycle-s-tab .bycle-s__tab3__block03 .c-table .c-table__table .price span {
//   font-size: 1.75rem;
// }

// /*********************************************
// utility/
// **********************************************/
// /*********************************************
// align-items
// **********************************************/
// .u-align-items-flex-start {
//   align-items: flex-start;
// }

// @media (max-width: 519px) {
//   .sm\:align-items-flex-start {
//     align-items: flex-start;
//   }
// }
// .u-align-items-flex-end {
//   align-items: flex-end;
// }

// @media (max-width: 519px) {
//   .sm\:align-items-flex-end {
//     align-items: flex-end;
//   }
// }
// .u-align-items-center {
//   align-items: center;
// }

// @media (max-width: 519px) {
//   .sm\:align-items-center {
//     align-items: center;
//   }
// }
// .u-align-items-baseline {
//   align-items: baseline;
// }

// @media (max-width: 519px) {
//   .sm\:align-items-baseline {
//     align-items: baseline;
//   }
// }
// .u-align-items-stretch {
//   align-items: stretch;
// }

// @media (max-width: 519px) {
//   .sm\:align-items-stretch {
//     align-items: stretch;
//   }
// }
// /*********************************************
// 背景色
// **********************************************/
// .u-bg-white {
//   background-color: #fff;
// }

// .u-bg-primary {
//   background-color: #ea5404;
// }

// .u-bg-primary-100 {
//   background-color: #fff0c9;
// }

// .u-bg-primary-050 {
//   background-color: #fff5ed;
// }

// .u-bg-base-050 {
//   background-color: #f3f3f3;
// }

// /*********************************************
// font-size
// **********************************************/
// .u-border {
//   border-width: 0.0625rem;
//   border-style: solid;
// }

// .u-border-0 {
//   border-width: 0px;
// }

// .u-border-2 {
//   border-width: 0.125rem;
//   border-style: solid;
// }

// .u-border-primary {
//   border-color: #ea5404;
// }

// .u-border-black {
//   border-color: #000;
// }

// .u-border-white {
//   border-color: #fff;
// }

// .u-border-gray {
//   border-color: #f3f3f3;
// }

/*********************************************
display
**********************************************/
.u-block {
  display: block;
}

@media (max-width: 519px) {
  .sm\:u-block {
    display: block;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-block {
    display: block;
  }
}
.u-inline-block {
  display: inline-block;
}

@media (max-width: 519px) {
  .sm\:u-inline-block {
    display: inline-block;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-inline-block {
    display: inline-block;
  }
}
.u-inline {
  display: inline;
}

@media (max-width: 519px) {
  .sm\:u-inline {
    display: inline;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-inline {
    display: inline;
  }
}
.u-flex {
  display: flex;
}

@media (max-width: 519px) {
  .sm\:u-flex {
    display: flex;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-flex {
    display: flex;
  }
}
.u-inline-flex {
  display: inline-flex;
}

@media (max-width: 519px) {
  .sm\:u-inline-flex {
    display: inline-flex;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-inline-flex {
    display: inline-flex;
  }
}
.u-grid {
  display: grid;
}

@media (max-width: 519px) {
  .sm\:u-grid {
    display: grid;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-grid {
    display: grid;
  }
}
.u-inline-grid {
  display: inline-grid;
}

@media (max-width: 519px) {
  .sm\:u-inline-grid {
    display: inline-grid;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-inline-grid {
    display: inline-grid;
  }
}
.u-external-link::after {
  content: "";
  background: url(/common_2024/img/icon/icon_external.svg) center center/contain no-repeat;
  width: 0.9375rem;
  height: 0.75rem;
  display: inline-block;
  margin-left: 0.75em;
}
@media (max-width: 519px) {
  .u-external-link::after {
    width: 0.9375rem;
    height: 0.75rem;
  }
}

/*********************************************
font-size
**********************************************/
.u-text-primary {
  color: #ea5404;
}

.u-text-red {
  color: #f20000;
}

.u-text-white {
  color: #fff;
}

.u-text-black {
  color: #000;
}

.u-text-gray {
  color: #5c5c5c;
}

/*********************************************
font-size
**********************************************/
.u-text-8 {
  font-size: 0.5rem;
}

.u-text-9 {
  font-size: 0.5625rem;
}

.u-text-10 {
  font-size: 0.625rem;
}

.u-text-11 {
  font-size: 0.6875rem;
}

.u-text-12 {
  font-size: 0.75rem;
}

.u-text-13 {
  font-size: 0.8125rem;
}

.u-text-14 {
  font-size: 0.875rem;
}

.u-text-16 {
  font-size: 1rem;
}

.u-text-18 {
  font-size: 1.125rem;
}

.u-text-20 {
  font-size: 1.25rem;
}

.u-text-24 {
  font-size: 1.5rem;
}

@media (max-width: 519px) {
  .sm\:u-text-8 {
    font-size: 0.5rem;
  }
  .sm\:u-text-9 {
    font-size: 0.5625rem;
  }
  .sm\:u-text-10 {
    font-size: 0.625rem;
  }
  .sm\:u-text-11 {
    font-size: 0.6875rem;
  }
  .sm\:u-text-12 {
    font-size: 0.75rem;
  }
  .sm\:u-text-13 {
    font-size: 0.8125rem;
  }
  .sm\:u-text-14 {
    font-size: 0.875rem;
  }
  .sm\:u-text-16 {
    font-size: 1rem;
  }
  .sm\:u-text-18 {
    font-size: 1.125rem;
  }
  .sm\:u-text-20 {
    font-size: 1.25rem;
  }
  .sm\:u-text-24 {
    font-size: 1.5rem;
  }
}
/*********************************************
font-weight
**********************************************/
.u-text-medium {
  font-weight: 500;
}

.u-text-bold {
  font-weight: 700;
}

.u-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.u-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.u-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.u-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

@media (max-width: 519px) {
  .sm\:u-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm\:u-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:u-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:u-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.u-col-span-1 {
  grid-column: span 1/span 1;
}

@media (max-width: 519px) {
  .sm\:u-col-span-1 {
    grid-column: span 1/span 1;
  }
}
.u-col-span-2 {
  grid-column: span 2/span 2;
}

@media (max-width: 519px) {
  .sm\:u-col-span-2 {
    grid-column: span 2/span 2;
  }
}
.u-col-span-3 {
  grid-column: span 3/span 3;
}

@media (max-width: 519px) {
  .sm\:u-col-span-3 {
    grid-column: span 3/span 3;
  }
}
.u-col-span-4 {
  grid-column: span 4/span 4;
}

@media (max-width: 519px) {
  .sm\:u-col-span-4 {
    grid-column: span 4/span 4;
  }
}
.u-col-span-5 {
  grid-column: span 5/span 5;
}

@media (max-width: 519px) {
  .sm\:u-col-span-5 {
    grid-column: span 5/span 5;
  }
}
.u-col-span-6 {
  grid-column: span 6/span 6;
}

@media (max-width: 519px) {
  .sm\:u-col-span-6 {
    grid-column: span 6/span 6;
  }
}
.u-col-span-7 {
  grid-column: span 7/span 7;
}

@media (max-width: 519px) {
  .sm\:u-col-span-7 {
    grid-column: span 7/span 7;
  }
}
.u-col-span-8 {
  grid-column: span 8/span 8;
}

@media (max-width: 519px) {
  .sm\:u-col-span-8 {
    grid-column: span 8/span 8;
  }
}
.u-col-span-9 {
  grid-column: span 9/span 9;
}

@media (max-width: 519px) {
  .sm\:u-col-span-9 {
    grid-column: span 9/span 9;
  }
}
.u-col-span-10 {
  grid-column: span 10/span 10;
}

@media (max-width: 519px) {
  .sm\:u-col-span-10 {
    grid-column: span 10/span 10;
  }
}
.u-col-span-11 {
  grid-column: span 11/span 11;
}

@media (max-width: 519px) {
  .sm\:u-col-span-11 {
    grid-column: span 11/span 11;
  }
}
.u-col-span-12 {
  grid-column: span 12/span 12;
}

@media (max-width: 519px) {
  .sm\:u-col-span-12 {
    grid-column: span 12/span 12;
  }
}
@media (min-width: 520px) {
  .lg\:u-hidden {
    display: none;
  }
}
@media (max-width: 519px) {
  .sm\:u-hidden {
    display: none;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-hidden {
    display: none;
  }
}
/*********************************************
justify-content
**********************************************/
.u-justify-flex-start {
  justify-content: flex-start;
}

@media (max-width: 519px) {
  .sm\:justify-flex-start {
    justify-content: flex-start;
  }
}
.u-justify-flex-end {
  justify-content: flex-end;
}

@media (max-width: 519px) {
  .sm\:justify-flex-end {
    justify-content: flex-end;
  }
}
.u-justify-center {
  justify-content: center;
}

@media (max-width: 519px) {
  .sm\:justify-center {
    justify-content: center;
  }
}
.u-justify-space-between {
  justify-content: space-between;
}

@media (max-width: 519px) {
  .sm\:justify-space-between {
    justify-content: space-between;
  }
}
.u-justify-space-around {
  justify-content: space-around;
}

@media (max-width: 519px) {
  .sm\:justify-space-around {
    justify-content: space-around;
  }
}
.u-justify-space-evenly {
  justify-content: space-evenly;
}

@media (max-width: 519px) {
  .sm\:justify-space-evenly {
    justify-content: space-evenly;
  }
}
/*********************************************
justify-items
**********************************************/
.u-justify-items-start {
  justify-items: start;
}

@media (max-width: 519px) {
  .sm\:justify-items-start {
    justify-items: start;
  }
}
.u-justify-items-end {
  justify-items: end;
}

@media (max-width: 519px) {
  .sm\:justify-items-end {
    justify-items: end;
  }
}
.u-justify-items-center {
  justify-items: center;
}

@media (max-width: 519px) {
  .sm\:justify-items-center {
    justify-items: center;
  }
}
.u-justify-items-stretch {
  justify-items: stretch;
}

@media (max-width: 519px) {
  .sm\:justify-items-stretch {
    justify-items: stretch;
  }
}
/*********************************************
letter-spacing
**********************************************/
.u-tracking-tighter {
  letter-spacing: -0.08em;
}

.u-tracking-tight {
  letter-spacing: -0.04em;
}

.u-tracking-normal {
  letter-spacing: 0em;
}

.u-tracking-wide {
  letter-spacing: 0.04em;
}

.u-tracking-wider {
  letter-spacing: 0.08em;
}

.u-tracking-widest {
  letter-spacing: 0.1em;
}

@media (max-width: 519px) {
  .sm\:u-tracking-tight {
    letter-spacing: -0.04em;
  }
  .sm\:u-tracking-normal {
    letter-spacing: 0em;
  }
}
/*********************************************
line-height
**********************************************/
.u-leading-none {
  line-height: 1;
}

.u-leading-tight {
  line-height: 1.25;
}

.u-leading-snug {
  line-height: 1.375;
}

.u-leading-normal {
  line-height: 1.5;
}

.u-leading-relaxed {
  line-height: 1.625;
}

.u-leading-loose {
  line-height: 2;
}

@media (max-width: 519px) {
  .sm\:u-leading-none {
    line-height: 1;
  }
  .sm\:u-leading-tight {
    line-height: 1.25;
  }
  .sm\:u-leading-snug {
    line-height: 1.375;
  }
  .sm\:u-leading-normal {
    line-height: 1.5;
  }
  .sm\:u-leading-relaxed {
    line-height: 1.625;
  }
  .sm\:u-leading-loose {
    line-height: 2;
  }
}
.u-link-text {
  text-decoration: underline;
  color: #057cd8;
}

.u-link-has-arrow {
  font-size: 0.875rem;
  background: url(/common_2024/img/arrow/arrow_sm_black.svg) left 0.45em no-repeat;
  background-size: 0.4375rem;
  padding-left: 1.25rem;
  display: inline-block;
  line-height: 1.5;
}
.u-link-has-arrow.-sm {
  font-size: 0.75rem;
  background-size: 0.375rem;
  padding-left: 1.125rem;
}
.u-link-has-arrow.-lg {
  font-size: 1rem;
  background-size: 0.5rem;
  padding-left: 1.5rem;
}
.u-link-has-arrow.-primary {
  color: #ea5404;
  background-image: url(/common_2024/img/arrow/arrow_sm_primary.svg);
}
.u-link-has-arrow:focus {
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .u-link-has-arrow:hover {
    text-decoration: underline;
  }
}

/*********************************************
border-radius
**********************************************/
.u-order-1 {
  order: 1;
}

@media (max-width: 519px) {
  .sm\:u-order-1 {
    order: 1;
  }
}
.u-order-2 {
  order: 2;
}

@media (max-width: 519px) {
  .sm\:u-order-2 {
    order: 2;
  }
}
.u-order-3 {
  order: 3;
}

@media (max-width: 519px) {
  .sm\:u-order-3 {
    order: 3;
  }
}
.u-order-4 {
  order: 4;
}

@media (max-width: 519px) {
  .sm\:u-order-4 {
    order: 4;
  }
}
.u-order-5 {
  order: 5;
}

@media (max-width: 519px) {
  .sm\:u-order-5 {
    order: 5;
  }
}
.u-order-6 {
  order: 6;
}

@media (max-width: 519px) {
  .sm\:u-order-6 {
    order: 6;
  }
}
.u-order-7 {
  order: 7;
}

@media (max-width: 519px) {
  .sm\:u-order-7 {
    order: 7;
  }
}
.u-order-8 {
  order: 8;
}

@media (max-width: 519px) {
  .sm\:u-order-8 {
    order: 8;
  }
}
.u-order-9 {
  order: 9;
}

@media (max-width: 519px) {
  .sm\:u-order-9 {
    order: 9;
  }
}
.u-order-10 {
  order: 10;
}

@media (max-width: 519px) {
  .sm\:u-order-10 {
    order: 10;
  }
}
.u-order-11 {
  order: 11;
}

@media (max-width: 519px) {
  .sm\:u-order-11 {
    order: 11;
  }
}
.u-order-12 {
  order: 12;
}

@media (max-width: 519px) {
  .sm\:u-order-12 {
    order: 12;
  }
}
/*********************************************
border-radius
**********************************************/
.u-radius-0 {
  border-radius: 0rem;
  overflow: hidden;
}
.u-radius-0 > img {
  border-radius: 0rem;
}

@media (max-width: 519px) {
  .sm\:u-radius-0 {
    border-radius: 0rem;
    overflow: hidden;
  }
  .sm\:u-radius-0 > img {
    border-radius: 0rem;
  }
}
.u-radius-1 {
  border-radius: 0.25rem;
  overflow: hidden;
}
.u-radius-1 > img {
  border-radius: 0.25rem;
}

@media (max-width: 519px) {
  .sm\:u-radius-1 {
    border-radius: 0.25rem;
    overflow: hidden;
  }
  .sm\:u-radius-1 > img {
    border-radius: 0.25rem;
  }
}
.u-radius-2 {
  border-radius: 0.5rem;
  overflow: hidden;
}
.u-radius-2 > img {
  border-radius: 0.5rem;
}

@media (max-width: 519px) {
  .sm\:u-radius-2 {
    border-radius: 0.5rem;
    overflow: hidden;
  }
  .sm\:u-radius-2 > img {
    border-radius: 0.5rem;
  }
}
.u-radius-3 {
  border-radius: 0.75rem;
  overflow: hidden;
}
.u-radius-3 > img {
  border-radius: 0.75rem;
}

@media (max-width: 519px) {
  .sm\:u-radius-3 {
    border-radius: 0.75rem;
    overflow: hidden;
  }
  .sm\:u-radius-3 > img {
    border-radius: 0.75rem;
  }
}
.u-radius-4 {
  border-radius: 1rem;
  overflow: hidden;
}
.u-radius-4 > img {
  border-radius: 1rem;
}

@media (max-width: 519px) {
  .sm\:u-radius-4 {
    border-radius: 1rem;
    overflow: hidden;
  }
  .sm\:u-radius-4 > img {
    border-radius: 1rem;
  }
}
.u-radius-5 {
  border-radius: 1.25rem;
  overflow: hidden;
}
.u-radius-5 > img {
  border-radius: 1.25rem;
}

@media (max-width: 519px) {
  .sm\:u-radius-5 {
    border-radius: 1.25rem;
    overflow: hidden;
  }
  .sm\:u-radius-5 > img {
    border-radius: 1.25rem;
  }
}
.u-radius-6 {
  border-radius: 1.5rem;
  overflow: hidden;
}
.u-radius-6 > img {
  border-radius: 1.5rem;
}

@media (max-width: 519px) {
  .sm\:u-radius-6 {
    border-radius: 1.5rem;
    overflow: hidden;
  }
  .sm\:u-radius-6 > img {
    border-radius: 1.5rem;
  }
}
.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 519px) {
  .sm\:u-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
.u-m-0 {
  margin: 0rem;
}

.u-mx-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.u-my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.u-mt-0 {
  margin-top: 0rem;
}

.u-mb-0 {
  margin-bottom: 0rem;
}

.u-ml-0 {
  margin-left: 0rem;
}

.u-mr-0 {
  margin-right: 0rem;
}

.u-p-0 {
  padding: 0rem;
}

.u-px-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.u-py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.u-pt-0 {
  padding-top: 0rem;
}

.u-pb-0 {
  padding-bottom: 0rem;
}

.u-pl-0 {
  padding-left: 0rem;
}

.u-pr-0 {
  padding-right: 0rem;
}

.u-gap-0 {
  gap: 0rem;
}

.u-gap-x-0 {
  -moz-column-gap: 0rem;
       column-gap: 0rem;
}

.u-gap-y-0 {
  row-gap: 0rem;
}

@media (max-width: 519px) {
  .sm\:u-m-0 {
    margin: 0rem;
  }
  .sm\:u-mx-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .sm\:u-my-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .sm\:u-mt-0 {
    margin-top: 0rem;
  }
  .sm\:u-mb-0 {
    margin-bottom: 0rem;
  }
  .sm\:u-p-0 {
    padding: 0rem;
  }
  .sm\:u-px-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .sm\:u-py-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .sm\:u-pt-0 {
    padding-top: 0rem;
  }
  .sm\:u-pb-0 {
    padding-bottom: 0rem;
  }
  .sm\:u-pl-0 {
    padding-left: 0rem;
  }
  .sm\:u-pr-0 {
    padding-right: 0rem;
  }
  .sm\:u-gap-0 {
    gap: 0rem;
  }
  .sm\:u-gap-x-0 {
    -moz-column-gap: 0rem;
         column-gap: 0rem;
  }
  .sm\:u-gap-y-0 {
    row-gap: 0rem;
  }
}
.u-m-1 {
  margin: 0.25rem;
}

.u-mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.u-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.u-mt-1 {
  margin-top: 0.25rem;
}

.u-mb-1 {
  margin-bottom: 0.25rem;
}

.u-ml-1 {
  margin-left: 0.25rem;
}

.u-mr-1 {
  margin-right: 0.25rem;
}

.u-p-1 {
  padding: 0.25rem;
}

.u-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.u-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.u-pt-1 {
  padding-top: 0.25rem;
}

.u-pb-1 {
  padding-bottom: 0.25rem;
}

.u-pl-1 {
  padding-left: 0.25rem;
}

.u-pr-1 {
  padding-right: 0.25rem;
}

.u-gap-1 {
  gap: 0.25rem;
}

.u-gap-x-1 {
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}

.u-gap-y-1 {
  row-gap: 0.25rem;
}

@media (max-width: 519px) {
  .sm\:u-m-1 {
    margin: 0.25rem;
  }
  .sm\:u-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .sm\:u-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .sm\:u-mt-1 {
    margin-top: 0.25rem;
  }
  .sm\:u-mb-1 {
    margin-bottom: 0.25rem;
  }
  .sm\:u-p-1 {
    padding: 0.25rem;
  }
  .sm\:u-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .sm\:u-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .sm\:u-pt-1 {
    padding-top: 0.25rem;
  }
  .sm\:u-pb-1 {
    padding-bottom: 0.25rem;
  }
  .sm\:u-pl-1 {
    padding-left: 0.25rem;
  }
  .sm\:u-pr-1 {
    padding-right: 0.25rem;
  }
  .sm\:u-gap-1 {
    gap: 0.25rem;
  }
  .sm\:u-gap-x-1 {
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }
  .sm\:u-gap-y-1 {
    row-gap: 0.25rem;
  }
}
.u-m-2 {
  margin: 0.5rem;
}

.u-mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.u-my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.u-mt-2 {
  margin-top: 0.5rem;
}

.u-mb-2 {
  margin-bottom: 0.5rem;
}

.u-ml-2 {
  margin-left: 0.5rem;
}

.u-mr-2 {
  margin-right: 0.5rem;
}

.u-p-2 {
  padding: 0.5rem;
}

.u-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.u-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.u-pt-2 {
  padding-top: 0.5rem;
}

.u-pb-2 {
  padding-bottom: 0.5rem;
}

.u-pl-2 {
  padding-left: 0.5rem;
}

.u-pr-2 {
  padding-right: 0.5rem;
}

.u-gap-2 {
  gap: 0.5rem;
}

.u-gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.u-gap-y-2 {
  row-gap: 0.5rem;
}

@media (max-width: 519px) {
  .sm\:u-m-2 {
    margin: 0.5rem;
  }
  .sm\:u-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sm\:u-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .sm\:u-mt-2 {
    margin-top: 0.5rem;
  }
  .sm\:u-mb-2 {
    margin-bottom: 0.5rem;
  }
  .sm\:u-p-2 {
    padding: 0.5rem;
  }
  .sm\:u-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm\:u-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm\:u-pt-2 {
    padding-top: 0.5rem;
  }
  .sm\:u-pb-2 {
    padding-bottom: 0.5rem;
  }
  .sm\:u-pl-2 {
    padding-left: 0.5rem;
  }
  .sm\:u-pr-2 {
    padding-right: 0.5rem;
  }
  .sm\:u-gap-2 {
    gap: 0.5rem;
  }
  .sm\:u-gap-x-2 {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }
  .sm\:u-gap-y-2 {
    row-gap: 0.5rem;
  }
}
.u-m-3 {
  margin: 0.75rem;
}

.u-mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.u-my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.u-mt-3 {
  margin-top: 0.75rem;
}

.u-mb-3 {
  margin-bottom: 0.75rem;
}

.u-ml-3 {
  margin-left: 0.75rem;
}

.u-mr-3 {
  margin-right: 0.75rem;
}

.u-p-3 {
  padding: 0.75rem;
}

.u-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.u-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.u-pt-3 {
  padding-top: 0.75rem;
}

.u-pb-3 {
  padding-bottom: 0.75rem;
}

.u-pl-3 {
  padding-left: 0.75rem;
}

.u-pr-3 {
  padding-right: 0.75rem;
}

.u-gap-3 {
  gap: 0.75rem;
}

.u-gap-x-3 {
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}

.u-gap-y-3 {
  row-gap: 0.75rem;
}

@media (max-width: 519px) {
  .sm\:u-m-3 {
    margin: 0.75rem;
  }
  .sm\:u-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .sm\:u-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .sm\:u-mt-3 {
    margin-top: 0.75rem;
  }
  .sm\:u-mb-3 {
    margin-bottom: 0.75rem;
  }
  .sm\:u-p-3 {
    padding: 0.75rem;
  }
  .sm\:u-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:u-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sm\:u-pt-3 {
    padding-top: 0.75rem;
  }
  .sm\:u-pb-3 {
    padding-bottom: 0.75rem;
  }
  .sm\:u-pl-3 {
    padding-left: 0.75rem;
  }
  .sm\:u-pr-3 {
    padding-right: 0.75rem;
  }
  .sm\:u-gap-3 {
    gap: 0.75rem;
  }
  .sm\:u-gap-x-3 {
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }
  .sm\:u-gap-y-3 {
    row-gap: 0.75rem;
  }
}
.u-m-4 {
  margin: 1rem;
}

.u-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.u-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.u-mt-4 {
  margin-top: 1rem;
}

.u-mb-4 {
  margin-bottom: 1rem;
}

.u-ml-4 {
  margin-left: 1rem;
}

.u-mr-4 {
  margin-right: 1rem;
}

.u-p-4 {
  padding: 1rem;
}

.u-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.u-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.u-pt-4 {
  padding-top: 1rem;
}

.u-pb-4 {
  padding-bottom: 1rem;
}

.u-pl-4 {
  padding-left: 1rem;
}

.u-pr-4 {
  padding-right: 1rem;
}

.u-gap-4 {
  gap: 1rem;
}

.u-gap-x-4 {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.u-gap-y-4 {
  row-gap: 1rem;
}

@media (max-width: 519px) {
  .sm\:u-m-4 {
    margin: 1rem;
  }
  .sm\:u-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sm\:u-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .sm\:u-mt-4 {
    margin-top: 1rem;
  }
  .sm\:u-mb-4 {
    margin-bottom: 1rem;
  }
  .sm\:u-p-4 {
    padding: 1rem;
  }
  .sm\:u-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:u-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm\:u-pt-4 {
    padding-top: 1rem;
  }
  .sm\:u-pb-4 {
    padding-bottom: 1rem;
  }
  .sm\:u-pl-4 {
    padding-left: 1rem;
  }
  .sm\:u-pr-4 {
    padding-right: 1rem;
  }
  .sm\:u-gap-4 {
    gap: 1rem;
  }
  .sm\:u-gap-x-4 {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  .sm\:u-gap-y-4 {
    row-gap: 1rem;
  }
}
.u-m-5 {
  margin: 1.25rem;
}

.u-mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.u-my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.u-mt-5 {
  margin-top: 1.25rem;
}

.u-mb-5 {
  margin-bottom: 1.25rem;
}

.u-ml-5 {
  margin-left: 1.25rem;
}

.u-mr-5 {
  margin-right: 1.25rem;
}

.u-p-5 {
  padding: 1.25rem;
}

.u-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.u-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.u-pt-5 {
  padding-top: 1.25rem;
}

.u-pb-5 {
  padding-bottom: 1.25rem;
}

.u-pl-5 {
  padding-left: 1.25rem;
}

.u-pr-5 {
  padding-right: 1.25rem;
}

.u-gap-5 {
  gap: 1.25rem;
}

.u-gap-x-5 {
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
}

.u-gap-y-5 {
  row-gap: 1.25rem;
}

@media (max-width: 519px) {
  .sm\:u-m-5 {
    margin: 1.25rem;
  }
  .sm\:u-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .sm\:u-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .sm\:u-mt-5 {
    margin-top: 1.25rem;
  }
  .sm\:u-mb-5 {
    margin-bottom: 1.25rem;
  }
  .sm\:u-p-5 {
    padding: 1.25rem;
  }
  .sm\:u-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:u-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .sm\:u-pt-5 {
    padding-top: 1.25rem;
  }
  .sm\:u-pb-5 {
    padding-bottom: 1.25rem;
  }
  .sm\:u-pl-5 {
    padding-left: 1.25rem;
  }
  .sm\:u-pr-5 {
    padding-right: 1.25rem;
  }
  .sm\:u-gap-5 {
    gap: 1.25rem;
  }
  .sm\:u-gap-x-5 {
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }
  .sm\:u-gap-y-5 {
    row-gap: 1.25rem;
  }
}
.u-m-6 {
  margin: 1.5rem;
}

.u-mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.u-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.u-mt-6 {
  margin-top: 1.5rem;
}

.u-mb-6 {
  margin-bottom: 1.5rem;
}

.u-ml-6 {
  margin-left: 1.5rem;
}

.u-mr-6 {
  margin-right: 1.5rem;
}

.u-p-6 {
  padding: 1.5rem;
}

.u-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.u-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.u-pt-6 {
  padding-top: 1.5rem;
}

.u-pb-6 {
  padding-bottom: 1.5rem;
}

.u-pl-6 {
  padding-left: 1.5rem;
}

.u-pr-6 {
  padding-right: 1.5rem;
}

.u-gap-6 {
  gap: 1.5rem;
}

.u-gap-x-6 {
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}

.u-gap-y-6 {
  row-gap: 1.5rem;
}

@media (max-width: 519px) {
  .sm\:u-m-6 {
    margin: 1.5rem;
  }
  .sm\:u-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .sm\:u-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .sm\:u-mt-6 {
    margin-top: 1.5rem;
  }
  .sm\:u-mb-6 {
    margin-bottom: 1.5rem;
  }
  .sm\:u-p-6 {
    padding: 1.5rem;
  }
  .sm\:u-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:u-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .sm\:u-pt-6 {
    padding-top: 1.5rem;
  }
  .sm\:u-pb-6 {
    padding-bottom: 1.5rem;
  }
  .sm\:u-pl-6 {
    padding-left: 1.5rem;
  }
  .sm\:u-pr-6 {
    padding-right: 1.5rem;
  }
  .sm\:u-gap-6 {
    gap: 1.5rem;
  }
  .sm\:u-gap-x-6 {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }
  .sm\:u-gap-y-6 {
    row-gap: 1.5rem;
  }
}
.u-m-8 {
  margin: 2rem;
}

.u-mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.u-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.u-mt-8 {
  margin-top: 2rem;
}

.u-mb-8 {
  margin-bottom: 2rem;
}

.u-ml-8 {
  margin-left: 2rem;
}

.u-mr-8 {
  margin-right: 2rem;
}

.u-p-8 {
  padding: 2rem;
}

.u-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.u-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.u-pt-8 {
  padding-top: 2rem;
}

.u-pb-8 {
  padding-bottom: 2rem;
}

.u-pl-8 {
  padding-left: 2rem;
}

.u-pr-8 {
  padding-right: 2rem;
}

.u-gap-8 {
  gap: 2rem;
}

.u-gap-x-8 {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}

.u-gap-y-8 {
  row-gap: 2rem;
}

@media (max-width: 519px) {
  .sm\:u-m-8 {
    margin: 2rem;
  }
  .sm\:u-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .sm\:u-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .sm\:u-mt-8 {
    margin-top: 2rem;
  }
  .sm\:u-mb-8 {
    margin-bottom: 2rem;
  }
  .sm\:u-p-8 {
    padding: 2rem;
  }
  .sm\:u-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .sm\:u-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sm\:u-pt-8 {
    padding-top: 2rem;
  }
  .sm\:u-pb-8 {
    padding-bottom: 2rem;
  }
  .sm\:u-pl-8 {
    padding-left: 2rem;
  }
  .sm\:u-pr-8 {
    padding-right: 2rem;
  }
  .sm\:u-gap-8 {
    gap: 2rem;
  }
  .sm\:u-gap-x-8 {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }
  .sm\:u-gap-y-8 {
    row-gap: 2rem;
  }
}
.u-m-10 {
  margin: 2.5rem;
}

.u-mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.u-my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.u-mt-10 {
  margin-top: 2.5rem;
}

.u-mb-10 {
  margin-bottom: 2.5rem;
}

.u-ml-10 {
  margin-left: 2.5rem;
}

.u-mr-10 {
  margin-right: 2.5rem;
}

.u-p-10 {
  padding: 2.5rem;
}

.u-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.u-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.u-pt-10 {
  padding-top: 2.5rem;
}

.u-pb-10 {
  padding-bottom: 2.5rem;
}

.u-pl-10 {
  padding-left: 2.5rem;
}

.u-pr-10 {
  padding-right: 2.5rem;
}

.u-gap-10 {
  gap: 2.5rem;
}

.u-gap-x-10 {
  -moz-column-gap: 2.5rem;
       column-gap: 2.5rem;
}

.u-gap-y-10 {
  row-gap: 2.5rem;
}

@media (max-width: 519px) {
  .sm\:u-m-10 {
    margin: 2.5rem;
  }
  .sm\:u-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .sm\:u-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .sm\:u-mt-10 {
    margin-top: 2.5rem;
  }
  .sm\:u-mb-10 {
    margin-bottom: 2.5rem;
  }
  .sm\:u-p-10 {
    padding: 2.5rem;
  }
  .sm\:u-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .sm\:u-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .sm\:u-pt-10 {
    padding-top: 2.5rem;
  }
  .sm\:u-pb-10 {
    padding-bottom: 2.5rem;
  }
  .sm\:u-pl-10 {
    padding-left: 2.5rem;
  }
  .sm\:u-pr-10 {
    padding-right: 2.5rem;
  }
  .sm\:u-gap-10 {
    gap: 2.5rem;
  }
  .sm\:u-gap-x-10 {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }
  .sm\:u-gap-y-10 {
    row-gap: 2.5rem;
  }
}
.u-m-12 {
  margin: 3rem;
}

.u-mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.u-my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.u-mt-12 {
  margin-top: 3rem;
}

.u-mb-12 {
  margin-bottom: 3rem;
}

.u-ml-12 {
  margin-left: 3rem;
}

.u-mr-12 {
  margin-right: 3rem;
}

.u-p-12 {
  padding: 3rem;
}

.u-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.u-py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.u-pt-12 {
  padding-top: 3rem;
}

.u-pb-12 {
  padding-bottom: 3rem;
}

.u-pl-12 {
  padding-left: 3rem;
}

.u-pr-12 {
  padding-right: 3rem;
}

.u-gap-12 {
  gap: 3rem;
}

.u-gap-x-12 {
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}

.u-gap-y-12 {
  row-gap: 3rem;
}

@media (max-width: 519px) {
  .sm\:u-m-12 {
    margin: 3rem;
  }
  .sm\:u-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .sm\:u-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .sm\:u-mt-12 {
    margin-top: 3rem;
  }
  .sm\:u-mb-12 {
    margin-bottom: 3rem;
  }
  .sm\:u-p-12 {
    padding: 3rem;
  }
  .sm\:u-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .sm\:u-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .sm\:u-pt-12 {
    padding-top: 3rem;
  }
  .sm\:u-pb-12 {
    padding-bottom: 3rem;
  }
  .sm\:u-pl-12 {
    padding-left: 3rem;
  }
  .sm\:u-pr-12 {
    padding-right: 3rem;
  }
  .sm\:u-gap-12 {
    gap: 3rem;
  }
  .sm\:u-gap-x-12 {
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }
  .sm\:u-gap-y-12 {
    row-gap: 3rem;
  }
}
.u-m-14 {
  margin: 3.5rem;
}

.u-mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.u-my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.u-mt-14 {
  margin-top: 3.5rem;
}

.u-mb-14 {
  margin-bottom: 3.5rem;
}

.u-ml-14 {
  margin-left: 3.5rem;
}

.u-mr-14 {
  margin-right: 3.5rem;
}

.u-p-14 {
  padding: 3.5rem;
}

.u-px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.u-py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.u-pt-14 {
  padding-top: 3.5rem;
}

.u-pb-14 {
  padding-bottom: 3.5rem;
}

.u-pl-14 {
  padding-left: 3.5rem;
}

.u-pr-14 {
  padding-right: 3.5rem;
}

.u-gap-14 {
  gap: 3.5rem;
}

.u-gap-x-14 {
  -moz-column-gap: 3.5rem;
       column-gap: 3.5rem;
}

.u-gap-y-14 {
  row-gap: 3.5rem;
}

@media (max-width: 519px) {
  .sm\:u-m-14 {
    margin: 3.5rem;
  }
  .sm\:u-mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .sm\:u-my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .sm\:u-mt-14 {
    margin-top: 3.5rem;
  }
  .sm\:u-mb-14 {
    margin-bottom: 3.5rem;
  }
  .sm\:u-p-14 {
    padding: 3.5rem;
  }
  .sm\:u-px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .sm\:u-py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .sm\:u-pt-14 {
    padding-top: 3.5rem;
  }
  .sm\:u-pb-14 {
    padding-bottom: 3.5rem;
  }
  .sm\:u-pl-14 {
    padding-left: 3.5rem;
  }
  .sm\:u-pr-14 {
    padding-right: 3.5rem;
  }
  .sm\:u-gap-14 {
    gap: 3.5rem;
  }
  .sm\:u-gap-x-14 {
    -moz-column-gap: 3.5rem;
         column-gap: 3.5rem;
  }
  .sm\:u-gap-y-14 {
    row-gap: 3.5rem;
  }
}
.u-m-16 {
  margin: 4rem;
}

.u-mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.u-my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.u-mt-16 {
  margin-top: 4rem;
}

.u-mb-16 {
  margin-bottom: 4rem;
}

.u-ml-16 {
  margin-left: 4rem;
}

.u-mr-16 {
  margin-right: 4rem;
}

.u-p-16 {
  padding: 4rem;
}

.u-px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.u-py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.u-pt-16 {
  padding-top: 4rem;
}

.u-pb-16 {
  padding-bottom: 4rem;
}

.u-pl-16 {
  padding-left: 4rem;
}

.u-pr-16 {
  padding-right: 4rem;
}

.u-gap-16 {
  gap: 4rem;
}

.u-gap-x-16 {
  -moz-column-gap: 4rem;
       column-gap: 4rem;
}

.u-gap-y-16 {
  row-gap: 4rem;
}

@media (max-width: 519px) {
  .sm\:u-m-16 {
    margin: 4rem;
  }
  .sm\:u-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .sm\:u-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .sm\:u-mt-16 {
    margin-top: 4rem;
  }
  .sm\:u-mb-16 {
    margin-bottom: 4rem;
  }
  .sm\:u-p-16 {
    padding: 4rem;
  }
  .sm\:u-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .sm\:u-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .sm\:u-pt-16 {
    padding-top: 4rem;
  }
  .sm\:u-pb-16 {
    padding-bottom: 4rem;
  }
  .sm\:u-pl-16 {
    padding-left: 4rem;
  }
  .sm\:u-pr-16 {
    padding-right: 4rem;
  }
  .sm\:u-gap-16 {
    gap: 4rem;
  }
  .sm\:u-gap-x-16 {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }
  .sm\:u-gap-y-16 {
    row-gap: 4rem;
  }
}
.u-m-20 {
  margin: 5rem;
}

.u-mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.u-my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.u-mt-20 {
  margin-top: 5rem;
}

.u-mb-20 {
  margin-bottom: 5rem;
}

.u-ml-20 {
  margin-left: 5rem;
}

.u-mr-20 {
  margin-right: 5rem;
}

.u-p-20 {
  padding: 5rem;
}

.u-px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.u-py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.u-pt-20 {
  padding-top: 5rem;
}

.u-pb-20 {
  padding-bottom: 5rem;
}

.u-pl-20 {
  padding-left: 5rem;
}

.u-pr-20 {
  padding-right: 5rem;
}

.u-gap-20 {
  gap: 5rem;
}

.u-gap-x-20 {
  -moz-column-gap: 5rem;
       column-gap: 5rem;
}

.u-gap-y-20 {
  row-gap: 5rem;
}

@media (max-width: 519px) {
  .sm\:u-m-20 {
    margin: 5rem;
  }
  .sm\:u-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .sm\:u-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .sm\:u-mt-20 {
    margin-top: 5rem;
  }
  .sm\:u-mb-20 {
    margin-bottom: 5rem;
  }
  .sm\:u-p-20 {
    padding: 5rem;
  }
  .sm\:u-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .sm\:u-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .sm\:u-pt-20 {
    padding-top: 5rem;
  }
  .sm\:u-pb-20 {
    padding-bottom: 5rem;
  }
  .sm\:u-pl-20 {
    padding-left: 5rem;
  }
  .sm\:u-pr-20 {
    padding-right: 5rem;
  }
  .sm\:u-gap-20 {
    gap: 5rem;
  }
  .sm\:u-gap-x-20 {
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }
  .sm\:u-gap-y-20 {
    row-gap: 5rem;
  }
}
.u-m-24 {
  margin: 6rem;
}

.u-mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.u-my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.u-mt-24 {
  margin-top: 6rem;
}

.u-mb-24 {
  margin-bottom: 6rem;
}

.u-ml-24 {
  margin-left: 6rem;
}

.u-mr-24 {
  margin-right: 6rem;
}

.u-p-24 {
  padding: 6rem;
}

.u-px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.u-py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.u-pt-24 {
  padding-top: 6rem;
}

.u-pb-24 {
  padding-bottom: 6rem;
}

.u-pl-24 {
  padding-left: 6rem;
}

.u-pr-24 {
  padding-right: 6rem;
}

.u-gap-24 {
  gap: 6rem;
}

.u-gap-x-24 {
  -moz-column-gap: 6rem;
       column-gap: 6rem;
}

.u-gap-y-24 {
  row-gap: 6rem;
}

@media (max-width: 519px) {
  .sm\:u-m-24 {
    margin: 6rem;
  }
  .sm\:u-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .sm\:u-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .sm\:u-mt-24 {
    margin-top: 6rem;
  }
  .sm\:u-mb-24 {
    margin-bottom: 6rem;
  }
  .sm\:u-p-24 {
    padding: 6rem;
  }
  .sm\:u-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .sm\:u-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .sm\:u-pt-24 {
    padding-top: 6rem;
  }
  .sm\:u-pb-24 {
    padding-bottom: 6rem;
  }
  .sm\:u-pl-24 {
    padding-left: 6rem;
  }
  .sm\:u-pr-24 {
    padding-right: 6rem;
  }
  .sm\:u-gap-24 {
    gap: 6rem;
  }
  .sm\:u-gap-x-24 {
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }
  .sm\:u-gap-y-24 {
    row-gap: 6rem;
  }
}
.u-m-28 {
  margin: 7rem;
}

.u-mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.u-my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.u-mt-28 {
  margin-top: 7rem;
}

.u-mb-28 {
  margin-bottom: 7rem;
}

.u-ml-28 {
  margin-left: 7rem;
}

.u-mr-28 {
  margin-right: 7rem;
}

.u-p-28 {
  padding: 7rem;
}

.u-px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.u-py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.u-pt-28 {
  padding-top: 7rem;
}

.u-pb-28 {
  padding-bottom: 7rem;
}

.u-pl-28 {
  padding-left: 7rem;
}

.u-pr-28 {
  padding-right: 7rem;
}

.u-gap-28 {
  gap: 7rem;
}

.u-gap-x-28 {
  -moz-column-gap: 7rem;
       column-gap: 7rem;
}

.u-gap-y-28 {
  row-gap: 7rem;
}

@media (max-width: 519px) {
  .sm\:u-m-28 {
    margin: 7rem;
  }
  .sm\:u-mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .sm\:u-my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .sm\:u-mt-28 {
    margin-top: 7rem;
  }
  .sm\:u-mb-28 {
    margin-bottom: 7rem;
  }
  .sm\:u-p-28 {
    padding: 7rem;
  }
  .sm\:u-px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .sm\:u-py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .sm\:u-pt-28 {
    padding-top: 7rem;
  }
  .sm\:u-pb-28 {
    padding-bottom: 7rem;
  }
  .sm\:u-pl-28 {
    padding-left: 7rem;
  }
  .sm\:u-pr-28 {
    padding-right: 7rem;
  }
  .sm\:u-gap-28 {
    gap: 7rem;
  }
  .sm\:u-gap-x-28 {
    -moz-column-gap: 7rem;
         column-gap: 7rem;
  }
  .sm\:u-gap-y-28 {
    row-gap: 7rem;
  }
}
.u-m-32 {
  margin: 8rem;
}

.u-mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.u-my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.u-mt-32 {
  margin-top: 8rem;
}

.u-mb-32 {
  margin-bottom: 8rem;
}

.u-ml-32 {
  margin-left: 8rem;
}

.u-mr-32 {
  margin-right: 8rem;
}

.u-p-32 {
  padding: 8rem;
}

.u-px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.u-py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.u-pt-32 {
  padding-top: 8rem;
}

.u-pb-32 {
  padding-bottom: 8rem;
}

.u-pl-32 {
  padding-left: 8rem;
}

.u-pr-32 {
  padding-right: 8rem;
}

.u-gap-32 {
  gap: 8rem;
}

.u-gap-x-32 {
  -moz-column-gap: 8rem;
       column-gap: 8rem;
}

.u-gap-y-32 {
  row-gap: 8rem;
}

@media (max-width: 519px) {
  .sm\:u-m-32 {
    margin: 8rem;
  }
  .sm\:u-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .sm\:u-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .sm\:u-mt-32 {
    margin-top: 8rem;
  }
  .sm\:u-mb-32 {
    margin-bottom: 8rem;
  }
  .sm\:u-p-32 {
    padding: 8rem;
  }
  .sm\:u-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .sm\:u-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .sm\:u-pt-32 {
    padding-top: 8rem;
  }
  .sm\:u-pb-32 {
    padding-bottom: 8rem;
  }
  .sm\:u-pl-32 {
    padding-left: 8rem;
  }
  .sm\:u-pr-32 {
    padding-right: 8rem;
  }
  .sm\:u-gap-32 {
    gap: 8rem;
  }
  .sm\:u-gap-x-32 {
    -moz-column-gap: 8rem;
         column-gap: 8rem;
  }
  .sm\:u-gap-y-32 {
    row-gap: 8rem;
  }
}
.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

@media (max-width: 519px) {
  .sm\:u-text-right {
    text-align: right;
  }
  .sm\:u-text-left {
    text-align: left;
  }
  .sm\:u-text-center {
    text-align: center;
  }
}
/*********************************************
font-size
**********************************************/
.u-text-underline {
  text-decoration: underline;
}

.u-line-through {
  text-decoration-line: line-through;
}

/*********************************************
line-height
**********************************************/
.u-text-indent-1 {
  padding-left: 1em;
  text-indent: -1em;
}

.u-text-indent-2 {
  padding-left: 1.8em;
  text-indent: -1.8em;
}

.u-text-lg {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.03em;
}

.u-text-md {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.03em;
}

.u-text-sm {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.u-text-xs {
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.u-w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.u-w-full {
  width: 100%;
}

.u-w-1\/2 {
  width: 50%;
}

.u-w-1-5\/2 {
  width: 75%;
}

.u-w-screen {
  width: 100vw;
}

@media (max-width: 519px) {
  .sm\:u-w-fit {
    width: -moz-fit-content;
    width: fit-content;
  }
  .sm\:u-w-full {
    width: 100%;
  }
  .sm\:u-w-1\/2 {
    width: 50%;
  }
  .smu-w-1-5\/2 {
    width: 75%;
  }
  .sm\:u-w-screen {
    width: 100vw;
  }
}
.u-has-check {
  font-size: 0.875rem;
  font-weight: 700;
  background: url(/common_2024/img/icon/icon_check_primary.svg) left top 7px no-repeat;
  background-size: 0.75rem;
  padding-left: 1.25rem;
  display: inline-block;
  line-height: 1.5;
}

/*********************************************
display
**********************************************/
.u-relative {
  position: relative;
}

@media (max-width: 519px) {
  .sm\:u-relative {
    position: relative;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-relative {
    position: relative;
  }
}
.u-absolute {
  position: absolute;
}

@media (max-width: 519px) {
  .sm\:u-absolute {
    position: absolute;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-absolute {
    position: absolute;
  }
}
.u-static {
  position: static;
}

@media (max-width: 519px) {
  .sm\:u-static {
    position: static;
  }
}
@media (min-width: 520px) {
  .sm-min\:u-static {
    position: static;
  }
}
.u-white-space-wrap {
  white-space: wrap;
}

@media (max-width: 519px) {
  .sm\:u-white-space-wrap {
    white-space: wrap;
  }
}
.u-white-space-nowrap {
  white-space: nowrap;
}

@media (max-width: 519px) {
  .sm\:u-white-space-nowrap {
    white-space: nowrap;
  }
}



@media (min-width: 521px) {
    .js-footer-toggle-btn {
      pointer-events: none !important;
    }
}

@media(min-width: 960px)and (hover: hover)and (pointer: fine) {
    .l-header-sub__btn.-login a:hover {
        background-color:#404ac7;
        color: #fff
    }

    .l-header-sub__btn.-user a:hover {
        background-color: #9d9d9d;
        color: #fff
    }
}

}